.tlov-shartlari-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.tlov-shartlari-burger-menu1 {
  display: none;
}
.tlov-shartlari-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tlov-shartlari-container11 {
  gap: 60px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: 146px;
  align-items: flex-start;
  padding-top: 60px;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/frame%2043-200h.png");
  background-repeat: space;
  background-position: center;
}
.tlov-shartlari-text100 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tlov-shartlari-container12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  background-color: #F0F0F0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.tlov-shartlari-navlink10 {
  display: contents;
}
.tlov-shartlari-container13 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.tlov-shartlari-icon12 {
  fill: var(--dl-color-primary-500);
  color: var(--dl-color-primary-500);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tlov-shartlari-text101 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.tlov-shartlari-navlink11 {
  display: contents;
}
.tlov-shartlari-container14 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.tlov-shartlari-icon14 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 16px;
  height: 16px;
}
.tlov-shartlari-text102 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.tlov-shartlari-navlink12 {
  display: contents;
}
.tlov-shartlari-container15 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.tlov-shartlari-icon16 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 16px;
  height: 16px;
}
.tlov-shartlari-text103 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.tlov-shartlari-container16 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  margin: 100px;
  display: flex;
  align-self: flex-start;
  margin-top: 0px;
  align-items: flex-start;
  margin-left: 0px;
  padding-top: var(--dl-space-space-twounits);
  margin-right: 0px;
  padding-left: 100px;
  margin-bottom: 0px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
}
.tlov-shartlari-container17 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.tlov-shartlari-container18 {
  gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.tlov-shartlari-text104 {
  color: var(--dl-color-gray-500);
  font-style: normal;
  font-weight: 500;
}
.tlov-shartlari-icon18 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 12px;
  height: 12px;
}
.tlov-shartlari-image10 {
  width: auto;
  object-fit: cover;
}
.tlov-shartlari-container19 {
  gap: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.tlov-shartlari-navlink13 {
  display: contents;
}
.tlov-shartlari-container20 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.tlov-shartlari-image11 {
  width: var(--dl-size-size-xsmall);
  object-fit: cover;
}
.tlov-shartlari-text105 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tlov-shartlari-navlink14 {
  display: contents;
}
.tlov-shartlari-container21 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.tlov-shartlari-container21:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.tlov-shartlari-image12 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.tlov-shartlari-text106 {
  color: var(--dl-color-gray-gray-main);
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: "Roboto";
  font-weight: 500;
}
.tlov-shartlari-text106:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tlov-shartlari-navlink15 {
  display: contents;
}
.tlov-shartlari-container22 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.tlov-shartlari-container22:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.tlov-shartlari-image13 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.tlov-shartlari-text107 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tlov-shartlari-text107:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tlov-shartlari-navlink16 {
  display: contents;
}
.tlov-shartlari-container23 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.tlov-shartlari-container23:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.tlov-shartlari-image14 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.tlov-shartlari-text108 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tlov-shartlari-text108:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tlov-shartlari-navlink17 {
  display: contents;
}
.tlov-shartlari-container24 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.tlov-shartlari-container24:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.tlov-shartlari-image15 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.tlov-shartlari-text109 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tlov-shartlari-text109:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tlov-shartlari-navlink18 {
  display: contents;
}
.tlov-shartlari-container25 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-gray-main);
}
.tlov-shartlari-container25:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.tlov-shartlari-image16 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.tlov-shartlari-text110 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tlov-shartlari-text110:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tlov-shartlari-navlink19 {
  display: contents;
}
.tlov-shartlari-container26 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.tlov-shartlari-container26:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.tlov-shartlari-image17 {
  width: var(--dl-size-size-xsmall);
  align-self: center;
  object-fit: cover;
  transition: 0.3s;
}

.tlov-shartlari-text111 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tlov-shartlari-text111:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tlov-shartlari-navlink20 {
  display: contents;
}
.tlov-shartlari-container27 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
  border-bottom-width: 1px;
}
.tlov-shartlari-container27:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.tlov-shartlari-image18 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.tlov-shartlari-text112 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tlov-shartlari-text112:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tlov-shartlari-container28 {
  gap: var(--dl-space-space-twounits);
  flex: 0;
  width: auto;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  border-left-width: 1px;
}
.tlov-shartlari-text113 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 24px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  border-bottom-width: 1px;
}
.tlov-shartlari-text116 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 900px;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-text117 {
  font-style: normal;
  font-weight: 500;
}
.tlov-shartlari-text129 {
  font-style: normal;
  font-weight: 500;
}
.tlov-shartlari-text130 {
  font-style: normal;
  font-weight: 500;
}
.tlov-shartlari-container29 {
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.tlov-shartlari-container30 {
  width: 832px;
  height: 174px;
}
.tlov-shartlari-container31 {
  display: contents;
}
.tlov-shartlari-container32 {
  width: 832px;
}
.tlov-shartlari-container33 {
  display: contents;
}
.tlov-shartlari-container34 {
  width: 838px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.tlov-shartlari-container35 {
  width: 100%;
}
.tlov-shartlari-container36 {
  display: contents;
}
.tlov-shartlari-container37 {
  width: 100%;
}
.tlov-shartlari-container38 {
  display: contents;
}
.tlov-shartlari-container39 {
  width: 841px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.tlov-shartlari-container40 {
  display: contents;
}
.tlov-shartlari-container41 {
  flex: 0;
  width: 100%;
  display: flex;
  position: fixed;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.tlov-shartlari-navlink21 {
  display: contents;
}
.tlov-shartlari-container42 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}
.tlov-shartlari-image19 {
  width: 106px;
  height: 64px;
  text-decoration: none;
}
.tlov-shartlari-text158 {
  fill: var(--dl-color-primary-main);
  color: var(--dl-color-primary-main);
  width: 275px;
  height: 52px;
  font-size: 10px;
  max-width: auto;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  margin-left: var(--dl-space-space-fourunits);
}
.tlov-shartlari-container43 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.tlov-shartlari-container44 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.tlov-shartlari-text161 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-family: "Roboto";
}
.tlov-shartlari-text163 {
  font-style: normal;
  font-weight: 700;
}
.tlov-shartlari-text164 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-family: "Roboto";
}
.tlov-shartlari-text165 {
  font-style: normal;
  font-weight: 700;
}
.tlov-shartlari-image20 {
  width: 16px;
  height: 11px;
  object-fit: cover;
}
.tlov-shartlari-text196 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 700;
}
.tlov-shartlari-image21 {
  width: 16px;
  height: 11px;
  object-fit: cover;
}
.tlov-shartlari-select {
  height: auto;
  font-size: x-small;
  background-color: var(--dl-color-gray-white);
}
.tlov-shartlari-navbar-interactive {
  width: 100%;
  display: flex;
  position: fixed;
  align-self: center;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: px;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: px;
  justify-content: center;
  background-color: var(--dl-color-primary-active_red);
}
.tlov-shartlari-desktop-menu {
  flex: 1;
  width: auto;
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
}
.tlov-shartlari-container45 {
  display: flex;
  justify-content: space-between;
}
.tlov-shartlari-links1 {
  gap: 1px;
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-danger-700);
}
.tlov-shartlari-thq-dropdown10 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.tlov-shartlari-dropdown-toggle10:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: mixed;
  padding-right: mixed;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-dropdown-toggle10:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-text197 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tlov-shartlari-thq-dropdown11 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-dropdown-toggle11:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-dropdown-toggle11:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-text198 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tlov-shartlari-dropdown-arrow1 {
  transition: 0.3s;
}
.tlov-shartlari-dropdown-list1 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tlov-shartlari-dropdown10 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle12 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle12:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text199 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.tlov-shartlari-dropdown11 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle13 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle13:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text202 {
  font-style: normal;
}
.tlov-shartlari-dropdown12 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle14 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle14:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text203 {
  font-style: normal;
}
.tlov-shartlari-dropdown13 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle15 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle15:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text204 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.tlov-shartlari-dropdown14 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle16 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle16:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text209 {
  font-style: normal;
}
.tlov-shartlari-dropdown15 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle17 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle17:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text210 {
  font-style: normal;
}
.tlov-shartlari-dropdown16 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle18 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle18:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text211 {
  font-style: normal;
}
.tlov-shartlari-dropdown17 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle19 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.tlov-shartlari-dropdown-toggle19:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text215 {
  font-style: normal;
}
.tlov-shartlari-thq-dropdown12 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle20 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.tlov-shartlari-dropdown-toggle20:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-dropdown-toggle20:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-text216 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tlov-shartlari-dropdown-arrow2 {
  transition: 0.3s;
}
.tlov-shartlari-dropdown-list2 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tlov-shartlari-dropdown18 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle21 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle21:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text217 {
  font-style: normal;
}
.tlov-shartlari-dropdown19 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle22 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle22:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text218 {
  font-style: normal;
}
.tlov-shartlari-dropdown20 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle23 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle23:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text219 {
  font-style: normal;
}
.tlov-shartlari-dropdown21 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle24 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle24:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text220 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.tlov-shartlari-dropdown22 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle25 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle25:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text223 {
  font-style: normal;
}
.tlov-shartlari-dropdown23 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle26 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle26:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text227 {
  font-style: normal;
}
.tlov-shartlari-dropdown24 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle27 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  padding-bottom: 20px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle27:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text228 {
  font-style: normal;
}
.tlov-shartlari-thq-dropdown13 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle28 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.tlov-shartlari-dropdown-toggle28:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-dropdown-toggle28:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-text229 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tlov-shartlari-dropdown-arrow3 {
  transition: 0.3s;
}
.tlov-shartlari-dropdown-list3 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tlov-shartlari-dropdown25 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle29 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle29:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text230 {
  font-style: normal;
}
.tlov-shartlari-dropdown26 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle30 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle30:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text231 {
  font-style: normal;
}
.tlov-shartlari-dropdown27 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle31 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle31:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text235 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.tlov-shartlari-dropdown28 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle32 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle32:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text238 {
  font-style: normal;
}
.tlov-shartlari-dropdown29 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle33 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle33:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text239 {
  font-style: normal;
}
.tlov-shartlari-dropdown30 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle34 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle34:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text243 {
  font-style: normal;
}
.tlov-shartlari-dropdown31 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle35 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.tlov-shartlari-dropdown-toggle35:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text244 {
  font-style: normal;
}
.tlov-shartlari-dropdown32 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle36 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle36:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-dropdown33 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle37 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle37:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-thq-dropdown14 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle38 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.tlov-shartlari-dropdown-toggle38:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-dropdown-toggle38:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-text247 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tlov-shartlari-thq-dropdown15 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle39 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.tlov-shartlari-dropdown-toggle39:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-dropdown-toggle39:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-text248 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tlov-shartlari-dropdown-arrow4 {
  transition: 0.3s;
}
.tlov-shartlari-dropdown-list4 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tlov-shartlari-dropdown34 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle40 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle40:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text249 {
  font-style: normal;
}
.tlov-shartlari-dropdown35 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle41 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle41:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text250 {
  font-style: normal;
}
.tlov-shartlari-dropdown36 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle42 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle42:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text251 {
  font-style: normal;
}
.tlov-shartlari-dropdown37 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle43 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle43:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text252 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.tlov-shartlari-container46 {
  gap: 4px;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-end;
  background-color: var(--dl-color-primary-active_red);
}
.tlov-shartlari-textinput1 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  border-width: 0px;
  border-radius: 0px;
}
.tlov-shartlari-container47 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.tlov-shartlari-icon20 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 24px;
}
.tlov-shartlari-container48 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.tlov-shartlari-text255 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tlov-shartlari-text256 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tlov-shartlari-burger-menu2 {
  display: none;
}
.tlov-shartlari-icon22 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tlov-shartlari-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.tlov-shartlari-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tlov-shartlari-container49 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.tlov-shartlari-container50 {
  gap: 4px;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-end;
  background-color: var(--dl-color-primary-active_red);
}
.tlov-shartlari-textinput2 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  border-width: 0px;
  border-radius: 0px;
}
.tlov-shartlari-container51 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.tlov-shartlari-icon24 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 24px;
}
.tlov-shartlari-container52 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.tlov-shartlari-text257 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tlov-shartlari-text258 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tlov-shartlari-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tlov-shartlari-icon26 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tlov-shartlari-links2 {
  gap: 1px;
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-danger-700);
}
.tlov-shartlari-thq-dropdown16 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle44 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.tlov-shartlari-dropdown-toggle44:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-dropdown-toggle44:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-text259 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tlov-shartlari-thq-dropdown17 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle45 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.tlov-shartlari-dropdown-toggle45:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-dropdown-toggle45:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-text260 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tlov-shartlari-dropdown-arrow5 {
  transition: 0.3s;
}
.tlov-shartlari-dropdown-list5 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tlov-shartlari-dropdown38 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle46 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle46:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text261 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown39 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle47 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle47:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text264 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown40 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle48 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle48:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text265 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown41 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle49 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle49:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text266 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown42 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle50 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle50:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text271 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown43 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle51 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle51:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text272 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown44 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle52 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle52:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text273 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown45 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle53 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.tlov-shartlari-dropdown-toggle53:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text276 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-thq-dropdown18 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle54 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.tlov-shartlari-dropdown-toggle54:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-dropdown-toggle54:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-text277 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tlov-shartlari-dropdown-arrow6 {
  transition: 0.3s;
}
.tlov-shartlari-dropdown-list6 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tlov-shartlari-dropdown46 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle55 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle55:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text278 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown47 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle56 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle56:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text279 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown48 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle57 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle57:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text280 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown49 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle58 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle58:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text281 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown50 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle59 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle59:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text284 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown51 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle60 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle60:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text288 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown52 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle61 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle61:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text289 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-thq-dropdown19 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle62 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.tlov-shartlari-dropdown-toggle62:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-dropdown-toggle62:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-text290 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tlov-shartlari-dropdown-arrow7 {
  transition: 0.3s;
}
.tlov-shartlari-dropdown-list7 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tlov-shartlari-dropdown53 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle63 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle63:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text291 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown54 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle64 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle64:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text292 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown55 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle65 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle65:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text293 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown56 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle66 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.tlov-shartlari-dropdown-toggle66:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text294 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown57 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle67 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle67:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text295 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown58 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle68 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle68:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text298 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown59 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle69 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle69:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text301 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown60 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle70 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle70:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text302 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown61 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle71 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle71:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text303 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-thq-dropdown20 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle72 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.tlov-shartlari-dropdown-toggle72:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-dropdown-toggle72:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-text304 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tlov-shartlari-thq-dropdown21 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle73 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.tlov-shartlari-dropdown-toggle73:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-dropdown-toggle73:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tlov-shartlari-text305 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tlov-shartlari-dropdown-arrow8 {
  transition: 0.3s;
}
.tlov-shartlari-dropdown-list8 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tlov-shartlari-dropdown62 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle74 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle74:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text306 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown63 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle75 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle75:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text307 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown64 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle76 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle76:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text308 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-dropdown65 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tlov-shartlari-dropdown-toggle77 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tlov-shartlari-dropdown-toggle77:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tlov-shartlari-text309 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tlov-shartlari-container53 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #222222;
}
.tlov-shartlari-container54 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.tlov-shartlari-image22 {
  width: 72px;
  height: 20px;
  object-fit: cover;
}
.tlov-shartlari-text312 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 440px;
  font-size: 14px;
}
.tlov-shartlari-container55 {
  gap: var(--dl-space-space-unit);
  width: 196px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.tlov-shartlari-container56 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.tlov-shartlari-text313 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-style: normal;
  font-weight: 500;
}
.tlov-shartlari-image23 {
  width: auto;
  object-fit: cover;
}
.tlov-shartlari-container57 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.tlov-shartlari-navlink82 {
  display: contents;
}
.tlov-shartlari-container58 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.tlov-shartlari-icon36 {
  color: var(--dl-color-gray-700);
  width: 22px;
  height: 22px;
}
.tlov-shartlari-text314 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.tlov-shartlari-text314:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tlov-shartlari-navlink83 {
  display: contents;
}
.tlov-shartlari-container59 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.tlov-shartlari-icon38 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tlov-shartlari-text315 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.tlov-shartlari-text315:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tlov-shartlari-navlink84 {
  display: contents;
}
.tlov-shartlari-container60 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.tlov-shartlari-icon40 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tlov-shartlari-text319 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.tlov-shartlari-text319:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tlov-shartlari-navlink85 {
  display: contents;
}
.tlov-shartlari-container61 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.tlov-shartlari-icon42 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tlov-shartlari-text323 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.tlov-shartlari-text323:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tlov-shartlari-navlink86 {
  display: contents;
}
.tlov-shartlari-container62 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.tlov-shartlari-icon44 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tlov-shartlari-text327 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.tlov-shartlari-text327:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tlov-shartlari-container63 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #181818;
}
.tlov-shartlari-container64 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.tlov-shartlari-text331 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
}
.tlov-shartlari-container65 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.tlov-shartlari-navlink87 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  transition: 0.3s;
  text-decoration: none;
}
.tlov-shartlari-navlink87:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tlov-shartlari-navlink88 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 14px;
  transition: 0.3s;
  text-decoration: none;
}
.tlov-shartlari-navlink88:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tlov-shartlari-image24 {
  width: auto;
  object-fit: cover;
}
.tlov-shartlari-link {
  display: contents;
}
.tlov-shartlari-image25 {
  width: auto;
  margin: 0px;
  text-decoration: none;
}
@media(max-width: 1200px) {
  .tlov-shartlari-text100 {
    color: var(--dl-color-gray-white);
    font-family: Roboto;
  }
  .tlov-shartlari-container16 {
    width: 100%;
  }
  .tlov-shartlari-text116 {
    width: 700px;
  }
  .tlov-shartlari-container29 {
    width: 700px;
  }
  .tlov-shartlari-container30 {
    width: 100%;
    height: 245px;
  }
  .tlov-shartlari-container32 {
    width: 100%;
  }
  .tlov-shartlari-container34 {
    width: 700px;
  }
  .tlov-shartlari-container39 {
    width: 700px;
    height: 306px;
  }
  .tlov-shartlari-select {
    background-color: var(--dl-color-gray-white);
  }
  .tlov-shartlari-dropdown-toggle10 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tlov-shartlari-dropdown-toggle11 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tlov-shartlari-dropdown-toggle20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tlov-shartlari-dropdown-toggle28 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tlov-shartlari-dropdown-toggle38 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tlov-shartlari-dropdown-toggle39 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media(max-width: 991px) {
  .tlov-shartlari-container11 {
    height: 200px;
  }
  .tlov-shartlari-container12 {
    display: none;
  }
  .tlov-shartlari-container16 {
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
  }
  .tlov-shartlari-container17 {
    display: none;
  }
  .tlov-shartlari-container28 {
    border-left-width: 0px;
  }
  .tlov-shartlari-container41 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .tlov-shartlari-navbar-interactive {
    padding-left: 50px;
    padding-right: 50px;
  }
  .tlov-shartlari-dropdown-toggle10 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .tlov-shartlari-text197 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .tlov-shartlari-dropdown-toggle11 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .tlov-shartlari-text198 {
    font-size: 14px;
  }
  .tlov-shartlari-dropdown-toggle20 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .tlov-shartlari-text216 {
    font-size: 14px;
  }
  .tlov-shartlari-dropdown-toggle28 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .tlov-shartlari-text229 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .tlov-shartlari-dropdown-toggle38 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .tlov-shartlari-text247 {
    font-size: 14px;
  }
  .tlov-shartlari-dropdown-toggle39 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .tlov-shartlari-text248 {
    font-size: 14px;
  }
  .tlov-shartlari-textinput1 {
    width: var(--dl-size-size-medium);
  }
  .tlov-shartlari-container53 {
    align-self: flex-start;
  }
  .tlov-shartlari-container63 {
    align-self: flex-start;
  }
}
@media(max-width: 767px) {
  .tlov-shartlari-burger-menu1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tlov-shartlari-icon10 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .tlov-shartlari-container11 {
    height: 100%;
    margin-top: 98px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: var(--dl-space-space-threeunits);
    background-size: cover;
    background-image: url("/frame%2043-200h.png");
  }
  .tlov-shartlari-container12 {
    display: none;
  }
  .tlov-shartlari-container16 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .tlov-shartlari-container28 {
    padding-left: 0px;
  }
  .tlov-shartlari-text116 {
    width: 600px;
  }
  .tlov-shartlari-container29 {
    width: 600px;
  }
  .tlov-shartlari-container30 {
    height: 283px;
  }
  .tlov-shartlari-container34 {
    width: 600px;
  }
  .tlov-shartlari-container39 {
    width: 600px;
  }
  .tlov-shartlari-container41 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .tlov-shartlari-text158 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .tlov-shartlari-navbar-interactive {
    align-self: center;
    padding-top: 0px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: 0px;
    justify-content: space-between;
  }
  .tlov-shartlari-text197 {
    font-size: 12px;
  }
  .tlov-shartlari-text198 {
    font-size: 12px;
  }
  .tlov-shartlari-text216 {
    font-size: 12px;
  }
  .tlov-shartlari-text229 {
    font-size: 12px;
  }
  .tlov-shartlari-text247 {
    font-size: 12px;
  }
  .tlov-shartlari-text248 {
    font-size: 12px;
  }
  .tlov-shartlari-container46 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .tlov-shartlari-container48 {
    display: none;
  }
  .tlov-shartlari-burger-menu2 {
    align-items: center;
    justify-content: center;
  }
  .tlov-shartlari-icon22 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .tlov-shartlari-container50 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .tlov-shartlari-container52 {
    display: none;
  }
  .tlov-shartlari-icon26 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .tlov-shartlari-container53 {
    gap: var(--dl-space-space-unit);
    align-self: flex-start;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
    justify-content: center;
  }
  .tlov-shartlari-container63 {
    align-self: flex-start;
  }
}
@media(max-width: 479px) {
  .tlov-shartlari-icon10 {
    width: 32px;
    height: 32px;
  }
  .tlov-shartlari-container11 {
    margin-top: 150px;
    align-items: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .tlov-shartlari-text100 {
    color: var(--dl-color-gray-white);
    width: 273px;
    height: 50px;
    align-self: flex-start;
    font-family: Roboto;
  }
  .tlov-shartlari-container16 {
    width: 100%;
    align-items: flex-start;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .tlov-shartlari-container28 {
    padding-left: 0px;
  }
  .tlov-shartlari-text113 {
    width: 100%;
  }
  .tlov-shartlari-text116 {
    width: 100%;
  }
  .tlov-shartlari-container29 {
    width: 400px;
  }
  .tlov-shartlari-container30 {
    height: 404px;
  }
  .tlov-shartlari-container34 {
    width: 400px;
  }
  .tlov-shartlari-container39 {
    width: 400px;
    height: 417px;
  }
  .tlov-shartlari-container41 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: center;
  }
  .tlov-shartlari-image19 {
    width: 103px;
    height: 60px;
    margin-right: 0px;
  }
  .tlov-shartlari-text158 {
    color: var(--dl-color-gray-black);
    width: 200px;
  }
  .tlov-shartlari-navbar-interactive {
    top: 133px;
    flex: 1;
    left: 0px;
    align-self: flex-end;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .tlov-shartlari-desktop-menu {
    display: none;
  }
  .tlov-shartlari-container46 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .tlov-shartlari-textinput1 {
    width: var(--dl-size-size-large);
  }
  .tlov-shartlari-burger-menu2 {
    display: flex;
  }
  .tlov-shartlari-icon22 {
    width: 32px;
    height: 32px;
  }
  .tlov-shartlari-mobile-menu {
    height: auto;
    padding: 0px;
  }
  .tlov-shartlari-container49 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
    background-color: var(--dl-color-primary-active_red);
  }
  .tlov-shartlari-container50 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .tlov-shartlari-icon26 {
    width: 32px;
    height: 32px;
  }
  .tlov-shartlari-links2 {
    gap: 0.5PX;
    width: 100%;
    height: auto;
    flex-direction: column;
  }
  .tlov-shartlari-thq-dropdown16 {
    width: 100%;
  }
  .tlov-shartlari-dropdown-toggle44 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .tlov-shartlari-text259 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .tlov-shartlari-thq-dropdown17 {
    width: 100%;
  }
  .tlov-shartlari-dropdown-toggle45 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .tlov-shartlari-text260 {
    font-size: 14px;
  }
  .tlov-shartlari-icon28 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .tlov-shartlari-dropdown-list5 {
    background-color: var(--dl-color-primary-main);
  }
  .tlov-shartlari-dropdown-toggle46 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text261 {
    font-size: 14px;
  }
  .tlov-shartlari-dropdown-toggle47 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text264 {
    font-size: 14px;
  }
  .tlov-shartlari-dropdown-toggle48 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text265 {
    font-size: 14px;
  }
  .tlov-shartlari-dropdown-toggle49 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text266 {
    font-size: 14px;
  }
  .tlov-shartlari-dropdown-toggle50 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text271 {
    font-size: 14px;
  }
  .tlov-shartlari-dropdown-toggle51 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text272 {
    font-size: 14px;
  }
  .tlov-shartlari-dropdown-toggle52 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text273 {
    font-size: 14px;
  }
  .tlov-shartlari-dropdown-toggle53 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text276 {
    font-size: 14px;
  }
  .tlov-shartlari-thq-dropdown18 {
    width: 100%;
  }
  .tlov-shartlari-dropdown-toggle54 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .tlov-shartlari-text277 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .tlov-shartlari-icon30 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .tlov-shartlari-dropdown-list6 {
    background-color: var(--dl-color-primary-main);
  }
  .tlov-shartlari-dropdown-toggle55 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text278 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-dropdown-toggle56 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text279 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-dropdown-toggle57 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text280 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-dropdown-toggle58 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text281 {
    font-size: 14px;
    font-family: Roboto;
  }
  .tlov-shartlari-dropdown-toggle59 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text284 {
    font-size: 14px;
    font-family: Roboto;
  }
  .tlov-shartlari-dropdown-toggle60 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text288 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-dropdown-toggle61 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text289 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-thq-dropdown19 {
    width: 100%;
  }
  .tlov-shartlari-dropdown-toggle62 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .tlov-shartlari-text290 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .tlov-shartlari-icon32 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .tlov-shartlari-dropdown-list7 {
    background-color: var(--dl-color-primary-main);
  }
  .tlov-shartlari-dropdown-toggle63 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text291 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-dropdown-toggle64 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text292 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-dropdown-toggle65 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text293 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-dropdown-toggle66 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text294 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-dropdown-toggle67 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text295 {
    font-size: 14px;
    font-family: Roboto;
  }
  .tlov-shartlari-dropdown-toggle68 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text298 {
    font-size: 14px;
  }
  .tlov-shartlari-dropdown-toggle69 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text301 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-dropdown-toggle70 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text302 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-dropdown-toggle71 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text303 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-thq-dropdown20 {
    width: 100%;
  }
  .tlov-shartlari-dropdown-toggle72 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .tlov-shartlari-text304 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .tlov-shartlari-thq-dropdown21 {
    width: 100%;
  }
  .tlov-shartlari-dropdown-toggle73 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .tlov-shartlari-text305 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .tlov-shartlari-icon34 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .tlov-shartlari-dropdown-list8 {
    background-color: var(--dl-color-primary-main);
  }
  .tlov-shartlari-dropdown-toggle74 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text306 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-dropdown-toggle75 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text307 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-dropdown-toggle76 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text308 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tlov-shartlari-dropdown-toggle77 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tlov-shartlari-text309 {
    font-size: 14px;
    font-family: Roboto;
  }
  .tlov-shartlari-container53 {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .tlov-shartlari-text312 {
    width: 250px;
  }
  .tlov-shartlari-container63 {
    gap: var(--dl-space-space-halfunit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .tlov-shartlari-container64 {
    gap: var(--dl-space-space-halfunit);
    align-self: center;
    flex-direction: column;
  }
  .tlov-shartlari-container65 {
    flex-wrap: wrap;
    align-self: center;
    flex-direction: row;
  }
  .tlov-shartlari-image24 {
    align-self: center;
  }
  .tlov-shartlari-image25 {
    display: none;
  }
}
