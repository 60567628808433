.ariza-shakllari-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.ariza-shakllari-burger-menu1 {
  display: none;
}
.ariza-shakllari-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.ariza-shakllari-container11 {
  gap: 60px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: 146px;
  align-items: flex-start;
  padding-top: 60px;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/frame%2043-200h.png");
  background-repeat: space;
  background-position: center;
}
.ariza-shakllari-text100 {
  color: var(--dl-color-gray-white);
}
.ariza-shakllari-container12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  background-color: #F0F0F0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.ariza-shakllari-navlink10 {
  display: contents;
}
.ariza-shakllari-container13 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.ariza-shakllari-icon12 {
  fill: var(--dl-color-primary-500);
  color: var(--dl-color-primary-500);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.ariza-shakllari-text101 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.ariza-shakllari-navlink11 {
  display: contents;
}
.ariza-shakllari-container14 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.ariza-shakllari-icon14 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 16px;
  height: 16px;
}
.ariza-shakllari-text102 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.ariza-shakllari-navlink12 {
  display: contents;
}
.ariza-shakllari-container15 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.ariza-shakllari-icon16 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 16px;
  height: 16px;
}
.ariza-shakllari-text103 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}
.ariza-shakllari-container16 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  margin: 100px;
  display: flex;
  align-self: flex-start;
  margin-top: 0px;
  align-items: flex-start;
  margin-left: 0px;
  padding-top: var(--dl-space-space-twounits);
  margin-right: 0px;
  padding-left: 100px;
  margin-bottom: 0px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
}
.ariza-shakllari-container17 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ariza-shakllari-container18 {
  gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ariza-shakllari-text104 {
  color: var(--dl-color-gray-500);
  font-style: normal;
  font-weight: 500;
}
.ariza-shakllari-icon18 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 12px;
  height: 12px;
}
.ariza-shakllari-image10 {
  width: auto;
  object-fit: cover;
}
.ariza-shakllari-container19 {
  gap: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.ariza-shakllari-navlink13 {
  display: contents;
}
.ariza-shakllari-container20 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.ariza-shakllari-image11 {
  width: var(--dl-size-size-xsmall);
  object-fit: cover;
}
.ariza-shakllari-text105 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.ariza-shakllari-navlink14 {
  display: contents;
}
.ariza-shakllari-container21 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.ariza-shakllari-container21:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.ariza-shakllari-image12 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.ariza-shakllari-text106 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.ariza-shakllari-text106:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.ariza-shakllari-navlink15 {
  display: contents;
}
.ariza-shakllari-container22 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.ariza-shakllari-container22:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.ariza-shakllari-image13 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.ariza-shakllari-text107 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.ariza-shakllari-text107:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.ariza-shakllari-navlink16 {
  display: contents;
}
.ariza-shakllari-container23 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.ariza-shakllari-container23:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.ariza-shakllari-image14 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.ariza-shakllari-text108 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.ariza-shakllari-text108:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.ariza-shakllari-navlink17 {
  display: contents;
}
.ariza-shakllari-container24 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.ariza-shakllari-container24:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.ariza-shakllari-image15 {
  width: var(--dl-size-size-xsmall);
  align-self: center;
  object-fit: cover;
  transition: 0.3s;
}

.ariza-shakllari-text109 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  align-self: center;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.ariza-shakllari-text109:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.ariza-shakllari-navlink18 {
  display: contents;
}
.ariza-shakllari-container25 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-gray-main);
}
.ariza-shakllari-container25:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.ariza-shakllari-image16 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.ariza-shakllari-text110 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.ariza-shakllari-text110:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.ariza-shakllari-navlink19 {
  display: contents;
}
.ariza-shakllari-container26 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
  border-bottom-width: 1px;
}
.ariza-shakllari-container26:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.ariza-shakllari-image17 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.ariza-shakllari-text111 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.ariza-shakllari-text111:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.ariza-shakllari-container27 {
  gap: var(--dl-space-space-twounits);
  width: 1137px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  border-left-width: 1px;
}
.ariza-shakllari-container28 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
}
.ariza-shakllari-container29 {
  gap: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.ariza-shakllari-container30 {
  width: 60px;
  height: 60px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 100px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.ariza-shakllari-container30:hover {
  width: 70px;
  height: 70px;
  margin-right: 22px;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-image18 {
  width: auto;
  height: auto;
  object-fit: cover;
}
.ariza-shakllari-container31 {
  gap: var(--dl-space-space-unit);
  width: 332px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ariza-shakllari-text112 {
  color: var(--dl-color-gray-newblack);
  font-size: 18px;
  text-align: center;
  text-decoration: underline;
}
.ariza-shakllari-container32 {
  gap: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.ariza-shakllari-container33 {
  width: 60px;
  height: 60px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 100px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.ariza-shakllari-container33:hover {
  width: 70px;
  height: 70px;
  margin-right: 22px;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-image19 {
  width: auto;
  height: auto;
  object-fit: cover;
}
.ariza-shakllari-container34 {
  gap: var(--dl-space-space-unit);
  width: 332px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ariza-shakllari-text113 {
  color: var(--dl-color-gray-newblack);
  font-size: 18px;
  text-align: center;
  text-decoration: underline;
}
.ariza-shakllari-container35 {
  gap: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.ariza-shakllari-container36 {
  width: 60px;
  height: 60px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 100px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.ariza-shakllari-container36:hover {
  width: 70px;
  height: 70px;
  margin-right: 22px;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-image20 {
  width: auto;
  height: auto;
  object-fit: cover;
}
.ariza-shakllari-container37 {
  gap: var(--dl-space-space-unit);
  width: 332px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ariza-shakllari-text114 {
  color: var(--dl-color-gray-newblack);
  font-size: 18px;
  text-align: center;
  text-decoration: underline;
}
.ariza-shakllari-container38 {
  gap: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.ariza-shakllari-container39 {
  width: 60px;
  height: 60px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 100px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.ariza-shakllari-container39:hover {
  width: 70px;
  height: 70px;
  margin-right: 22px;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-image21 {
  width: auto;
  height: auto;
  object-fit: cover;
}
.ariza-shakllari-text115 {
  color: var(--dl-color-gray-newblack);
  font-size: 18px;
  text-align: center;
  text-decoration: underline;
}
.ariza-shakllari-container40 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
}
.ariza-shakllari-button {
  gap: 4px;
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  display: flex;
  align-self: flex-start;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
  background-color: #498074;
}
.ariza-shakllari-button:hover {
  background-color: #233D37;
}
.ariza-shakllari-text116 {
  font-size: 14px;
}
.ariza-shakllari-link1 {
  gap: 4px;
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  display: flex;
  align-self: flex-start;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: #498074;
}
.ariza-shakllari-link1:hover {
  background-color: #233D37;
}
.ariza-shakllari-text119 {
  font-size: 14px;
}
.ariza-shakllari-container41 {
  flex: 0;
  width: 100%;
  display: flex;
  position: fixed;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.ariza-shakllari-navlink20 {
  display: contents;
}
.ariza-shakllari-container42 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}
.ariza-shakllari-image22 {
  width: 106px;
  height: 64px;
  text-decoration: none;
}
.ariza-shakllari-text122 {
  fill: var(--dl-color-primary-main);
  color: var(--dl-color-primary-main);
  width: 275px;
  height: 52px;
  font-size: 10px;
  max-width: auto;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  margin-left: var(--dl-space-space-fourunits);
}
.ariza-shakllari-container43 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.ariza-shakllari-container44 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.ariza-shakllari-text125 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-family: "Roboto";
}
.ariza-shakllari-text127 {
  font-style: normal;
  font-weight: 700;
}
.ariza-shakllari-text128 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-family: "Roboto";
}
.ariza-shakllari-text129 {
  font-style: normal;
  font-weight: 700;
}
.ariza-shakllari-image23 {
  width: 16px;
  height: 11px;
  object-fit: cover;
}
.ariza-shakllari-text160 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 700;
}
.ariza-shakllari-image24 {
  width: 16px;
  height: 11px;
  object-fit: cover;
}
.ariza-shakllari-select {
  height: auto;
  font-size: x-small;
  background-color: var(--dl-color-gray-white);
}
.ariza-shakllari-navbar-interactive {
  width: 100%;
  display: flex;
  position: fixed;
  align-self: center;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: px;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: px;
  justify-content: center;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-desktop-menu {
  flex: 1;
  width: auto;
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
}
.ariza-shakllari-container45 {
  display: flex;
  justify-content: space-between;
}
.ariza-shakllari-links1 {
  gap: 1px;
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-danger-700);
}
.ariza-shakllari-thq-dropdown10 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-dropdown-toggle10:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: mixed;
  padding-right: mixed;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-dropdown-toggle10:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-text161 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.ariza-shakllari-thq-dropdown11 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-dropdown-toggle11:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-dropdown-toggle11:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-text162 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.ariza-shakllari-dropdown-arrow1 {
  transition: 0.3s;
}
.ariza-shakllari-dropdown-list1 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.ariza-shakllari-dropdown10 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle12 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle12:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text163 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.ariza-shakllari-dropdown11 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle13 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle13:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text166 {
  font-style: normal;
}
.ariza-shakllari-dropdown12 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle14 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle14:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text167 {
  font-style: normal;
}
.ariza-shakllari-dropdown13 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle15 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle15:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text168 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.ariza-shakllari-dropdown14 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle16 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle16:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text173 {
  font-style: normal;
}
.ariza-shakllari-dropdown15 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle17 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle17:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text174 {
  font-style: normal;
}
.ariza-shakllari-dropdown16 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle18 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle18:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text175 {
  font-style: normal;
}
.ariza-shakllari-dropdown17 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle19 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.ariza-shakllari-dropdown-toggle19:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text179 {
  font-style: normal;
}
.ariza-shakllari-thq-dropdown12 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle20 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-dropdown-toggle20:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-dropdown-toggle20:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-text180 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.ariza-shakllari-dropdown-arrow2 {
  transition: 0.3s;
}
.ariza-shakllari-dropdown-list2 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.ariza-shakllari-dropdown18 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle21 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle21:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text181 {
  font-style: normal;
}
.ariza-shakllari-dropdown19 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle22 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle22:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text182 {
  font-style: normal;
}
.ariza-shakllari-dropdown20 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle23 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle23:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text183 {
  font-style: normal;
}
.ariza-shakllari-dropdown21 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle24 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle24:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text184 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.ariza-shakllari-dropdown22 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle25 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle25:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text187 {
  font-style: normal;
}
.ariza-shakllari-dropdown23 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle26 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle26:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text191 {
  font-style: normal;
}
.ariza-shakllari-dropdown24 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle27 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  padding-bottom: 20px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle27:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text192 {
  font-style: normal;
}
.ariza-shakllari-thq-dropdown13 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle28 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-dropdown-toggle28:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-dropdown-toggle28:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-text193 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.ariza-shakllari-dropdown-arrow3 {
  transition: 0.3s;
}
.ariza-shakllari-dropdown-list3 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.ariza-shakllari-dropdown25 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle29 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle29:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text194 {
  font-style: normal;
}
.ariza-shakllari-dropdown26 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle30 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle30:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text195 {
  font-style: normal;
}
.ariza-shakllari-dropdown27 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle31 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle31:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text199 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.ariza-shakllari-dropdown28 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle32 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle32:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text202 {
  font-style: normal;
}
.ariza-shakllari-dropdown29 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle33 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle33:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text203 {
  font-style: normal;
}
.ariza-shakllari-dropdown30 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle34 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle34:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text207 {
  font-style: normal;
}
.ariza-shakllari-dropdown31 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle35 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.ariza-shakllari-dropdown-toggle35:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text208 {
  font-style: normal;
}
.ariza-shakllari-dropdown32 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle36 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle36:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-dropdown33 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle37 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle37:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-thq-dropdown14 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle38 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-dropdown-toggle38:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-dropdown-toggle38:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-text211 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.ariza-shakllari-thq-dropdown15 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle39 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-dropdown-toggle39:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-dropdown-toggle39:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-text212 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.ariza-shakllari-dropdown-arrow4 {
  transition: 0.3s;
}
.ariza-shakllari-dropdown-list4 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.ariza-shakllari-dropdown34 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle40 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle40:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text213 {
  font-style: normal;
}
.ariza-shakllari-dropdown35 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle41 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle41:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text214 {
  font-style: normal;
}
.ariza-shakllari-dropdown36 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle42 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle42:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text215 {
  font-style: normal;
}
.ariza-shakllari-dropdown37 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle43 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle43:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text216 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.ariza-shakllari-container46 {
  gap: 4px;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-end;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-textinput1 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  border-width: 0px;
  border-radius: 0px;
}
.ariza-shakllari-container47 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.ariza-shakllari-icon20 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 24px;
}
.ariza-shakllari-container48 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.ariza-shakllari-text219 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.ariza-shakllari-text220 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.ariza-shakllari-burger-menu2 {
  display: none;
}
.ariza-shakllari-icon22 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.ariza-shakllari-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.ariza-shakllari-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ariza-shakllari-container49 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.ariza-shakllari-container50 {
  gap: 4px;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-end;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-textinput2 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  border-width: 0px;
  border-radius: 0px;
}
.ariza-shakllari-container51 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.ariza-shakllari-icon24 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 24px;
}
.ariza-shakllari-container52 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.ariza-shakllari-text221 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.ariza-shakllari-text222 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.ariza-shakllari-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ariza-shakllari-icon26 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.ariza-shakllari-links2 {
  gap: 1px;
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-danger-700);
}
.ariza-shakllari-thq-dropdown16 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle44 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-dropdown-toggle44:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-dropdown-toggle44:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-text223 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.ariza-shakllari-thq-dropdown17 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle45 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-dropdown-toggle45:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-dropdown-toggle45:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-text224 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.ariza-shakllari-dropdown-arrow5 {
  transition: 0.3s;
}
.ariza-shakllari-dropdown-list5 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.ariza-shakllari-dropdown38 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle46 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle46:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text225 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown39 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle47 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle47:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text228 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown40 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle48 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle48:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text229 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown41 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle49 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle49:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text230 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown42 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle50 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle50:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text235 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown43 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle51 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle51:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text236 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown44 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle52 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle52:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text237 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown45 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle53 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.ariza-shakllari-dropdown-toggle53:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text240 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-thq-dropdown18 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle54 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-dropdown-toggle54:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-dropdown-toggle54:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-text241 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.ariza-shakllari-dropdown-arrow6 {
  transition: 0.3s;
}
.ariza-shakllari-dropdown-list6 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.ariza-shakllari-dropdown46 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle55 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle55:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text242 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown47 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle56 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle56:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text243 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown48 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle57 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle57:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text244 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown49 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle58 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle58:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text245 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown50 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle59 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle59:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text248 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown51 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle60 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle60:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text252 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown52 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle61 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle61:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text253 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-thq-dropdown19 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle62 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-dropdown-toggle62:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-dropdown-toggle62:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-text254 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.ariza-shakllari-dropdown-arrow7 {
  transition: 0.3s;
}
.ariza-shakllari-dropdown-list7 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.ariza-shakllari-dropdown53 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle63 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle63:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text255 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown54 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle64 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle64:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text256 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown55 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle65 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle65:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text257 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown56 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle66 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.ariza-shakllari-dropdown-toggle66:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text258 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown57 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle67 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle67:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text259 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown58 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle68 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle68:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text262 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown59 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle69 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle69:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text265 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown60 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle70 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle70:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text266 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown61 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle71 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle71:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text267 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-thq-dropdown20 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle72 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-dropdown-toggle72:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-dropdown-toggle72:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-text268 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.ariza-shakllari-thq-dropdown21 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle73 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.ariza-shakllari-dropdown-toggle73:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-dropdown-toggle73:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.ariza-shakllari-text269 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.ariza-shakllari-dropdown-arrow8 {
  transition: 0.3s;
}
.ariza-shakllari-dropdown-list8 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.ariza-shakllari-dropdown62 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle74 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle74:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text270 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown63 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle75 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle75:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text271 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown64 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle76 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle76:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text272 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-dropdown65 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.ariza-shakllari-dropdown-toggle77 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ariza-shakllari-dropdown-toggle77:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.ariza-shakllari-text273 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ariza-shakllari-container53 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #222222;
}
.ariza-shakllari-container54 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.ariza-shakllari-image25 {
  width: 72px;
  height: 20px;
  object-fit: cover;
}
.ariza-shakllari-text276 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 440px;
  font-size: 14px;
}
.ariza-shakllari-container55 {
  gap: var(--dl-space-space-unit);
  width: 196px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ariza-shakllari-container56 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ariza-shakllari-text277 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-style: normal;
  font-weight: 500;
}
.ariza-shakllari-image26 {
  width: auto;
  object-fit: cover;
}
.ariza-shakllari-container57 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ariza-shakllari-navlink81 {
  display: contents;
}
.ariza-shakllari-container58 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.ariza-shakllari-icon36 {
  color: var(--dl-color-gray-700);
  width: 22px;
  height: 22px;
}
.ariza-shakllari-text278 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.ariza-shakllari-text278:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.ariza-shakllari-navlink82 {
  display: contents;
}
.ariza-shakllari-container59 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.ariza-shakllari-icon38 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.ariza-shakllari-text279 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.ariza-shakllari-text279:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.ariza-shakllari-navlink83 {
  display: contents;
}
.ariza-shakllari-container60 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.ariza-shakllari-icon40 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.ariza-shakllari-text283 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.ariza-shakllari-text283:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.ariza-shakllari-navlink84 {
  display: contents;
}
.ariza-shakllari-container61 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.ariza-shakllari-icon42 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.ariza-shakllari-text287 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.ariza-shakllari-text287:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.ariza-shakllari-navlink85 {
  display: contents;
}
.ariza-shakllari-container62 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.ariza-shakllari-icon44 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.ariza-shakllari-text291 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.ariza-shakllari-text291:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.ariza-shakllari-container63 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #181818;
}
.ariza-shakllari-container64 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.ariza-shakllari-text295 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
}
.ariza-shakllari-container65 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.ariza-shakllari-navlink86 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  transition: 0.3s;
  text-decoration: none;
}
.ariza-shakllari-navlink86:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.ariza-shakllari-navlink87 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 14px;
  transition: 0.3s;
  text-decoration: none;
}
.ariza-shakllari-navlink87:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.ariza-shakllari-image27 {
  width: auto;
  object-fit: cover;
}
.ariza-shakllari-link2 {
  display: contents;
}
.ariza-shakllari-image28 {
  width: auto;
  margin: 0px;
  text-decoration: none;
}
@media(max-width: 1200px) {
  .ariza-shakllari-text100 {
    color: var(--dl-color-gray-white);
    font-family: Roboto;
  }
  .ariza-shakllari-container16 {
    width: 100%;
  }
  .ariza-shakllari-select {
    background-color: var(--dl-color-gray-white);
  }
  .ariza-shakllari-dropdown-toggle10 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ariza-shakllari-dropdown-toggle11 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ariza-shakllari-dropdown-toggle20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ariza-shakllari-dropdown-toggle28 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ariza-shakllari-dropdown-toggle38 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ariza-shakllari-dropdown-toggle39 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media(max-width: 991px) {
  .ariza-shakllari-container11 {
    height: 200px;
  }
  .ariza-shakllari-container12 {
    display: none;
  }
  .ariza-shakllari-container16 {
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
  }
  .ariza-shakllari-container17 {
    display: none;
  }
  .ariza-shakllari-container27 {
    width: 550px;
    margin-right: 0px;
    border-left-width: 0px;
  }
  .ariza-shakllari-container41 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .ariza-shakllari-navbar-interactive {
    padding-left: 50px;
    padding-right: 50px;
  }
  .ariza-shakllari-dropdown-toggle10 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .ariza-shakllari-text161 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .ariza-shakllari-dropdown-toggle11 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .ariza-shakllari-text162 {
    font-size: 14px;
  }
  .ariza-shakllari-dropdown-toggle20 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .ariza-shakllari-text180 {
    font-size: 14px;
  }
  .ariza-shakllari-dropdown-toggle28 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .ariza-shakllari-text193 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .ariza-shakllari-dropdown-toggle38 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .ariza-shakllari-text211 {
    font-size: 14px;
  }
  .ariza-shakllari-dropdown-toggle39 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .ariza-shakllari-text212 {
    font-size: 14px;
  }
  .ariza-shakllari-textinput1 {
    width: var(--dl-size-size-medium);
  }
  .ariza-shakllari-container53 {
    align-self: flex-start;
  }
  .ariza-shakllari-container63 {
    align-self: flex-start;
  }
}
@media(max-width: 767px) {
  .ariza-shakllari-burger-menu1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ariza-shakllari-icon10 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .ariza-shakllari-container11 {
    height: 100%;
    margin-top: 98px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: var(--dl-space-space-threeunits);
    background-size: cover;
    background-image: url("/frame%2043-200h.png");
  }
  .ariza-shakllari-container12 {
    display: none;
  }
  .ariza-shakllari-container16 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .ariza-shakllari-container27 {
    padding-left: 0px;
  }
  .ariza-shakllari-container41 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .ariza-shakllari-text122 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .ariza-shakllari-navbar-interactive {
    align-self: center;
    padding-top: 0px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: 0px;
    justify-content: space-between;
  }
  .ariza-shakllari-text161 {
    font-size: 12px;
  }
  .ariza-shakllari-text162 {
    font-size: 12px;
  }
  .ariza-shakllari-text180 {
    font-size: 12px;
  }
  .ariza-shakllari-text193 {
    font-size: 12px;
  }
  .ariza-shakllari-text211 {
    font-size: 12px;
  }
  .ariza-shakllari-text212 {
    font-size: 12px;
  }
  .ariza-shakllari-container46 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .ariza-shakllari-container48 {
    display: none;
  }
  .ariza-shakllari-burger-menu2 {
    align-items: center;
    justify-content: center;
  }
  .ariza-shakllari-icon22 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .ariza-shakllari-container50 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .ariza-shakllari-container52 {
    display: none;
  }
  .ariza-shakllari-icon26 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .ariza-shakllari-container53 {
    gap: var(--dl-space-space-unit);
    align-self: flex-start;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
    justify-content: center;
  }
  .ariza-shakllari-container63 {
    align-self: flex-start;
  }
}
@media(max-width: 479px) {
  .ariza-shakllari-icon10 {
    width: 32px;
    height: 32px;
  }
  .ariza-shakllari-container11 {
    margin-top: 150px;
    align-items: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .ariza-shakllari-text100 {
    color: var(--dl-color-gray-white);
    width: 273px;
    height: 50px;
    font-family: Roboto;
  }
  .ariza-shakllari-container16 {
    width: 100%;
    align-items: flex-start;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .ariza-shakllari-container27 {
    width: 100%;
    padding-left: 0px;
  }
  .ariza-shakllari-container29 {
    width: auto;
  }
  .ariza-shakllari-container30 {
    margin-right: var(--dl-space-space-unit);
  }
  .ariza-shakllari-container31 {
    width: 270px;
  }
  .ariza-shakllari-container32 {
    width: auto;
  }
  .ariza-shakllari-container33 {
    margin-right: var(--dl-space-space-unit);
  }
  .ariza-shakllari-container34 {
    width: 270px;
  }
  .ariza-shakllari-container35 {
    width: auto;
  }
  .ariza-shakllari-container36 {
    margin-right: var(--dl-space-space-unit);
  }
  .ariza-shakllari-container37 {
    width: 270px;
  }
  .ariza-shakllari-container38 {
    width: auto;
  }
  .ariza-shakllari-container39 {
    margin-right: var(--dl-space-space-unit);
  }
  .ariza-shakllari-container41 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: center;
  }
  .ariza-shakllari-image22 {
    width: 103px;
    height: 60px;
    margin-right: 0px;
  }
  .ariza-shakllari-text122 {
    color: var(--dl-color-gray-black);
    width: 200px;
  }
  .ariza-shakllari-navbar-interactive {
    top: 133px;
    flex: 1;
    left: 0px;
    align-self: flex-end;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .ariza-shakllari-desktop-menu {
    display: none;
  }
  .ariza-shakllari-container46 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ariza-shakllari-textinput1 {
    width: var(--dl-size-size-large);
  }
  .ariza-shakllari-burger-menu2 {
    display: flex;
  }
  .ariza-shakllari-icon22 {
    width: 32px;
    height: 32px;
  }
  .ariza-shakllari-mobile-menu {
    height: auto;
    padding: 0px;
  }
  .ariza-shakllari-container49 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
    background-color: var(--dl-color-primary-active_red);
  }
  .ariza-shakllari-container50 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ariza-shakllari-icon26 {
    width: 32px;
    height: 32px;
  }
  .ariza-shakllari-links2 {
    gap: 0.5PX;
    width: 100%;
    height: auto;
    flex-direction: column;
  }
  .ariza-shakllari-thq-dropdown16 {
    width: 100%;
  }
  .ariza-shakllari-dropdown-toggle44 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .ariza-shakllari-text223 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .ariza-shakllari-thq-dropdown17 {
    width: 100%;
  }
  .ariza-shakllari-dropdown-toggle45 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .ariza-shakllari-text224 {
    font-size: 14px;
  }
  .ariza-shakllari-icon28 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .ariza-shakllari-dropdown-list5 {
    background-color: var(--dl-color-primary-main);
  }
  .ariza-shakllari-dropdown-toggle46 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text225 {
    font-size: 14px;
  }
  .ariza-shakllari-dropdown-toggle47 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text228 {
    font-size: 14px;
  }
  .ariza-shakllari-dropdown-toggle48 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text229 {
    font-size: 14px;
  }
  .ariza-shakllari-dropdown-toggle49 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text230 {
    font-size: 14px;
  }
  .ariza-shakllari-dropdown-toggle50 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text235 {
    font-size: 14px;
  }
  .ariza-shakllari-dropdown-toggle51 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text236 {
    font-size: 14px;
  }
  .ariza-shakllari-dropdown-toggle52 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text237 {
    font-size: 14px;
  }
  .ariza-shakllari-dropdown-toggle53 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text240 {
    font-size: 14px;
  }
  .ariza-shakllari-thq-dropdown18 {
    width: 100%;
  }
  .ariza-shakllari-dropdown-toggle54 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .ariza-shakllari-text241 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .ariza-shakllari-icon30 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .ariza-shakllari-dropdown-list6 {
    background-color: var(--dl-color-primary-main);
  }
  .ariza-shakllari-dropdown-toggle55 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text242 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-dropdown-toggle56 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text243 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-dropdown-toggle57 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text244 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-dropdown-toggle58 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text245 {
    font-size: 14px;
    font-family: Roboto;
  }
  .ariza-shakllari-dropdown-toggle59 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text248 {
    font-size: 14px;
    font-family: Roboto;
  }
  .ariza-shakllari-dropdown-toggle60 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text252 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-dropdown-toggle61 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text253 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-thq-dropdown19 {
    width: 100%;
  }
  .ariza-shakllari-dropdown-toggle62 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .ariza-shakllari-text254 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .ariza-shakllari-icon32 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .ariza-shakllari-dropdown-list7 {
    background-color: var(--dl-color-primary-main);
  }
  .ariza-shakllari-dropdown-toggle63 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text255 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-dropdown-toggle64 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text256 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-dropdown-toggle65 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text257 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-dropdown-toggle66 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text258 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-dropdown-toggle67 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text259 {
    font-size: 14px;
    font-family: Roboto;
  }
  .ariza-shakllari-dropdown-toggle68 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text262 {
    font-size: 14px;
  }
  .ariza-shakllari-dropdown-toggle69 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text265 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-dropdown-toggle70 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text266 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-dropdown-toggle71 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text267 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-thq-dropdown20 {
    width: 100%;
  }
  .ariza-shakllari-dropdown-toggle72 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .ariza-shakllari-text268 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .ariza-shakllari-thq-dropdown21 {
    width: 100%;
  }
  .ariza-shakllari-dropdown-toggle73 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .ariza-shakllari-text269 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .ariza-shakllari-icon34 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .ariza-shakllari-dropdown-list8 {
    background-color: var(--dl-color-primary-main);
  }
  .ariza-shakllari-dropdown-toggle74 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text270 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-dropdown-toggle75 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text271 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-dropdown-toggle76 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text272 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .ariza-shakllari-dropdown-toggle77 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .ariza-shakllari-text273 {
    font-size: 14px;
    font-family: Roboto;
  }
  .ariza-shakllari-container53 {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .ariza-shakllari-text276 {
    width: 250px;
  }
  .ariza-shakllari-container63 {
    gap: var(--dl-space-space-halfunit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .ariza-shakllari-container64 {
    gap: var(--dl-space-space-halfunit);
    align-self: center;
    flex-direction: column;
  }
  .ariza-shakllari-container65 {
    flex-wrap: wrap;
    align-self: center;
    flex-direction: row;
  }
  .ariza-shakllari-image27 {
    align-self: center;
  }
  .ariza-shakllari-image28 {
    display: none;
  }
}
