.uzbekistan-germanyuz-container10 {
  width: 100%;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  min-height: 100vh;
  align-items: center;
  padding-top: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-container11 {
  flex: 0;
  width: 100%;
  display: flex;
  position: fixed;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.uzbekistan-germanyuz-navlink10 {
  display: contents;
}
.uzbekistan-germanyuz-container12 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}
.uzbekistan-germanyuz-image10 {
  width: 106px;
  height: 64px;
  text-decoration: none;
}
.uzbekistan-germanyuz-text100 {
  fill: var(--dl-color-primary-main);
  color: var(--dl-color-primary-main);
  width: 275px;
  height: 52px;
  font-size: 10px;
  max-width: auto;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  margin-left: var(--dl-space-space-fourunits);
}
.uzbekistan-germanyuz-container13 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.uzbekistan-germanyuz-container14 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.uzbekistan-germanyuz-text103 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-family: "Roboto";
}
.uzbekistan-germanyuz-text105 {
  font-style: normal;
  font-weight: 700;
}
.uzbekistan-germanyuz-text106 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-family: "Roboto";
}
.uzbekistan-germanyuz-text107 {
  font-style: normal;
  font-weight: 700;
}
.uzbekistan-germanyuz-image11 {
  width: 16px;
  height: 11px;
  object-fit: cover;
}
.uzbekistan-germanyuz-text138 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 700;
}
.uzbekistan-germanyuz-image12 {
  width: 16px;
  height: 11px;
  object-fit: cover;
}
.uzbekistan-germanyuz-select {
  height: auto;
  font-size: x-small;
  background-color: var(--dl-color-gray-white);
}
.uzbekistan-germanyuz-burger-menu1 {
  display: none;
}
.uzbekistan-germanyuz-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzbekistan-germanyuz-container15 {
  width: 100%;
  height: 300px;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  margin-top: 146px;
  align-items: flex-end;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  background-size: cover;
  justify-content: center;
  background-image: url("/img_slider01-300h.jpg");
  background-repeat: space;
  background-position: center;
}
.uzbekistan-germanyuz-navlink11 {
  display: contents;
}
.uzbekistan-germanyuz-image13 {
  width: auto;
  height: 193px;
  margin-top: 0px;
  object-fit: cover;
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.uzbekistan-germanyuz-image13:hover {
  animation-name: bounce;
  animation-delay: 0s;
  animation-duration: 800ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
.uzbekistan-germanyuz-navlink12 {
  display: contents;
}
.uzbekistan-germanyuz-image14 {
  width: auto;
  height: 193px;
  margin-top: 0px;
  object-fit: cover;
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.uzbekistan-germanyuz-image14:hover {
  animation-name: bounce;
  animation-delay: 0s;
  animation-duration: 800ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
.uzbekistan-germanyuz-navlink13 {
  display: contents;
}
.uzbekistan-germanyuz-image15 {
  width: auto;
  height: 193px;
  margin-top: 0px;
  object-fit: cover;
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.uzbekistan-germanyuz-image15:hover {
  animation-name: bounce;
  animation-delay: 0s;
  animation-duration: 800ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
.uzbekistan-germanyuz-navlink14 {
  display: contents;
}
.uzbekistan-germanyuz-image16 {
  width: 200px;
  object-fit: cover;
  transition: 0.3s;
  text-decoration: none;
}
.uzbekistan-germanyuz-image16:hover {
  animation-name: bounce;
  animation-delay: 0s;
  animation-duration: 800ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
.uzbekistan-germanyuz-container16 {
  gap: var(--dl-space-space-twounits);
  width: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
}
.uzbekistan-germanyuz-navlink15 {
  display: contents;
}
.uzbekistan-germanyuz-container17 {
  gap: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzbekistan-germanyuz-container18 {
  width: 60px;
  height: 60px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 100px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.uzbekistan-germanyuz-container18:hover {
  width: 70px;
  height: 70px;
  margin-right: 22px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-image17 {
  width: auto;
  height: auto;
  object-fit: cover;
}
.uzbekistan-germanyuz-container19 {
  gap: var(--dl-space-space-unit);
  width: 332px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-text139 {
  fill: var(--dl-color-gray-newblack);
  color: var(--dl-color-gray-newblack);
  font-size: 20px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
}
.uzbekistan-germanyuz-text140 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
  border-color: var(--dl-color-gray-900);
  text-transform: none;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-navlink16 {
  display: contents;
}
.uzbekistan-germanyuz-container20 {
  gap: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzbekistan-germanyuz-container21 {
  width: 60px;
  height: 60px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 100px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.uzbekistan-germanyuz-container21:hover {
  width: 70px;
  height: 70px;
  margin-right: 22px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-image18 {
  width: auto;
  height: auto;
  object-fit: cover;
}
.uzbekistan-germanyuz-container22 {
  gap: var(--dl-space-space-unit);
  width: 332px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-text141 {
  fill: var(--dl-color-gray-newblack);
  color: var(--dl-color-gray-newblack);
  font-size: 20px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
}
.uzbekistan-germanyuz-text142 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  text-transform: none;
  text-decoration: none;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-container23 {
  gap: var(--dl-space-space-unit);
  width: 424px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-container24 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-primary-active_red);
  border-width: 0px;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-text143 {
  color: var(--dl-color-gray-newblack);
  font-size: 20px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
}
.uzbekistan-germanyuz-container25 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-text144 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-black);
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 700;
  line-height: 1.5;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  text-transform: none;
  text-decoration: none;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-navlink17 {
  gap: 4px;
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: #498074;
}
.uzbekistan-germanyuz-navlink17:hover {
  background-color: #233D37;
}
.uzbekistan-germanyuz-text148 {
  font-size: 14px;
}
.uzbekistan-germanyuz-navlink18 {
  display: contents;
}
.uzbekistan-germanyuz-container26 {
  gap: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzbekistan-germanyuz-container27 {
  width: 60px;
  height: 60px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 100px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.uzbekistan-germanyuz-container27:hover {
  width: 70px;
  height: 70px;
  margin-right: 22px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-image19 {
  width: auto;
  height: auto;
  object-fit: cover;
}
.uzbekistan-germanyuz-container28 {
  gap: var(--dl-space-space-unit);
  width: 332px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-text149 {
  color: var(--dl-color-gray-newblack);
  font-size: 20px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
}
.uzbekistan-germanyuz-text150 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
  border-color: var(--dl-color-gray-900);
  text-transform: none;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-navlink19 {
  display: contents;
}
.uzbekistan-germanyuz-container29 {
  gap: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzbekistan-germanyuz-container30 {
  width: 60px;
  height: 60px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 100px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.uzbekistan-germanyuz-container30:hover {
  width: 70px;
  height: 70px;
  margin-right: 22px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-image20 {
  width: auto;
  height: auto;
  object-fit: cover;
}
.uzbekistan-germanyuz-container31 {
  gap: var(--dl-space-space-unit);
  width: 332px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-text151 {
  color: var(--dl-color-gray-newblack);
  font-size: 20px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
}
.uzbekistan-germanyuz-text152 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
  border-color: var(--dl-color-gray-900);
  text-transform: none;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-container32 {
  gap: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.uzbekistan-germanyuz-container33 {
  width: 60px;
  height: 60px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 100px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.uzbekistan-germanyuz-container33:hover {
  width: 70px;
  height: 70px;
  margin-right: 22px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-image21 {
  width: auto;
  height: auto;
  object-fit: cover;
}
.uzbekistan-germanyuz-container34 {
  gap: var(--dl-space-space-unit);
  width: 332px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-link1 {
  color: var(--dl-color-gray-newblack);
  font-size: 20px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
}
.uzbekistan-germanyuz-text153 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-container35 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-container36 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-image22 {
  width: 32px;
  object-fit: cover;
}
.uzbekistan-germanyuz-textinput1 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 130px;
  box-shadow: 0px 1px 1px 0px #a7a7a7;
  padding-top: var(--dl-space-space-halfunit);
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: 0px;
  padding-right: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: #efefef;
}
.uzbekistan-germanyuz-container37 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-image23 {
  width: 32px;
  object-fit: cover;
}
.uzbekistan-germanyuz-textinput2 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 130px;
  box-shadow: 0px 1px 1px 0px #a7a7a7;
  padding-top: var(--dl-space-space-halfunit);
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: 0px;
  padding-right: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: #efefef;
}
.uzbekistan-germanyuz-image24 {
  width: 32px;
  object-fit: cover;
}
.uzbekistan-germanyuz-textinput3 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 100%;
  padding-top: var(--dl-space-space-halfunit);
  border-color: #A7A7A7;
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: transparent;
}
.uzbekistan-germanyuz-container38 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  align-items: flex-start;
  margin-left: 0px;
  padding-top: var(--dl-space-space-twounits);
  margin-right: 0px;
  padding-left: 0px;
  margin-bottom: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: #fbfbfb;
}
.uzbekistan-germanyuz-navlink20 {
  display: contents;
}
.uzbekistan-germanyuz-container39 {
  gap: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzbekistan-germanyuz-container40 {
  width: 60px;
  height: 60px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 100px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.uzbekistan-germanyuz-container40:hover {
  width: 70px;
  height: 70px;
  margin-right: 22px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-image26 {
  width: auto;
  height: auto;
  object-fit: cover;
}
.uzbekistan-germanyuz-container41 {
  gap: var(--dl-space-space-unit);
  width: 332px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-text154 {
  color: var(--dl-color-gray-newblack);
  font-size: 20px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
}
.uzbekistan-germanyuz-text155 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
  border-color: var(--dl-color-gray-900);
  text-transform: none;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-navlink21 {
  display: contents;
}
.uzbekistan-germanyuz-container42 {
  gap: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzbekistan-germanyuz-container43 {
  width: 60px;
  height: 60px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 100px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.uzbekistan-germanyuz-container43:hover {
  width: 70px;
  height: 70px;
  margin-right: 22px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-image27 {
  width: auto;
  height: auto;
  object-fit: cover;
}
.uzbekistan-germanyuz-container44 {
  gap: var(--dl-space-space-unit);
  width: 332px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-text160 {
  color: var(--dl-color-gray-newblack);
  font-size: 20px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
}
.uzbekistan-germanyuz-text161 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
  border-color: var(--dl-color-gray-900);
  text-transform: none;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-navlink22 {
  display: contents;
}
.uzbekistan-germanyuz-container45 {
  gap: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzbekistan-germanyuz-container46 {
  width: 60px;
  height: 60px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: 100px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.uzbekistan-germanyuz-container46:hover {
  width: 70px;
  height: 70px;
  margin-right: 22px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-image28 {
  width: auto;
  height: auto;
  object-fit: cover;
}
.uzbekistan-germanyuz-container47 {
  gap: var(--dl-space-space-unit);
  width: 332px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-text166 {
  color: var(--dl-color-gray-newblack);
  font-size: 20px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
}
.uzbekistan-germanyuz-text167 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  text-transform: none;
  text-decoration: none;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-container48 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #222222;
}
.uzbekistan-germanyuz-container49 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-image29 {
  width: 72px;
  height: 20px;
  object-fit: cover;
}
.uzbekistan-germanyuz-text168 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 440px;
  font-size: 14px;
}
.uzbekistan-germanyuz-container50 {
  gap: var(--dl-space-space-unit);
  width: 196px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.uzbekistan-germanyuz-container51 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.uzbekistan-germanyuz-text169 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-style: normal;
  font-weight: 500;
}
.uzbekistan-germanyuz-image30 {
  width: auto;
  object-fit: cover;
}
.uzbekistan-germanyuz-container52 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.uzbekistan-germanyuz-navlink23 {
  display: contents;
}
.uzbekistan-germanyuz-container53 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzbekistan-germanyuz-icon14 {
  color: var(--dl-color-gray-700);
  width: 22px;
  height: 22px;
}
.uzbekistan-germanyuz-text170 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.uzbekistan-germanyuz-text170:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzbekistan-germanyuz-navlink24 {
  display: contents;
}
.uzbekistan-germanyuz-container54 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzbekistan-germanyuz-icon16 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzbekistan-germanyuz-text171 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.uzbekistan-germanyuz-text171:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzbekistan-germanyuz-navlink25 {
  display: contents;
}
.uzbekistan-germanyuz-container55 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzbekistan-germanyuz-icon18 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzbekistan-germanyuz-text175 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.uzbekistan-germanyuz-text175:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzbekistan-germanyuz-navlink26 {
  display: contents;
}
.uzbekistan-germanyuz-container56 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzbekistan-germanyuz-icon20 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzbekistan-germanyuz-text179 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.uzbekistan-germanyuz-text179:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzbekistan-germanyuz-navlink27 {
  display: contents;
}
.uzbekistan-germanyuz-container57 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzbekistan-germanyuz-icon22 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzbekistan-germanyuz-text183 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.uzbekistan-germanyuz-text183:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzbekistan-germanyuz-container58 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #181818;
}
.uzbekistan-germanyuz-container59 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.uzbekistan-germanyuz-container60 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.uzbekistan-germanyuz-text187 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
}
.uzbekistan-germanyuz-container61 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.uzbekistan-germanyuz-navlink28 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  transition: 0.3s;
  text-decoration: none;
}
.uzbekistan-germanyuz-navlink28:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzbekistan-germanyuz-navlink29 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 14px;
  transition: 0.3s;
  text-decoration: none;
}
.uzbekistan-germanyuz-navlink29:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzbekistan-germanyuz-image31 {
  width: auto;
  object-fit: cover;
}
.uzbekistan-germanyuz-link2 {
  display: contents;
}
.uzbekistan-germanyuz-image32 {
  width: auto;
  margin: 0px;
  text-decoration: none;
}
.uzbekistan-germanyuz-navbar-interactive {
  width: 100%;
  display: flex;
  position: fixed;
  align-self: center;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: px;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: px;
  justify-content: center;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-desktop-menu {
  flex: 1;
  width: auto;
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
}
.uzbekistan-germanyuz-container62 {
  display: flex;
  justify-content: space-between;
}
.uzbekistan-germanyuz-links1 {
  gap: 1px;
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-danger-700);
}
.uzbekistan-germanyuz-thq-dropdown10 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 21px;
  text-decoration: none;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-dropdown-toggle10:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: mixed;
  padding-right: mixed;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-dropdown-toggle10:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-text190 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzbekistan-germanyuz-thq-dropdown11 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-dropdown-toggle11:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-dropdown-toggle11:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-text191 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzbekistan-germanyuz-dropdown-arrow1 {
  transition: 0.3s;
}
.uzbekistan-germanyuz-dropdown-list1 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzbekistan-germanyuz-dropdown10 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle12 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle12:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text192 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown11 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle13 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle13:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text195 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown12 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle14 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle14:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text196 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown13 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle15 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle15:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text197 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown14 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle16 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle16:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text202 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown15 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle17 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle17:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text203 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown16 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle18 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle18:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text204 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown17 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle19 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.uzbekistan-germanyuz-dropdown-toggle19:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text208 {
  font-style: normal;
}
.uzbekistan-germanyuz-thq-dropdown12 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle20 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-dropdown-toggle20:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-dropdown-toggle20:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-text209 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzbekistan-germanyuz-dropdown-arrow2 {
  transition: 0.3s;
}
.uzbekistan-germanyuz-dropdown-list2 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzbekistan-germanyuz-dropdown18 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle21 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle21:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text210 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown19 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle22 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle22:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text211 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown20 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle23 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle23:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text212 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown21 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle24 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle24:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text213 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown22 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle25 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle25:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text216 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown23 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle26 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle26:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text220 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown24 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle27 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  padding-bottom: 20px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle27:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text221 {
  font-style: normal;
}
.uzbekistan-germanyuz-thq-dropdown13 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle28 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-dropdown-toggle28:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-dropdown-toggle28:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-text222 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzbekistan-germanyuz-dropdown-arrow3 {
  transition: 0.3s;
}
.uzbekistan-germanyuz-dropdown-list3 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzbekistan-germanyuz-dropdown25 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle29 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle29:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text223 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown26 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle30 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle30:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text224 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown27 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle31 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle31:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text228 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown28 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle32 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle32:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text231 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown29 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle33 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle33:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text232 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown30 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle34 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle34:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text236 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown31 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle35 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.uzbekistan-germanyuz-dropdown-toggle35:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text237 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown32 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle36 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle36:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-dropdown33 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle37 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle37:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-thq-dropdown14 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle38 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-dropdown-toggle38:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-dropdown-toggle38:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-text240 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzbekistan-germanyuz-thq-dropdown15 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle39 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-dropdown-toggle39:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-dropdown-toggle39:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-text241 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzbekistan-germanyuz-dropdown-arrow4 {
  transition: 0.3s;
}
.uzbekistan-germanyuz-dropdown-list4 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzbekistan-germanyuz-dropdown34 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle40 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle40:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text242 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown35 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle41 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle41:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text243 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown36 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle42 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle42:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text244 {
  font-style: normal;
}
.uzbekistan-germanyuz-dropdown37 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle43 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle43:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text245 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uzbekistan-germanyuz-container63 {
  gap: 4px;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-end;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-textinput4 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  border-width: 0px;
  border-radius: 0px;
}
.uzbekistan-germanyuz-container64 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.uzbekistan-germanyuz-icon24 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 24px;
}
.uzbekistan-germanyuz-container65 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.uzbekistan-germanyuz-text248 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uzbekistan-germanyuz-text249 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uzbekistan-germanyuz-burger-menu2 {
  display: none;
}
.uzbekistan-germanyuz-icon26 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzbekistan-germanyuz-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.uzbekistan-germanyuz-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.uzbekistan-germanyuz-container66 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.uzbekistan-germanyuz-container67 {
  gap: 4px;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-end;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-textinput5 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  border-width: 0px;
  border-radius: 0px;
}
.uzbekistan-germanyuz-container68 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.uzbekistan-germanyuz-icon28 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 24px;
}
.uzbekistan-germanyuz-container69 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.uzbekistan-germanyuz-text250 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uzbekistan-germanyuz-text251 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uzbekistan-germanyuz-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.uzbekistan-germanyuz-icon30 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzbekistan-germanyuz-links2 {
  gap: 1px;
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-danger-700);
}
.uzbekistan-germanyuz-thq-dropdown16 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle44 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-dropdown-toggle44:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-dropdown-toggle44:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-text252 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzbekistan-germanyuz-thq-dropdown17 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle45 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-dropdown-toggle45:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-dropdown-toggle45:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-text253 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzbekistan-germanyuz-dropdown-arrow5 {
  transition: 0.3s;
}
.uzbekistan-germanyuz-dropdown-list5 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzbekistan-germanyuz-dropdown38 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle46 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle46:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text254 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown39 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle47 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle47:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text257 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown40 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle48 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle48:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text258 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown41 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle49 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle49:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text259 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown42 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle50 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle50:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text264 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown43 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle51 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle51:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text265 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown44 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle52 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle52:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text266 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown45 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle53 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.uzbekistan-germanyuz-dropdown-toggle53:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text269 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-thq-dropdown18 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle54 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-dropdown-toggle54:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-dropdown-toggle54:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-text270 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzbekistan-germanyuz-dropdown-arrow6 {
  transition: 0.3s;
}
.uzbekistan-germanyuz-dropdown-list6 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzbekistan-germanyuz-dropdown46 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle55 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle55:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text271 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown47 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle56 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle56:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text272 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown48 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle57 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle57:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text273 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown49 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle58 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle58:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text274 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown50 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle59 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle59:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text277 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown51 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle60 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle60:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text281 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown52 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle61 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle61:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text282 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-thq-dropdown19 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle62 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-dropdown-toggle62:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-dropdown-toggle62:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-text283 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzbekistan-germanyuz-dropdown-arrow7 {
  transition: 0.3s;
}
.uzbekistan-germanyuz-dropdown-list7 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzbekistan-germanyuz-dropdown53 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle63 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle63:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text284 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown54 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle64 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle64:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text285 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown55 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle65 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle65:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text286 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown56 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle66 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.uzbekistan-germanyuz-dropdown-toggle66:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text287 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown57 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle67 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle67:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text288 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown58 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle68 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle68:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text291 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown59 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle69 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle69:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text294 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown60 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle70 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle70:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text295 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown61 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle71 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle71:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text296 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-thq-dropdown20 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle72 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-dropdown-toggle72:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-dropdown-toggle72:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-text297 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzbekistan-germanyuz-thq-dropdown21 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle73 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzbekistan-germanyuz-dropdown-toggle73:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-dropdown-toggle73:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzbekistan-germanyuz-text298 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzbekistan-germanyuz-dropdown-arrow8 {
  transition: 0.3s;
}
.uzbekistan-germanyuz-dropdown-list8 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzbekistan-germanyuz-dropdown62 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle74 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle74:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text299 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown63 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle75 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle75:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text300 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown64 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle76 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle76:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text301 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzbekistan-germanyuz-dropdown65 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzbekistan-germanyuz-dropdown-toggle77 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzbekistan-germanyuz-dropdown-toggle77:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzbekistan-germanyuz-text302 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
@media(max-width: 1200px) {
  .uzbekistan-germanyuz-select {
    background-color: var(--dl-color-gray-white);
  }
  .uzbekistan-germanyuz-image13 {
    height: 150px;
  }
  .uzbekistan-germanyuz-image14 {
    height: 150px;
  }
  .uzbekistan-germanyuz-image15 {
    height: 150px;
  }
  .uzbekistan-germanyuz-dropdown-toggle10 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uzbekistan-germanyuz-dropdown-toggle11 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uzbekistan-germanyuz-dropdown-toggle20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uzbekistan-germanyuz-dropdown-toggle28 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uzbekistan-germanyuz-dropdown-toggle38 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uzbekistan-germanyuz-dropdown-toggle39 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media(max-width: 991px) {
  .uzbekistan-germanyuz-container11 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .uzbekistan-germanyuz-container15 {
    height: 200px;
  }
  .uzbekistan-germanyuz-image13 {
    height: 120px;
  }
  .uzbekistan-germanyuz-image14 {
    height: 120px;
  }
  .uzbekistan-germanyuz-image15 {
    height: 120px;
  }
  .uzbekistan-germanyuz-container38 {
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
  }
  .uzbekistan-germanyuz-container48 {
    align-self: flex-start;
  }
  .uzbekistan-germanyuz-container58 {
    align-self: flex-start;
  }
  .uzbekistan-germanyuz-navbar-interactive {
    padding-left: 50px;
    padding-right: 50px;
  }
  .uzbekistan-germanyuz-dropdown-toggle10 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uzbekistan-germanyuz-text190 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uzbekistan-germanyuz-dropdown-toggle11 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uzbekistan-germanyuz-text191 {
    font-size: 14px;
  }
  .uzbekistan-germanyuz-dropdown-toggle20 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uzbekistan-germanyuz-text209 {
    font-size: 14px;
  }
  .uzbekistan-germanyuz-dropdown-toggle28 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uzbekistan-germanyuz-text222 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uzbekistan-germanyuz-dropdown-toggle38 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uzbekistan-germanyuz-text240 {
    font-size: 14px;
  }
  .uzbekistan-germanyuz-dropdown-toggle39 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uzbekistan-germanyuz-text241 {
    font-size: 14px;
  }
  .uzbekistan-germanyuz-textinput4 {
    width: var(--dl-size-size-medium);
  }
}
@media(max-width: 767px) {
  .uzbekistan-germanyuz-container11 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .uzbekistan-germanyuz-text100 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .uzbekistan-germanyuz-burger-menu1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uzbekistan-germanyuz-icon10 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .uzbekistan-germanyuz-container15 {
    height: 100px;
    margin-top: 98px;
    background-size: cover;
    background-image: url("/img_slider03_org-300h.jpg");
  }
  .uzbekistan-germanyuz-image13 {
    display: none;
  }
  .uzbekistan-germanyuz-image14 {
    display: none;
  }
  .uzbekistan-germanyuz-image15 {
    display: none;
  }
  .uzbekistan-germanyuz-image16 {
    display: none;
  }
  .uzbekistan-germanyuz-container48 {
    gap: var(--dl-space-space-unit);
    align-self: center;
    flex-direction: column;
    justify-content: center;
  }
  .uzbekistan-germanyuz-container58 {
    align-self: center;
  }
  .uzbekistan-germanyuz-navbar-interactive {
    align-self: center;
    padding-top: 0px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: 0px;
    justify-content: space-between;
  }
  .uzbekistan-germanyuz-text190 {
    font-size: 12px;
  }
  .uzbekistan-germanyuz-text191 {
    font-size: 12px;
  }
  .uzbekistan-germanyuz-text209 {
    font-size: 12px;
  }
  .uzbekistan-germanyuz-text222 {
    font-size: 12px;
  }
  .uzbekistan-germanyuz-text240 {
    font-size: 12px;
  }
  .uzbekistan-germanyuz-text241 {
    font-size: 12px;
  }
  .uzbekistan-germanyuz-container63 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .uzbekistan-germanyuz-container65 {
    display: none;
  }
  .uzbekistan-germanyuz-burger-menu2 {
    align-items: center;
    justify-content: center;
  }
  .uzbekistan-germanyuz-icon26 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .uzbekistan-germanyuz-container67 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .uzbekistan-germanyuz-container69 {
    display: none;
  }
  .uzbekistan-germanyuz-icon30 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
}
@media(max-width: 479px) {
  .uzbekistan-germanyuz-container11 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: center;
  }
  .uzbekistan-germanyuz-image10 {
    width: 103px;
    height: 60px;
    margin-right: 0px;
  }
  .uzbekistan-germanyuz-text100 {
    color: var(--dl-color-gray-black);
    width: 200px;
  }
  .uzbekistan-germanyuz-icon10 {
    width: 32px;
    height: 32px;
  }
  .uzbekistan-germanyuz-container15 {
    width: 100%;
    margin-top: 150px;
  }
  .uzbekistan-germanyuz-container16 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .uzbekistan-germanyuz-container17 {
    width: auto;
  }
  .uzbekistan-germanyuz-container18 {
    width: 60px;
    height: 60px;
    margin-right: var(--dl-space-space-unit);
  }
  .uzbekistan-germanyuz-container19 {
    width: 270px;
  }
  .uzbekistan-germanyuz-container20 {
    width: auto;
  }
  .uzbekistan-germanyuz-container21 {
    margin-right: var(--dl-space-space-unit);
  }
  .uzbekistan-germanyuz-container22 {
    width: 270px;
  }
  .uzbekistan-germanyuz-container23 {
    width: 350px;
  }
  .uzbekistan-germanyuz-container26 {
    width: 100%;
  }
  .uzbekistan-germanyuz-container27 {
    margin-right: var(--dl-space-space-unit);
  }
  .uzbekistan-germanyuz-container28 {
    width: 270px;
  }
  .uzbekistan-germanyuz-container29 {
    width: auto;
  }
  .uzbekistan-germanyuz-container30 {
    margin-right: var(--dl-space-space-unit);
  }
  .uzbekistan-germanyuz-container31 {
    width: 270px;
  }
  .uzbekistan-germanyuz-container32 {
    width: auto;
    align-items: flex-start;
    justify-content: center;
  }
  .uzbekistan-germanyuz-container33 {
    margin-right: var(--dl-space-space-unit);
  }
  .uzbekistan-germanyuz-container34 {
    width: 269px;
  }
  .uzbekistan-germanyuz-textinput1 {
    width: 100px;
  }
  .uzbekistan-germanyuz-textinput2 {
    width: 100px;
  }
  .uzbekistan-germanyuz-image25 {
    width: 100%;
    height: auto;
  }
  .uzbekistan-germanyuz-container38 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .uzbekistan-germanyuz-container39 {
    width: auto;
  }
  .uzbekistan-germanyuz-container40 {
    margin-right: var(--dl-space-space-unit);
  }
  .uzbekistan-germanyuz-container41 {
    width: 270px;
  }
  .uzbekistan-germanyuz-container42 {
    width: auto;
  }
  .uzbekistan-germanyuz-container43 {
    margin-right: var(--dl-space-space-unit);
  }
  .uzbekistan-germanyuz-container44 {
    width: 270px;
  }
  .uzbekistan-germanyuz-container45 {
    width: auto;
  }
  .uzbekistan-germanyuz-container46 {
    margin-right: var(--dl-space-space-unit);
  }
  .uzbekistan-germanyuz-container47 {
    width: 270px;
  }
  .uzbekistan-germanyuz-container48 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .uzbekistan-germanyuz-text168 {
    width: 320px;
  }
  .uzbekistan-germanyuz-container58 {
    gap: var(--dl-space-space-halfunit);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .uzbekistan-germanyuz-container60 {
    align-self: center;
    flex-direction: column;
  }
  .uzbekistan-germanyuz-container61 {
    align-self: center;
  }
  .uzbekistan-germanyuz-image31 {
    align-self: center;
  }
  .uzbekistan-germanyuz-image32 {
    display: none;
  }
  .uzbekistan-germanyuz-navbar-interactive {
    top: 133px;
    flex: 1;
    left: 0px;
    align-self: flex-end;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .uzbekistan-germanyuz-desktop-menu {
    display: none;
  }
  .uzbekistan-germanyuz-container63 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .uzbekistan-germanyuz-textinput4 {
    width: var(--dl-size-size-large);
  }
  .uzbekistan-germanyuz-burger-menu2 {
    display: flex;
  }
  .uzbekistan-germanyuz-icon26 {
    width: 32px;
    height: 32px;
  }
  .uzbekistan-germanyuz-mobile-menu {
    height: auto;
    padding: 0px;
  }
  .uzbekistan-germanyuz-container66 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
    background-color: var(--dl-color-primary-active_red);
  }
  .uzbekistan-germanyuz-container67 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .uzbekistan-germanyuz-icon30 {
    width: 32px;
    height: 32px;
  }
  .uzbekistan-germanyuz-links2 {
    gap: 0.5PX;
    width: 100%;
    height: auto;
    flex-direction: column;
  }
  .uzbekistan-germanyuz-thq-dropdown16 {
    width: 100%;
  }
  .uzbekistan-germanyuz-dropdown-toggle44 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uzbekistan-germanyuz-text252 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uzbekistan-germanyuz-thq-dropdown17 {
    width: 100%;
  }
  .uzbekistan-germanyuz-dropdown-toggle45 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uzbekistan-germanyuz-text253 {
    font-size: 14px;
  }
  .uzbekistan-germanyuz-icon32 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .uzbekistan-germanyuz-dropdown-list5 {
    background-color: var(--dl-color-primary-main);
  }
  .uzbekistan-germanyuz-dropdown-toggle46 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text254 {
    font-size: 14px;
  }
  .uzbekistan-germanyuz-dropdown-toggle47 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text257 {
    font-size: 14px;
  }
  .uzbekistan-germanyuz-dropdown-toggle48 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text258 {
    font-size: 14px;
  }
  .uzbekistan-germanyuz-dropdown-toggle49 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text259 {
    font-size: 14px;
  }
  .uzbekistan-germanyuz-dropdown-toggle50 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text264 {
    font-size: 14px;
  }
  .uzbekistan-germanyuz-dropdown-toggle51 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text265 {
    font-size: 14px;
  }
  .uzbekistan-germanyuz-dropdown-toggle52 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text266 {
    font-size: 14px;
  }
  .uzbekistan-germanyuz-dropdown-toggle53 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text269 {
    font-size: 14px;
  }
  .uzbekistan-germanyuz-thq-dropdown18 {
    width: 100%;
  }
  .uzbekistan-germanyuz-dropdown-toggle54 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uzbekistan-germanyuz-text270 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uzbekistan-germanyuz-icon34 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .uzbekistan-germanyuz-dropdown-list6 {
    background-color: var(--dl-color-primary-main);
  }
  .uzbekistan-germanyuz-dropdown-toggle55 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text271 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-dropdown-toggle56 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text272 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-dropdown-toggle57 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text273 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-dropdown-toggle58 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text274 {
    font-size: 14px;
    font-family: Roboto;
  }
  .uzbekistan-germanyuz-dropdown-toggle59 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text277 {
    font-size: 14px;
    font-family: Roboto;
  }
  .uzbekistan-germanyuz-dropdown-toggle60 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text281 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-dropdown-toggle61 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text282 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-thq-dropdown19 {
    width: 100%;
  }
  .uzbekistan-germanyuz-dropdown-toggle62 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uzbekistan-germanyuz-text283 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uzbekistan-germanyuz-icon36 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .uzbekistan-germanyuz-dropdown-list7 {
    background-color: var(--dl-color-primary-main);
  }
  .uzbekistan-germanyuz-dropdown-toggle63 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text284 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-dropdown-toggle64 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text285 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-dropdown-toggle65 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text286 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-dropdown-toggle66 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text287 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-dropdown-toggle67 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text288 {
    font-size: 14px;
    font-family: Roboto;
  }
  .uzbekistan-germanyuz-dropdown-toggle68 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text291 {
    font-size: 14px;
  }
  .uzbekistan-germanyuz-dropdown-toggle69 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text294 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-dropdown-toggle70 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text295 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-dropdown-toggle71 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text296 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-thq-dropdown20 {
    width: 100%;
  }
  .uzbekistan-germanyuz-dropdown-toggle72 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uzbekistan-germanyuz-text297 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uzbekistan-germanyuz-thq-dropdown21 {
    width: 100%;
  }
  .uzbekistan-germanyuz-dropdown-toggle73 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uzbekistan-germanyuz-text298 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uzbekistan-germanyuz-icon38 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .uzbekistan-germanyuz-dropdown-list8 {
    background-color: var(--dl-color-primary-main);
  }
  .uzbekistan-germanyuz-dropdown-toggle74 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text299 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-dropdown-toggle75 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text300 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-dropdown-toggle76 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text301 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzbekistan-germanyuz-dropdown-toggle77 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzbekistan-germanyuz-text302 {
    font-size: 14px;
    font-family: Roboto;
  }
}

.result-message {
  font-size: 12px;
  color: rgb(128, 0, 0);
  margin-top: 10px;
}
.visa-status-message {
  color: rgb(163, 6, 6);
  font-size: 14px;
  background-color: rgb(245, 162, 151);
  background-size: auto;
  margin-top: -8px;
  margin-bottom: 24px;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
}
.visa-modal-content-t {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0px;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.visa-modal-content-t {
  background-color: #fff;
  margin: auto;
  padding: 8px;
  border: 1px solid #888;
  width: 416px;
  text-align: center;
  align-content: center;
}

.visa-modal-content-t img {
  height: auto;
  width: 400px;

}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
