.uzhzhatlar-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.uzhzhatlar-burger-menu1 {
  display: none;
}
.uzhzhatlar-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzhzhatlar-container11 {
  gap: 60px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: 146px;
  align-items: flex-start;
  padding-top: 60px;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/frame%2043-200h.png");
  background-repeat: space;
  background-position: center;
}
.uzhzhatlar-text100 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 22px;
  align-self: flex-start;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uzhzhatlar-container12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  background-color: #F0F0F0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.uzhzhatlar-navlink10 {
  display: contents;
}
.uzhzhatlar-container13 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.uzhzhatlar-icon12 {
  fill: var(--dl-color-primary-500);
  color: var(--dl-color-primary-500);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzhzhatlar-text101 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.uzhzhatlar-navlink11 {
  display: contents;
}
.uzhzhatlar-container14 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.uzhzhatlar-icon14 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 16px;
  height: 16px;
}
.uzhzhatlar-text102 {
  color: var(--dl-color-gray-500);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
}
.uzhzhatlar-navlink12 {
  display: contents;
}
.uzhzhatlar-container15 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.uzhzhatlar-icon16 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 16px;
  height: 16px;
}
.uzhzhatlar-text103 {
  color: var(--dl-color-gray-500);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
}
.uzhzhatlar-container16 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  margin: 100px;
  display: flex;
  align-self: flex-start;
  margin-top: 0px;
  align-items: flex-start;
  margin-left: 0px;
  padding-top: var(--dl-space-space-twounits);
  margin-right: 0px;
  padding-left: 100px;
  margin-bottom: 0px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
}
.uzhzhatlar-container17 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.uzhzhatlar-container18 {
  gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.uzhzhatlar-text104 {
  color: var(--dl-color-gray-500);
}
.uzhzhatlar-icon18 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 12px;
  height: 12px;
}
.uzhzhatlar-image10 {
  width: auto;
  object-fit: cover;
}
.uzhzhatlar-container19 {
  gap: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.uzhzhatlar-navlink13 {
  display: contents;
}
.uzhzhatlar-container20 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.uzhzhatlar-image11 {
  width: var(--dl-size-size-xsmall);
  object-fit: cover;
}
.uzhzhatlar-text105 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
}
.uzhzhatlar-navlink14 {
  display: contents;
}
.uzhzhatlar-container21 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.uzhzhatlar-container21:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.uzhzhatlar-image12 {
  width: var(--dl-size-size-xsmall);
  align-self: center;
  object-fit: cover;
  transition: 0.3s;
}

.uzhzhatlar-text106 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-style: normal;
  transition: 0.3s;
  font-family: "Roboto";
  font-weight: 400;
}
.uzhzhatlar-text106:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzhzhatlar-navlink15 {
  display: contents;
}
.uzhzhatlar-container22 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.uzhzhatlar-container22:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.uzhzhatlar-image13 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.uzhzhatlar-text107 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
}
.uzhzhatlar-text107:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzhzhatlar-navlink16 {
  display: contents;
}
.uzhzhatlar-container23 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-gray-main);
}
.uzhzhatlar-container23:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.uzhzhatlar-image14 {
  width: var(--dl-size-size-xsmall);
  align-self: center;
  object-fit: cover;
  transition: 0.3s;
}

.uzhzhatlar-text108 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzhzhatlar-text108:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzhzhatlar-navlink17 {
  display: contents;
}
.uzhzhatlar-container24 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.uzhzhatlar-container24:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.uzhzhatlar-image15 {
  width: var(--dl-size-size-xsmall);
  align-self: center;
  object-fit: cover;
  transition: 0.3s;
}

.uzhzhatlar-text109 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
}
.uzhzhatlar-text109:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzhzhatlar-navlink18 {
  display: contents;
}
.uzhzhatlar-container25 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.uzhzhatlar-container25:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.uzhzhatlar-image16 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.uzhzhatlar-text110 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
}
.uzhzhatlar-text110:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzhzhatlar-container26 {
  gap: var(--dl-space-space-twounits);
  flex: 0;
  width: auto;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  border-left-width: 1px;
}
.uzhzhatlar-text111 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-primary-active_red);
  width: 900px;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  text-transform: none;
  text-decoration: none;
  border-bottom-width: 1px;
}
.uzhzhatlar-text113 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text114 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 900px;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-text115 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text116 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text117 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text119 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text120 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text122 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text123 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text125 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text126 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text127 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text128 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text130 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text131 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text132 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text133 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text134 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text135 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text137 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text139 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text140 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text141 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text142 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text143 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text145 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text146 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text148 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text149 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text150 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text151 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text152 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text153 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text155 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text156 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text157 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text158 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text159 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text160 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text162 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text163 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text165 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text166 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text167 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text168 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text169 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text170 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text172 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text173 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text175 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text176 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text177 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text178 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text180 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text181 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text182 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text183 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text184 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text185 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text186 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text187 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text188 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text190 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text191 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text192 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text193 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text194 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text195 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text197 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text198 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text200 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text201 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text203 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text204 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text206 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text207 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text209 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text210 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text212 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text213 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text214 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text215 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text217 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text218 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text220 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text221 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text223 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text224 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text226 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text227 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text229 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text230 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text232 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text233 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text234 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text235 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text237 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text238 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text240 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text241 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text243 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text244 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text245 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text246 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text248 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text249 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text251 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text252 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text253 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text254 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text255 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text256 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text257 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text259 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text260 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text262 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text263 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text265 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text266 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text267 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text268 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text270 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text271 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text273 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text274 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text275 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text276 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text277 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text278 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text280 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text281 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text283 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text284 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text286 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text287 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text288 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text289 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text291 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text292 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text294 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text295 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text296 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text297 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text298 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text299 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text301 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text302 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text304 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text305 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text307 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text308 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text310 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text311 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text313 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text314 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text316 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text317 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text319 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text320 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text322 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text323 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text325 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text326 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text328 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text329 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text331 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text332 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text334 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text335 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text337 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text338 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text340 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text341 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text343 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text344 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text346 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text347 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text349 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text350 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text352 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text353 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text355 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text356 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text358 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text359 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text361 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text363 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text364 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text365 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text366 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text367 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text369 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text370 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text372 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text373 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text374 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text375 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text377 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text378 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text380 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text381 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text382 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text383 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text385 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text386 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text388 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text389 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text391 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text392 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text393 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text394 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text395 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text396 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text398 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text399 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text401 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text402 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text403 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text404 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text405 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text406 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text408 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text409 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text411 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text412 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text414 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text415 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text417 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text418 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text420 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text421 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text423 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text424 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text425 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text426 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text428 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text429 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text430 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text431 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text432 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text433 {
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-container27 {
  flex: 0;
  width: 100%;
  display: flex;
  position: fixed;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.uzhzhatlar-navlink19 {
  display: contents;
}
.uzhzhatlar-container28 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}
.uzhzhatlar-image17 {
  width: 106px;
  height: 64px;
  text-decoration: none;
}
.uzhzhatlar-text435 {
  fill: var(--dl-color-primary-main);
  color: var(--dl-color-primary-main);
  width: 275px;
  height: 52px;
  font-size: 10px;
  max-width: auto;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  margin-left: var(--dl-space-space-fourunits);
}
.uzhzhatlar-container29 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.uzhzhatlar-container30 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.uzhzhatlar-text438 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-family: "Roboto";
}
.uzhzhatlar-text440 {
  font-style: normal;
  font-weight: 700;
}
.uzhzhatlar-text441 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-family: "Roboto";
}
.uzhzhatlar-text442 {
  font-style: normal;
  font-weight: 700;
}
.uzhzhatlar-image18 {
  width: 16px;
  height: 11px;
  object-fit: cover;
}
.uzhzhatlar-text473 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 700;
}
.uzhzhatlar-image19 {
  width: 16px;
  height: 11px;
  object-fit: cover;
}
.uzhzhatlar-select {
  height: auto;
  font-size: x-small;
  background-color: var(--dl-color-gray-white);
}
.uzhzhatlar-navbar-interactive {
  width: 100%;
  display: flex;
  position: fixed;
  align-self: center;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: px;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: px;
  justify-content: center;
  background-color: var(--dl-color-primary-active_red);
}
.uzhzhatlar-desktop-menu {
  flex: 1;
  width: auto;
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
}
.uzhzhatlar-container31 {
  display: flex;
  justify-content: space-between;
}
.uzhzhatlar-links1 {
  gap: 1px;
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-danger-700);
}
.uzhzhatlar-thq-dropdown10 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.uzhzhatlar-dropdown-toggle10:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: mixed;
  padding-right: mixed;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-dropdown-toggle10:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-text474 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzhzhatlar-thq-dropdown11 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzhzhatlar-dropdown-toggle11:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-dropdown-toggle11:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-text475 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzhzhatlar-dropdown-arrow1 {
  transition: 0.3s;
}
.uzhzhatlar-dropdown-list1 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzhzhatlar-dropdown10 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle12 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle12:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text476 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uzhzhatlar-dropdown11 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle13 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle13:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text479 {
  font-style: normal;
}
.uzhzhatlar-dropdown12 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle14 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle14:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text480 {
  font-style: normal;
}
.uzhzhatlar-dropdown13 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle15 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle15:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text481 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uzhzhatlar-dropdown14 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle16 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle16:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text486 {
  font-style: normal;
}
.uzhzhatlar-dropdown15 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle17 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle17:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text487 {
  font-style: normal;
}
.uzhzhatlar-dropdown16 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle18 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle18:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text488 {
  font-style: normal;
}
.uzhzhatlar-dropdown17 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle19 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.uzhzhatlar-dropdown-toggle19:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text492 {
  font-style: normal;
}
.uzhzhatlar-thq-dropdown12 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle20 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzhzhatlar-dropdown-toggle20:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-dropdown-toggle20:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-text493 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzhzhatlar-dropdown-arrow2 {
  transition: 0.3s;
}
.uzhzhatlar-dropdown-list2 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzhzhatlar-dropdown18 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle21 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle21:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text494 {
  font-style: normal;
}
.uzhzhatlar-dropdown19 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle22 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle22:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text495 {
  font-style: normal;
}
.uzhzhatlar-dropdown20 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle23 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle23:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text496 {
  font-style: normal;
}
.uzhzhatlar-dropdown21 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle24 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle24:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text497 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uzhzhatlar-dropdown22 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle25 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle25:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text500 {
  font-style: normal;
}
.uzhzhatlar-dropdown23 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle26 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle26:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text504 {
  font-style: normal;
}
.uzhzhatlar-dropdown24 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle27 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  padding-bottom: 20px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle27:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text505 {
  font-style: normal;
}
.uzhzhatlar-thq-dropdown13 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle28 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzhzhatlar-dropdown-toggle28:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-dropdown-toggle28:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-text506 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzhzhatlar-dropdown-arrow3 {
  transition: 0.3s;
}
.uzhzhatlar-dropdown-list3 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzhzhatlar-dropdown25 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle29 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle29:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text507 {
  font-style: normal;
}
.uzhzhatlar-dropdown26 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle30 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle30:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text508 {
  font-style: normal;
}
.uzhzhatlar-dropdown27 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle31 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle31:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text512 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uzhzhatlar-dropdown28 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle32 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle32:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text515 {
  font-style: normal;
}
.uzhzhatlar-dropdown29 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle33 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle33:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text516 {
  font-style: normal;
}
.uzhzhatlar-dropdown30 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle34 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle34:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text520 {
  font-style: normal;
}
.uzhzhatlar-dropdown31 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle35 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.uzhzhatlar-dropdown-toggle35:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text521 {
  font-style: normal;
}
.uzhzhatlar-dropdown32 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle36 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle36:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-dropdown33 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle37 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle37:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-thq-dropdown14 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle38 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 21px;
  text-decoration: none;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-dropdown-toggle38:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-dropdown-toggle38:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-text524 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzhzhatlar-thq-dropdown15 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle39 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzhzhatlar-dropdown-toggle39:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-dropdown-toggle39:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-text525 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzhzhatlar-dropdown-arrow4 {
  transition: 0.3s;
}
.uzhzhatlar-dropdown-list4 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzhzhatlar-dropdown34 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle40 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle40:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text526 {
  font-style: normal;
}
.uzhzhatlar-dropdown35 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle41 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle41:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text527 {
  font-style: normal;
}
.uzhzhatlar-dropdown36 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle42 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle42:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text528 {
  font-style: normal;
}
.uzhzhatlar-dropdown37 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle43 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle43:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text529 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uzhzhatlar-container32 {
  gap: 4px;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-end;
  background-color: var(--dl-color-primary-active_red);
}
.uzhzhatlar-textinput1 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  border-width: 0px;
  border-radius: 0px;
}
.uzhzhatlar-container33 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.uzhzhatlar-icon20 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 24px;
}
.uzhzhatlar-container34 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.uzhzhatlar-text532 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uzhzhatlar-text533 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uzhzhatlar-burger-menu2 {
  display: none;
}
.uzhzhatlar-icon22 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzhzhatlar-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.uzhzhatlar-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.uzhzhatlar-container35 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.uzhzhatlar-container36 {
  gap: 4px;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-end;
  background-color: var(--dl-color-primary-active_red);
}
.uzhzhatlar-textinput2 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  border-width: 0px;
  border-radius: 0px;
}
.uzhzhatlar-container37 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.uzhzhatlar-icon24 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 24px;
}
.uzhzhatlar-container38 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.uzhzhatlar-text534 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uzhzhatlar-text535 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uzhzhatlar-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.uzhzhatlar-icon26 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzhzhatlar-links2 {
  gap: 1px;
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-danger-700);
}
.uzhzhatlar-thq-dropdown16 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle44 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.uzhzhatlar-dropdown-toggle44:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-dropdown-toggle44:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-text536 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzhzhatlar-thq-dropdown17 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle45 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzhzhatlar-dropdown-toggle45:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-dropdown-toggle45:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-text537 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzhzhatlar-dropdown-arrow5 {
  transition: 0.3s;
}
.uzhzhatlar-dropdown-list5 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzhzhatlar-dropdown38 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle46 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle46:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text538 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown39 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle47 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle47:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text541 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown40 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle48 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle48:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text542 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown41 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle49 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle49:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text543 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown42 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle50 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle50:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text548 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown43 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle51 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle51:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text549 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown44 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle52 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle52:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text550 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown45 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle53 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.uzhzhatlar-dropdown-toggle53:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text553 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-thq-dropdown18 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle54 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzhzhatlar-dropdown-toggle54:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-dropdown-toggle54:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-text554 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzhzhatlar-dropdown-arrow6 {
  transition: 0.3s;
}
.uzhzhatlar-dropdown-list6 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzhzhatlar-dropdown46 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle55 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle55:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text555 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown47 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle56 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle56:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text556 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown48 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle57 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle57:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text557 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown49 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle58 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle58:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text558 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown50 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle59 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle59:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text561 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown51 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle60 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle60:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text565 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown52 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle61 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle61:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text566 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-thq-dropdown19 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle62 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzhzhatlar-dropdown-toggle62:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-dropdown-toggle62:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-text567 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzhzhatlar-dropdown-arrow7 {
  transition: 0.3s;
}
.uzhzhatlar-dropdown-list7 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzhzhatlar-dropdown53 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle63 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle63:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text568 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown54 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle64 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle64:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text569 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown55 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle65 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle65:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text570 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown56 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle66 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.uzhzhatlar-dropdown-toggle66:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text571 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown57 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle67 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle67:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text572 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown58 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle68 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle68:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text575 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown59 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle69 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle69:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text578 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown60 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle70 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle70:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text579 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown61 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle71 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle71:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text580 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-thq-dropdown20 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle72 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.uzhzhatlar-dropdown-toggle72:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-dropdown-toggle72:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-text581 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzhzhatlar-thq-dropdown21 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle73 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uzhzhatlar-dropdown-toggle73:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-dropdown-toggle73:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uzhzhatlar-text582 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uzhzhatlar-dropdown-arrow8 {
  transition: 0.3s;
}
.uzhzhatlar-dropdown-list8 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uzhzhatlar-dropdown62 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle74 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle74:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text583 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown63 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle75 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle75:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text584 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown64 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle76 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle76:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text585 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-dropdown65 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uzhzhatlar-dropdown-toggle77 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uzhzhatlar-dropdown-toggle77:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uzhzhatlar-text586 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uzhzhatlar-container39 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #222222;
}
.uzhzhatlar-container40 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.uzhzhatlar-image20 {
  width: 72px;
  height: 20px;
  object-fit: cover;
}
.uzhzhatlar-text589 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 440px;
  font-size: 14px;
}
.uzhzhatlar-container41 {
  gap: var(--dl-space-space-unit);
  width: 196px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.uzhzhatlar-container42 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.uzhzhatlar-text590 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-style: normal;
  font-weight: 500;
}
.uzhzhatlar-image21 {
  width: auto;
  object-fit: cover;
}
.uzhzhatlar-container43 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.uzhzhatlar-navlink80 {
  display: contents;
}
.uzhzhatlar-container44 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzhzhatlar-icon36 {
  color: var(--dl-color-gray-700);
  width: 22px;
  height: 22px;
}
.uzhzhatlar-text591 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.uzhzhatlar-text591:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzhzhatlar-navlink81 {
  display: contents;
}
.uzhzhatlar-container45 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzhzhatlar-icon38 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzhzhatlar-text592 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.uzhzhatlar-text592:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzhzhatlar-navlink82 {
  display: contents;
}
.uzhzhatlar-container46 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzhzhatlar-icon40 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzhzhatlar-text596 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.uzhzhatlar-text596:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzhzhatlar-navlink83 {
  display: contents;
}
.uzhzhatlar-container47 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzhzhatlar-icon42 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzhzhatlar-text600 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.uzhzhatlar-text600:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzhzhatlar-navlink84 {
  display: contents;
}
.uzhzhatlar-container48 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uzhzhatlar-icon44 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uzhzhatlar-text604 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.uzhzhatlar-text604:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzhzhatlar-container49 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #181818;
}
.uzhzhatlar-container50 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.uzhzhatlar-text608 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
}
.uzhzhatlar-container51 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.uzhzhatlar-navlink85 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  transition: 0.3s;
  text-decoration: none;
}
.uzhzhatlar-navlink85:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzhzhatlar-navlink86 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 14px;
  transition: 0.3s;
  text-decoration: none;
}
.uzhzhatlar-navlink86:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uzhzhatlar-image22 {
  width: auto;
  object-fit: cover;
}
.uzhzhatlar-link {
  display: contents;
}
.uzhzhatlar-image23 {
  width: auto;
  margin: 0px;
  text-decoration: none;
}
@media(max-width: 1200px) {
  .uzhzhatlar-text100 {
    color: var(--dl-color-gray-white);
    font-family: Roboto;
  }
  .uzhzhatlar-container16 {
    width: 100%;
  }
  .uzhzhatlar-text111 {
    width: 700px;
  }
  .uzhzhatlar-text114 {
    width: 700px;
  }
  .uzhzhatlar-select {
    background-color: var(--dl-color-gray-white);
  }
  .uzhzhatlar-dropdown-toggle10 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uzhzhatlar-dropdown-toggle11 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uzhzhatlar-dropdown-toggle20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uzhzhatlar-dropdown-toggle28 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uzhzhatlar-dropdown-toggle38 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uzhzhatlar-dropdown-toggle39 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media(max-width: 991px) {
  .uzhzhatlar-container11 {
    height: 200px;
  }
  .uzhzhatlar-container12 {
    display: none;
  }
  .uzhzhatlar-container16 {
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
  }
  .uzhzhatlar-container17 {
    display: none;
  }
  .uzhzhatlar-container26 {
    border-left-width: 0px;
  }
  .uzhzhatlar-container27 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .uzhzhatlar-navbar-interactive {
    padding-left: 50px;
    padding-right: 50px;
  }
  .uzhzhatlar-dropdown-toggle10 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uzhzhatlar-text474 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uzhzhatlar-dropdown-toggle11 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uzhzhatlar-text475 {
    font-size: 14px;
  }
  .uzhzhatlar-dropdown-toggle20 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uzhzhatlar-text493 {
    font-size: 14px;
  }
  .uzhzhatlar-dropdown-toggle28 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uzhzhatlar-text506 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uzhzhatlar-dropdown-toggle38 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uzhzhatlar-text524 {
    font-size: 14px;
  }
  .uzhzhatlar-dropdown-toggle39 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uzhzhatlar-text525 {
    font-size: 14px;
  }
  .uzhzhatlar-textinput1 {
    width: var(--dl-size-size-medium);
  }
  .uzhzhatlar-container39 {
    align-self: flex-start;
  }
  .uzhzhatlar-container49 {
    align-self: flex-start;
  }
}
@media(max-width: 767px) {
  .uzhzhatlar-burger-menu1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uzhzhatlar-icon10 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .uzhzhatlar-container11 {
    height: 100%;
    margin-top: 98px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: var(--dl-space-space-threeunits);
    background-size: cover;
    background-image: url("/frame%2043-200h.png");
  }
  .uzhzhatlar-container12 {
    display: none;
  }
  .uzhzhatlar-container16 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .uzhzhatlar-container26 {
    padding-left: 0px;
  }
  .uzhzhatlar-text111 {
    width: 600px;
  }
  .uzhzhatlar-text114 {
    width: 600px;
  }
  .uzhzhatlar-container27 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .uzhzhatlar-text435 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .uzhzhatlar-navbar-interactive {
    align-self: center;
    padding-top: 0px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: 0px;
    justify-content: space-between;
  }
  .uzhzhatlar-text474 {
    font-size: 12px;
  }
  .uzhzhatlar-text475 {
    font-size: 12px;
  }
  .uzhzhatlar-text493 {
    font-size: 12px;
  }
  .uzhzhatlar-text506 {
    font-size: 12px;
  }
  .uzhzhatlar-text524 {
    font-size: 12px;
  }
  .uzhzhatlar-text525 {
    font-size: 12px;
  }
  .uzhzhatlar-container32 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .uzhzhatlar-container34 {
    display: none;
  }
  .uzhzhatlar-burger-menu2 {
    align-items: center;
    justify-content: center;
  }
  .uzhzhatlar-icon22 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .uzhzhatlar-container36 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .uzhzhatlar-container38 {
    display: none;
  }
  .uzhzhatlar-icon26 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .uzhzhatlar-container39 {
    gap: var(--dl-space-space-unit);
    align-self: flex-start;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
    justify-content: center;
  }
  .uzhzhatlar-container49 {
    align-self: flex-start;
  }
}
@media(max-width: 479px) {
  .uzhzhatlar-icon10 {
    width: 32px;
    height: 32px;
  }
  .uzhzhatlar-container11 {
    margin-top: 150px;
    align-items: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .uzhzhatlar-text100 {
    color: var(--dl-color-gray-white);
    width: 273px;
    height: 50px;
    font-family: Roboto;
  }
  .uzhzhatlar-container16 {
    width: 100%;
    align-items: flex-start;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .uzhzhatlar-container26 {
    padding-left: 0px;
  }
  .uzhzhatlar-text111 {
    width: 100%;
  }
  .uzhzhatlar-text114 {
    width: 100%;
  }
  .uzhzhatlar-container27 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: center;
  }
  .uzhzhatlar-image17 {
    width: 103px;
    height: 60px;
    margin-right: 0px;
  }
  .uzhzhatlar-text435 {
    color: var(--dl-color-gray-black);
    width: 200px;
  }
  .uzhzhatlar-navbar-interactive {
    top: 133px;
    flex: 1;
    left: 0px;
    align-self: flex-end;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .uzhzhatlar-desktop-menu {
    display: none;
  }
  .uzhzhatlar-container32 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .uzhzhatlar-textinput1 {
    width: var(--dl-size-size-large);
  }
  .uzhzhatlar-burger-menu2 {
    display: flex;
  }
  .uzhzhatlar-icon22 {
    width: 32px;
    height: 32px;
  }
  .uzhzhatlar-mobile-menu {
    height: auto;
    padding: 0px;
  }
  .uzhzhatlar-container35 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
    background-color: var(--dl-color-primary-active_red);
  }
  .uzhzhatlar-container36 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .uzhzhatlar-icon26 {
    width: 32px;
    height: 32px;
  }
  .uzhzhatlar-links2 {
    gap: 0.5PX;
    width: 100%;
    height: auto;
    flex-direction: column;
  }
  .uzhzhatlar-thq-dropdown16 {
    width: 100%;
  }
  .uzhzhatlar-dropdown-toggle44 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uzhzhatlar-text536 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uzhzhatlar-thq-dropdown17 {
    width: 100%;
  }
  .uzhzhatlar-dropdown-toggle45 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uzhzhatlar-text537 {
    font-size: 14px;
  }
  .uzhzhatlar-icon28 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .uzhzhatlar-dropdown-list5 {
    background-color: var(--dl-color-primary-main);
  }
  .uzhzhatlar-dropdown-toggle46 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text538 {
    font-size: 14px;
  }
  .uzhzhatlar-dropdown-toggle47 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text541 {
    font-size: 14px;
  }
  .uzhzhatlar-dropdown-toggle48 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text542 {
    font-size: 14px;
  }
  .uzhzhatlar-dropdown-toggle49 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text543 {
    font-size: 14px;
  }
  .uzhzhatlar-dropdown-toggle50 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text548 {
    font-size: 14px;
  }
  .uzhzhatlar-dropdown-toggle51 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text549 {
    font-size: 14px;
  }
  .uzhzhatlar-dropdown-toggle52 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text550 {
    font-size: 14px;
  }
  .uzhzhatlar-dropdown-toggle53 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text553 {
    font-size: 14px;
  }
  .uzhzhatlar-thq-dropdown18 {
    width: 100%;
  }
  .uzhzhatlar-dropdown-toggle54 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uzhzhatlar-text554 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uzhzhatlar-icon30 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .uzhzhatlar-dropdown-list6 {
    background-color: var(--dl-color-primary-main);
  }
  .uzhzhatlar-dropdown-toggle55 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text555 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-dropdown-toggle56 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text556 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-dropdown-toggle57 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text557 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-dropdown-toggle58 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text558 {
    font-size: 14px;
    font-family: Roboto;
  }
  .uzhzhatlar-dropdown-toggle59 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text561 {
    font-size: 14px;
    font-family: Roboto;
  }
  .uzhzhatlar-dropdown-toggle60 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text565 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-dropdown-toggle61 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text566 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-thq-dropdown19 {
    width: 100%;
  }
  .uzhzhatlar-dropdown-toggle62 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uzhzhatlar-text567 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uzhzhatlar-icon32 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .uzhzhatlar-dropdown-list7 {
    background-color: var(--dl-color-primary-main);
  }
  .uzhzhatlar-dropdown-toggle63 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text568 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-dropdown-toggle64 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text569 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-dropdown-toggle65 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text570 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-dropdown-toggle66 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text571 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-dropdown-toggle67 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text572 {
    font-size: 14px;
    font-family: Roboto;
  }
  .uzhzhatlar-dropdown-toggle68 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text575 {
    font-size: 14px;
  }
  .uzhzhatlar-dropdown-toggle69 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text578 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-dropdown-toggle70 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text579 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-dropdown-toggle71 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text580 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-thq-dropdown20 {
    width: 100%;
  }
  .uzhzhatlar-dropdown-toggle72 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uzhzhatlar-text581 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uzhzhatlar-thq-dropdown21 {
    width: 100%;
  }
  .uzhzhatlar-dropdown-toggle73 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uzhzhatlar-text582 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uzhzhatlar-icon34 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .uzhzhatlar-dropdown-list8 {
    background-color: var(--dl-color-primary-main);
  }
  .uzhzhatlar-dropdown-toggle74 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text583 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-dropdown-toggle75 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text584 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-dropdown-toggle76 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text585 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uzhzhatlar-dropdown-toggle77 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uzhzhatlar-text586 {
    font-size: 14px;
    font-family: Roboto;
  }
  .uzhzhatlar-container39 {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .uzhzhatlar-text589 {
    width: 250px;
  }
  .uzhzhatlar-container49 {
    gap: var(--dl-space-space-halfunit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .uzhzhatlar-container50 {
    gap: var(--dl-space-space-halfunit);
    align-self: center;
    flex-direction: column;
  }
  .uzhzhatlar-container51 {
    flex-wrap: wrap;
    align-self: center;
    flex-direction: row;
  }
  .uzhzhatlar-image22 {
    align-self: center;
  }
  .uzhzhatlar-image23 {
    display: none;
  }
}
