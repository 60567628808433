.home-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  min-height: 100vh;
  align-items: center;
  padding-top: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: flex-start;
}
.home-container2 {
  gap: var(--dl-space-space-unit);
  height: auto;
  display: flex;
  align-self: stretch;
  margin-top: 0px;
  margin-left: 0px;
  padding-top: var(--dl-space-space-threeunits);
  margin-right: 0px;
  padding-left: 150px;
  margin-bottom: 0px;
  padding-right: 150px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: #F3F5F6;
}
.home-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-text10 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 700;
}
.home-select1 {
  width: 100%;
  height: 32px;
  padding: var(--dl-space-space-halfunit);
  font-size: small;
  text-align: center;
  padding-top: HalfUnit;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-primary-main);
  color: #F3F5F6;
}
.home-option10 {
  color: var(--dl-color-gray-900);
  text-emphasis-color: var(--dl-color-gray-white);
}
.home-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-text11 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 700;
}
.home-select2 {
  width: 100%;
  height: 32px;
  padding: var(--dl-space-space-halfunit);
  font-size: small;
  text-align: center;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-primary-main);
  color: #F3F5F6;
}
.home-option23 {
  color: var(--dl-color-gray-900);
  text-emphasis-color: var(--dl-color-gray-white);
}
.home-container5 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: 150px;
  padding-right: 150px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #ffffff;
}
.home-text12 {
  fill: var(--dl-color-primary-main);
  color: var(--dl-color-primary-main);
  font-size: 15px;
  max-width: auto;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
}
.home-text15 {
  fill: var(--dl-color-primary-main);
  color: var(--dl-color-primary-main);
  font-size: 15px;
  max-width: auto;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
}
.home-text18 {
  fill: var(--dl-color-primary-main);
  color: var(--dl-color-primary-main);
  font-size: 15px;
  max-width: auto;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
}
.home-container6 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: 150px;
  padding-right: 150px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-end;
  background-color: var(--dl-color-primary-main);
}
.home-image {
  width: 150px;
  height: 120px;
  object-fit: cover;
}
@media(max-width: 1200px) {
  .home-container2 {
    margin-top: var(--dl-space-space-sixunits);
    margin-left: 0px;
    padding-left: var(--dl-space-space-sixunits);
    padding-right: var(--dl-space-space-sixunits);
    flex-direction: row;
    background-color: #F3F5F6;
  }
  .home-container3 {
    gap: var(--dl-space-space-twounits);
    align-self: center;
    align-items: center;
    flex-direction: row;
  }
  .home-text10 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
  }
  .home-option10 {
    color: var(--dl-color-gray-900);
  }
  .home-container4 {
    gap: var(--dl-space-space-twounits);
    align-self: center;
    align-items: center;
    flex-direction: row;
  }
  .home-text11 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
  }
  .home-option23 {
    color: var(--dl-color-gray-900);
  }
}
@media(max-width: 991px) {
  .home-container2 {
    align-self: flex-start;
  }
  .home-container5 {
    padding: 100px;
  }
  .home-container6 {
    align-self: flex-start;
  }
}
@media(max-width: 767px) {
  .home-container2 {
    align-self: center;
    height: auto;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    margin-top: 0px;
    flex-direction: column;
  }
  .home-container6 {
    align-self: center;
    position: fixed;
    bottom: 0;
    padding-bottom: 24px;

  }
  .home-container5 {
    padding: 16px;
  }
}

