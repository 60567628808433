.navbar-interactive-container {
  display: flex;
  position: relative;
}
.navbar-interactive-navbar-interactive {
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  position: unset;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-main);
  border-width: 0px;
  padding-left: 150px;
  padding-right: 150px;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
  border-bottom-width: 0.5px;
}
.navbar-interactive-navlink1 {
  display: contents;
}
.navbar-interactive-image1 {
  width: 100px;
  height: 80px;
  text-decoration: none;
}
.navbar-interactive-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar-interactive-burger-menu {
  display: none;
}
.navbar-interactive-icon1 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-interactive-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.navbar-interactive-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-interactive-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar-interactive-navlink2 {
  display: contents;
}
.navbar-interactive-image2 {
  width: 137px;
  height: 73px;
  text-decoration: none;
}
.navbar-interactive-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-interactive-icon3 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.navbar-interactive-links {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-interactive-navlink3 {
  font-size: 24px;
  font-style: normal;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 1.5;
  border-color: var(--dl-color-primary-active);
  border-width: 0px;
  text-decoration: none;
  border-bottom-width: 4px;
}
.navbar-interactive-text1 {
  font-size: 24px;
  font-family: "Open Sans";
  text-decoration: none;
}
.navbar-interactive-text2 {
  font-size: 24px;
  font-family: "Open Sans";
  text-decoration: none;
}
.navbar-interactive-text3 {
  font-size: 24px;
  font-family: "Open Sans";
  text-decoration: none;
}
.navbar-interactive-text4 {
  font-size: 24px;
  font-family: "Open Sans";
  text-decoration: none;
}
.navbar-interactive-text5 {
  font-size: 24px;
  font-family: "Open Sans";
  text-decoration: none;
}
.navbar-interactive-text6 {
  fill: var(--dl-color-primary-active);
  color: var(--dl-color-primary-active);
  font-size: 24px;
  font-style: normal;
  transition: 0.3s;
  font-family: "Open Sans";
  font-weight: 600;
  line-height: 1.5;
  margin-right: var(--dl-space-space-threeunits);
  text-transform: none;
  text-decoration: none;
}
.navbar-interactive-text6:hover {
  fill: var(--dl-color-primary-hover);
  color: var(--dl-color-primary-hover);
}
.navbar-interactive-text7 {
  color: var(--dl-color-primary-active);
  font-size: 20px;
  line-height: normal;
  text-decoration: none;
}
.navbar-interactive-icon-group {
  border: 2px dashed rgba(120, 120, 120, 0.4);
}
.navbar-interactiveroot-class-name {
  width: 100%;
}
@media(max-width: 1200px) {
  .navbar-interactive-navbar-interactive {
    padding-left: var(--dl-space-space-sixunits);
    padding-right: var(--dl-space-space-sixunits);
  }
  .navbar-interactive-image1 {
    width: 100px;
    height: 80px;
  }
}
@media(max-width: 767px) {
  .navbar-interactive-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar-interactive-desktop-menu {
    display: none;
  }
  .navbar-interactive-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar-interactive-icon1 {
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
  }
  .navbar-interactive-navlink3 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .navbar-interactive-text1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navbar-interactive-text2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navbar-interactive-text3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navbar-interactive-text4 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navbar-interactive-text5 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .navbar-interactive-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navbar-interactive-image1 {
    width: 103px;
    height: 60px;
    margin-right: 0px;
  }
  .navbar-interactive-icon1 {
    width: 32px;
    height: 32px;
  }
  .navbar-interactive-mobile-menu {
    padding: 16px;
  }
  .navbar-interactive-image2 {
    width: 103px;
    height: 60px;
    margin-right: 0px;
  }
  .navbar-interactive-icon3 {
    width: 32px;
    height: 32px;
  }
  .navbar-interactive-navlink3 {
    font-size: 20px;
    font-style: normal;
    font-family: Open Sans;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
  }
  .navbar-interactive-text1 {
    font-size: 20px;
  }
  .navbar-interactive-text2 {
    font-size: 20px;
  }
  .navbar-interactive-text3 {
    font-size: 20px;
  }
  .navbar-interactive-text4 {
    font-size: 20px;
  }
  .navbar-interactive-text5 {
    font-size: 20px;
    font-family: Open Sans;
    margin-bottom: var(--dl-space-space-twounits);
    text-decoration: none;
  }
  .navbar-interactive-text6 {
    fill: var(--dl-color-primary-active);
    color: var(--dl-color-primary-active);
    font-size: 24px;
    font-style: normal;
    font-family: Open Sans;
    font-weight: 600;
    line-height: 1.5;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    text-transform: none;
    text-decoration: none;
  }
  .navbar-interactive-text7 {
    color: var(--dl-color-primary-active);
    font-size: 16px;
    font-style: normal;
    font-family: Open Sans;
    line-height: normal;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    text-decoration: none;
  }
  .navbar-interactiveroot-class-name {
    display: none;
    align-self: stretch;
  }
}
