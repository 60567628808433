.tegishli-malumotlar-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.tegishli-malumotlar-container11 {
  gap: 60px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: 146px;
  align-items: flex-start;
  padding-top: 60px;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/frame%2043-200h.png");
  background-repeat: space;
  background-position: center;
}
.tegishli-malumotlar-text100 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tegishli-malumotlar-container12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  background-color: #F0F0F0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.tegishli-malumotlar-navlink10 {
  display: contents;
}
.tegishli-malumotlar-container13 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.tegishli-malumotlar-icon10 {
  fill: var(--dl-color-primary-500);
  color: var(--dl-color-primary-500);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-text101 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.tegishli-malumotlar-navlink11 {
  display: contents;
}
.tegishli-malumotlar-container14 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.tegishli-malumotlar-icon12 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 16px;
  height: 16px;
}
.tegishli-malumotlar-text102 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.tegishli-malumotlar-navlink12 {
  display: contents;
}
.tegishli-malumotlar-container15 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.tegishli-malumotlar-icon14 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 16px;
  height: 16px;
}
.tegishli-malumotlar-text103 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.tegishli-malumotlar-container16 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  margin: 100px;
  display: flex;
  align-self: flex-start;
  margin-top: 0px;
  align-items: flex-start;
  margin-left: 0px;
  padding-top: var(--dl-space-space-twounits);
  margin-right: 0px;
  padding-left: 100px;
  margin-bottom: 0px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
}
.tegishli-malumotlar-container17 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.tegishli-malumotlar-container18 {
  gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.tegishli-malumotlar-text104 {
  color: var(--dl-color-gray-500);
  font-style: normal;
  font-weight: 500;
}
.tegishli-malumotlar-icon16 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 12px;
  height: 12px;
}
.tegishli-malumotlar-image10 {
  width: auto;
  object-fit: cover;
}
.tegishli-malumotlar-container19 {
  gap: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.tegishli-malumotlar-navlink13 {
  display: contents;
}
.tegishli-malumotlar-container20 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-image11 {
  width: var(--dl-size-size-xsmall);
  object-fit: cover;
}
.tegishli-malumotlar-text105 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tegishli-malumotlar-navlink14 {
  display: contents;
}
.tegishli-malumotlar-container21 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-gray-main);
}
.tegishli-malumotlar-container21:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.tegishli-malumotlar-image12 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.tegishli-malumotlar-text106 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: "Roboto";
  font-weight: 500;
}
.tegishli-malumotlar-text106:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-navlink15 {
  display: contents;
}
.tegishli-malumotlar-container22 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-container22:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.tegishli-malumotlar-image13 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.tegishli-malumotlar-text107 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tegishli-malumotlar-text107:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-navlink16 {
  display: contents;
}
.tegishli-malumotlar-container23 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-container23:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.tegishli-malumotlar-image14 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.tegishli-malumotlar-text108 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tegishli-malumotlar-text108:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-navlink17 {
  display: contents;
}
.tegishli-malumotlar-container24 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-container24:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.tegishli-malumotlar-image15 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.tegishli-malumotlar-text109 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tegishli-malumotlar-text109:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-navlink18 {
  display: contents;
}
.tegishli-malumotlar-container25 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-container25:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.tegishli-malumotlar-image16 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.tegishli-malumotlar-text110 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tegishli-malumotlar-text110:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-navlink19 {
  display: contents;
}
.tegishli-malumotlar-container26 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-container26:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.tegishli-malumotlar-image17 {
  width: var(--dl-size-size-xsmall);
  align-self: center;
  object-fit: cover;
  transition: 0.3s;
}

.tegishli-malumotlar-text111 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tegishli-malumotlar-text111:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-navlink20 {
  display: contents;
}
.tegishli-malumotlar-container27 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
  border-bottom-width: 1px;
}
.tegishli-malumotlar-container27:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.tegishli-malumotlar-image18 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.tegishli-malumotlar-text112 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tegishli-malumotlar-text112:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-container28 {
  gap: var(--dl-space-space-twounits);
  width: 900px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  border-left-width: 1px;
}
.tegishli-malumotlar-container29 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0x;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.tegishli-malumotlar-container30 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-container31 {
  gap: var(--dl-space-space-halfunit);
  width: 110px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: flex-start;
  box-shadow: 3px 3px 10px 0px #989898,-3px -3px 10px 0px #989898;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-icon18 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-text113 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 12px;
  text-align: center;
}
.tegishli-malumotlar-container32 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: center;
  background-color: #dfdfdf;
}
.tegishli-malumotlar-text114 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
  font-size: 22px;
}
.tegishli-malumotlar-container33 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-text115 {
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
}
.tegishli-malumotlar-text116 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-text118 {
  font-weight: 500;
}
.tegishli-malumotlar-button1 {
  gap: 4px;
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
  background-color: #498074;
}
.tegishli-malumotlar-button1:hover {
  background-color: #233D37;
}
.tegishli-malumotlar-text119 {
  font-size: 14px;
}
.tegishli-malumotlar-container34 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0x;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.tegishli-malumotlar-container35 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-container36 {
  gap: var(--dl-space-space-halfunit);
  width: 110px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  box-shadow: 3px 3px 10px 0px #989898,-3px -3px 10px 0px #989898;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-icon20 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-text120 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  align-self: center;
  font-family: "Roboto";
}
.tegishli-malumotlar-container37 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: center;
  background-color: #dfdfdf;
}
.tegishli-malumotlar-text121 {
  color: var(--dl-color-gray-gray-main);
  font-size: 22px;
}
.tegishli-malumotlar-container38 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-text122 {
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
}
.tegishli-malumotlar-text125 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-text127 {
  font-weight: 500;
}
.tegishli-malumotlar-button2 {
  gap: 4px;
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
  background-color: #498074;
}
.tegishli-malumotlar-button2:hover {
  background-color: #233D37;
}
.tegishli-malumotlar-text128 {
  font-size: 14px;
}
.tegishli-malumotlar-container39 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0x;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.tegishli-malumotlar-container40 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-container41 {
  gap: var(--dl-space-space-halfunit);
  width: 110px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  box-shadow: 3px 3px 10px 0px #989898,-3px -3px 10px 0px #989898;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-icon22 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-text129 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  align-self: center;
  font-family: "Roboto";
}
.tegishli-malumotlar-container42 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: center;
  background-color: #dfdfdf;
}
.tegishli-malumotlar-text130 {
  color: var(--dl-color-gray-gray-main);
  font-size: 22px;
}
.tegishli-malumotlar-container43 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-text131 {
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
}
.tegishli-malumotlar-text134 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-text136 {
  font-weight: 500;
}
.tegishli-malumotlar-button3 {
  gap: 4px;
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
  background-color: #498074;
}
.tegishli-malumotlar-button3:hover {
  background-color: #233D37;
}
.tegishli-malumotlar-text137 {
  font-size: 14px;
}
.tegishli-malumotlar-container44 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0x;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.tegishli-malumotlar-container45 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-container46 {
  gap: var(--dl-space-space-halfunit);
  width: 110px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  box-shadow: 3px 3px 10px 0px #989898,-3px -3px 10px 0px #989898;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-icon24 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-text138 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  font-family: "Roboto";
}
.tegishli-malumotlar-container47 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: center;
  background-color: #dfdfdf;
}
.tegishli-malumotlar-text141 {
  color: var(--dl-color-gray-gray-main);
  font-size: 22px;
}
.tegishli-malumotlar-container48 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-text142 {
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
}
.tegishli-malumotlar-text145 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-text147 {
  font-weight: 500;
}
.tegishli-malumotlar-button4 {
  gap: 4px;
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
  background-color: #498074;
}
.tegishli-malumotlar-button4:hover {
  background-color: #233D37;
}
.tegishli-malumotlar-text148 {
  font-size: 14px;
}
.tegishli-malumotlar-container49 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0x;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.tegishli-malumotlar-container50 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-container51 {
  gap: var(--dl-space-space-halfunit);
  width: 110px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  box-shadow: 3px 3px 10px 0px #989898,-3px -3px 10px 0px #989898;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-icon26 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-text149 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  align-self: center;
  font-family: "Roboto";
}
.tegishli-malumotlar-container52 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: center;
  background-color: #dfdfdf;
}
.tegishli-malumotlar-text152 {
  color: var(--dl-color-gray-gray-main);
  font-size: 22px;
}
.tegishli-malumotlar-container53 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-text153 {
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
}
.tegishli-malumotlar-text156 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-text158 {
  font-weight: 500;
}
.tegishli-malumotlar-button5 {
  gap: 4px;
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
  background-color: #498074;
}
.tegishli-malumotlar-button5:hover {
  background-color: #233D37;
}
.tegishli-malumotlar-text159 {
  font-size: 14px;
}
.tegishli-malumotlar-container54 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0x;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.tegishli-malumotlar-container55 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-container56 {
  gap: var(--dl-space-space-halfunit);
  width: 110px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  box-shadow: 3px 3px 10px 0px #989898,-3px -3px 10px 0px #989898;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-icon28 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-text160 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  font-family: "Roboto";
}
.tegishli-malumotlar-container57 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: center;
  background-color: #dfdfdf;
}
.tegishli-malumotlar-text161 {
  color: var(--dl-color-gray-gray-main);
  font-size: 22px;
}
.tegishli-malumotlar-container58 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-text162 {
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
}
.tegishli-malumotlar-text163 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-text165 {
  font-weight: 500;
}
.tegishli-malumotlar-button6 {
  gap: 4px;
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
  background-color: #498074;
}
.tegishli-malumotlar-button6:hover {
  background-color: #233D37;
}
.tegishli-malumotlar-text166 {
  font-size: 14px;
}
.tegishli-malumotlar-container59 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0x;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.tegishli-malumotlar-container60 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-container61 {
  gap: var(--dl-space-space-halfunit);
  width: 110px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  box-shadow: 3px 3px 10px 0px #989898,-3px -3px 10px 0px #989898;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-icon30 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-text167 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  font-family: "Roboto";
}
.tegishli-malumotlar-container62 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: center;
  background-color: #dfdfdf;
}
.tegishli-malumotlar-text168 {
  color: var(--dl-color-gray-gray-main);
  font-size: 22px;
}
.tegishli-malumotlar-container63 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-text169 {
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
}
.tegishli-malumotlar-text170 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-text172 {
  font-weight: 500;
}
.tegishli-malumotlar-button7 {
  gap: 4px;
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
  background-color: #498074;
}
.tegishli-malumotlar-button7:hover {
  background-color: #233D37;
}
.tegishli-malumotlar-text173 {
  font-size: 14px;
}
.tegishli-malumotlar-container64 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0x;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.tegishli-malumotlar-container65 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-container66 {
  gap: var(--dl-space-space-halfunit);
  width: 110px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  box-shadow: 3px 3px 10px 0px #989898,-3px -3px 10px 0px #989898;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-icon32 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-text174 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  font-family: "Roboto";
}
.tegishli-malumotlar-container67 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: center;
  background-color: #dfdfdf;
}
.tegishli-malumotlar-text175 {
  color: var(--dl-color-gray-gray-main);
  font-size: 22px;
}
.tegishli-malumotlar-container68 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-text176 {
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
}
.tegishli-malumotlar-text177 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-text179 {
  font-weight: 500;
}
.tegishli-malumotlar-button8 {
  gap: 4px;
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
  background-color: #498074;
}
.tegishli-malumotlar-button8:hover {
  background-color: #233D37;
}
.tegishli-malumotlar-text180 {
  font-size: 14px;
}
.tegishli-malumotlar-container69 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0x;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.tegishli-malumotlar-container70 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-container71 {
  gap: var(--dl-space-space-halfunit);
  width: 110px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  box-shadow: 3px 3px 10px 0px #989898,-3px -3px 10px 0px #989898;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-icon34 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-text181 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  font-family: "Roboto";
}
.tegishli-malumotlar-container72 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: center;
  background-color: #dfdfdf;
}
.tegishli-malumotlar-text184 {
  color: var(--dl-color-gray-gray-main);
  font-size: 22px;
}
.tegishli-malumotlar-container73 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-text185 {
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
}
.tegishli-malumotlar-text188 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-text190 {
  font-weight: 500;
}
.tegishli-malumotlar-button9 {
  gap: 4px;
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  display: flex;
  align-self: flex-end;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
  background-color: #498074;
}
.tegishli-malumotlar-button9:hover {
  background-color: #233D37;
}
.tegishli-malumotlar-text191 {
  font-size: 14px;
}
.tegishli-malumotlar-container74 {
  flex: 0;
  width: 100%;
  display: flex;
  position: fixed;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-navlink21 {
  display: contents;
}
.tegishli-malumotlar-container75 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}
.tegishli-malumotlar-image19 {
  width: 106px;
  height: 64px;
  text-decoration: none;
}
.tegishli-malumotlar-text192 {
  fill: var(--dl-color-primary-main);
  color: var(--dl-color-primary-main);
  width: 275px;
  height: 52px;
  font-size: 10px;
  max-width: auto;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  margin-left: var(--dl-space-space-fourunits);
}
.tegishli-malumotlar-container76 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.tegishli-malumotlar-container77 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.tegishli-malumotlar-text195 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-family: "Roboto";
}
.tegishli-malumotlar-text197 {
  font-style: normal;
  font-weight: 700;
}
.tegishli-malumotlar-text198 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-family: "Roboto";
}
.tegishli-malumotlar-text199 {
  font-style: normal;
  font-weight: 700;
}
.tegishli-malumotlar-image20 {
  width: 16px;
  height: 11px;
  object-fit: cover;
}
.tegishli-malumotlar-text230 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 700;
}
.tegishli-malumotlar-image21 {
  width: 16px;
  height: 11px;
  object-fit: cover;
}
.tegishli-malumotlar-select {
  height: auto;
  font-size: x-small;
  background-color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-navbar-interactive {
  width: 100%;
  display: flex;
  position: fixed;
  align-self: center;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: px;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: px;
  justify-content: center;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-desktop-menu {
  flex: 1;
  width: auto;
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
}
.tegishli-malumotlar-container78 {
  display: flex;
  justify-content: space-between;
}
.tegishli-malumotlar-links1 {
  gap: 1px;
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-danger-700);
}
.tegishli-malumotlar-thq-dropdown10 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-dropdown-toggle10:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: mixed;
  padding-right: mixed;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-dropdown-toggle10:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-text231 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tegishli-malumotlar-thq-dropdown11 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-dropdown-toggle11:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-dropdown-toggle11:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-text232 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tegishli-malumotlar-dropdown-arrow1 {
  transition: 0.3s;
}
.tegishli-malumotlar-dropdown-list1 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tegishli-malumotlar-dropdown10 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle12 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle12:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text233 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.tegishli-malumotlar-dropdown11 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle13 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle13:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text236 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown12 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle14 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle14:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text237 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown13 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle15 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle15:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text238 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.tegishli-malumotlar-dropdown14 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle16 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle16:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text243 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown15 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle17 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle17:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text244 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown16 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle18 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle18:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text245 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown17 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle19 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.tegishli-malumotlar-dropdown-toggle19:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text249 {
  font-style: normal;
}
.tegishli-malumotlar-thq-dropdown12 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle20 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-dropdown-toggle20:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-dropdown-toggle20:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-text250 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tegishli-malumotlar-dropdown-arrow2 {
  transition: 0.3s;
}
.tegishli-malumotlar-dropdown-list2 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tegishli-malumotlar-dropdown18 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle21 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle21:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text251 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown19 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle22 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle22:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text252 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown20 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle23 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle23:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text253 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown21 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle24 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle24:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text254 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.tegishli-malumotlar-dropdown22 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle25 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle25:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text257 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown23 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle26 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle26:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text261 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown24 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle27 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  padding-bottom: 20px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle27:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text262 {
  font-style: normal;
}
.tegishli-malumotlar-thq-dropdown13 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle28 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-dropdown-toggle28:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-dropdown-toggle28:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-text263 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tegishli-malumotlar-dropdown-arrow3 {
  transition: 0.3s;
}
.tegishli-malumotlar-dropdown-list3 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tegishli-malumotlar-dropdown25 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle29 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle29:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text264 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown26 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle30 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle30:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text265 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown27 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle31 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle31:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text269 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.tegishli-malumotlar-dropdown28 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle32 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle32:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text272 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown29 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle33 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle33:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text273 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown30 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle34 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle34:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text277 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown31 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle35 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.tegishli-malumotlar-dropdown-toggle35:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text278 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown32 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle36 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle36:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-dropdown33 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle37 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle37:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-thq-dropdown14 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle38 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-dropdown-toggle38:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-dropdown-toggle38:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-text281 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tegishli-malumotlar-thq-dropdown15 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle39 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-dropdown-toggle39:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-dropdown-toggle39:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-text282 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tegishli-malumotlar-dropdown-arrow4 {
  transition: 0.3s;
}
.tegishli-malumotlar-dropdown-list4 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tegishli-malumotlar-dropdown34 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle40 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle40:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text283 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown35 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle41 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle41:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text284 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown36 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle42 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle42:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text285 {
  font-style: normal;
}
.tegishli-malumotlar-dropdown37 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle43 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle43:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text286 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.tegishli-malumotlar-container79 {
  gap: 4px;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-end;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-textinput1 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  border-width: 0px;
  border-radius: 0px;
}
.tegishli-malumotlar-container80 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.tegishli-malumotlar-icon36 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 24px;
}
.tegishli-malumotlar-container81 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.tegishli-malumotlar-text289 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tegishli-malumotlar-text290 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tegishli-malumotlar-burger-menu {
  display: none;
}
.tegishli-malumotlar-icon38 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.tegishli-malumotlar-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.tegishli-malumotlar-container82 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.tegishli-malumotlar-container83 {
  gap: 4px;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-end;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-textinput2 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  border-width: 0px;
  border-radius: 0px;
}
.tegishli-malumotlar-container84 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.tegishli-malumotlar-icon40 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 24px;
}
.tegishli-malumotlar-container85 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.tegishli-malumotlar-text291 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tegishli-malumotlar-text292 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.tegishli-malumotlar-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tegishli-malumotlar-icon42 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-links2 {
  gap: 1px;
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-danger-700);
}
.tegishli-malumotlar-thq-dropdown16 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle44 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-dropdown-toggle44:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-dropdown-toggle44:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-text293 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tegishli-malumotlar-thq-dropdown17 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle45 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-dropdown-toggle45:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-dropdown-toggle45:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-text294 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tegishli-malumotlar-dropdown-arrow5 {
  transition: 0.3s;
}
.tegishli-malumotlar-dropdown-list5 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tegishli-malumotlar-dropdown38 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle46 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle46:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text295 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown39 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle47 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle47:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text298 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown40 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle48 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle48:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text299 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown41 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle49 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle49:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text300 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown42 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle50 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle50:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text305 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown43 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle51 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle51:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text306 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown44 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle52 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle52:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text307 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown45 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle53 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.tegishli-malumotlar-dropdown-toggle53:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text310 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-thq-dropdown18 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle54 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-dropdown-toggle54:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-dropdown-toggle54:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-text311 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tegishli-malumotlar-dropdown-arrow6 {
  transition: 0.3s;
}
.tegishli-malumotlar-dropdown-list6 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tegishli-malumotlar-dropdown46 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle55 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle55:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text312 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown47 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle56 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle56:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text313 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown48 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle57 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle57:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text314 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown49 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle58 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle58:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text315 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown50 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle59 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle59:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text318 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown51 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle60 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle60:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text322 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown52 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle61 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle61:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text323 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-thq-dropdown19 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle62 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-dropdown-toggle62:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-dropdown-toggle62:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-text324 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tegishli-malumotlar-dropdown-arrow7 {
  transition: 0.3s;
}
.tegishli-malumotlar-dropdown-list7 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tegishli-malumotlar-dropdown53 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle63 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle63:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text325 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown54 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle64 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle64:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text326 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown55 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle65 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle65:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text327 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown56 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle66 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.tegishli-malumotlar-dropdown-toggle66:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text328 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown57 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle67 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle67:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text329 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown58 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle68 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle68:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text332 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown59 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle69 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle69:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text335 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown60 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle70 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle70:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text336 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown61 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle71 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle71:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text337 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-thq-dropdown20 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle72 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-dropdown-toggle72:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-dropdown-toggle72:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-text338 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tegishli-malumotlar-thq-dropdown21 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle73 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.tegishli-malumotlar-dropdown-toggle73:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-dropdown-toggle73:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.tegishli-malumotlar-text339 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.tegishli-malumotlar-dropdown-arrow8 {
  transition: 0.3s;
}
.tegishli-malumotlar-dropdown-list8 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.tegishli-malumotlar-dropdown62 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle74 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle74:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text340 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown63 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle75 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle75:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text341 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown64 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle76 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle76:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text342 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-dropdown65 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.tegishli-malumotlar-dropdown-toggle77 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.tegishli-malumotlar-dropdown-toggle77:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.tegishli-malumotlar-text343 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.tegishli-malumotlar-container86 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #222222;
}
.tegishli-malumotlar-container87 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.tegishli-malumotlar-image22 {
  width: 72px;
  height: 20px;
  object-fit: cover;
}
.tegishli-malumotlar-text346 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 440px;
  font-size: 14px;
}
.tegishli-malumotlar-container88 {
  gap: var(--dl-space-space-unit);
  width: 196px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.tegishli-malumotlar-container89 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.tegishli-malumotlar-text347 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-style: normal;
  font-weight: 500;
}
.tegishli-malumotlar-image23 {
  width: auto;
  object-fit: cover;
}
.tegishli-malumotlar-container90 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.tegishli-malumotlar-navlink82 {
  display: contents;
}
.tegishli-malumotlar-container91 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.tegishli-malumotlar-icon52 {
  color: var(--dl-color-gray-700);
  width: 22px;
  height: 22px;
}
.tegishli-malumotlar-text348 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.tegishli-malumotlar-text348:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-navlink83 {
  display: contents;
}
.tegishli-malumotlar-container92 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.tegishli-malumotlar-icon54 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-text349 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.tegishli-malumotlar-text349:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-navlink84 {
  display: contents;
}
.tegishli-malumotlar-container93 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.tegishli-malumotlar-icon56 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-text353 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.tegishli-malumotlar-text353:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-navlink85 {
  display: contents;
}
.tegishli-malumotlar-container94 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.tegishli-malumotlar-icon58 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-text357 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.tegishli-malumotlar-text357:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-navlink86 {
  display: contents;
}
.tegishli-malumotlar-container95 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.tegishli-malumotlar-icon60 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tegishli-malumotlar-text361 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.tegishli-malumotlar-text361:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-container96 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #181818;
}
.tegishli-malumotlar-container97 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.tegishli-malumotlar-text365 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
}
.tegishli-malumotlar-container98 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.tegishli-malumotlar-navlink87 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  transition: 0.3s;
  text-decoration: none;
}
.tegishli-malumotlar-navlink87:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-navlink88 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 14px;
  transition: 0.3s;
  text-decoration: none;
}
.tegishli-malumotlar-navlink88:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.tegishli-malumotlar-image24 {
  width: auto;
  object-fit: cover;
}
.tegishli-malumotlar-link {
  display: contents;
}
.tegishli-malumotlar-image25 {
  width: auto;
  margin: 0px;
  text-decoration: none;
}
@media(max-width: 1200px) {
  .tegishli-malumotlar-text100 {
    color: var(--dl-color-gray-white);
    font-family: Roboto;
  }
  .tegishli-malumotlar-container16 {
    width: 100%;
  }
  .tegishli-malumotlar-container28 {
    width: 100%;
  }
  .tegishli-malumotlar-container29 {
    width: 600px;
  }
  .tegishli-malumotlar-container33 {
    width: 100%;
  }
  .tegishli-malumotlar-text115 {
    width: 100%;
  }
  .tegishli-malumotlar-text116 {
    width: 100%;
  }
  .tegishli-malumotlar-container34 {
    width: 600px;
  }
  .tegishli-malumotlar-text122 {
    width: 100%;
  }
  .tegishli-malumotlar-text125 {
    width: 100%;
  }
  .tegishli-malumotlar-container39 {
    width: 600px;
  }
  .tegishli-malumotlar-container43 {
    width: 100%;
  }
  .tegishli-malumotlar-text131 {
    width: 100%;
  }
  .tegishli-malumotlar-text134 {
    width: 100%;
  }
  .tegishli-malumotlar-container44 {
    width: 600px;
  }
  .tegishli-malumotlar-container48 {
    width: 100%;
  }
  .tegishli-malumotlar-text142 {
    width: 100%;
  }
  .tegishli-malumotlar-text145 {
    width: 100%;
  }
  .tegishli-malumotlar-container49 {
    width: 600px;
  }
  .tegishli-malumotlar-text153 {
    width: 100%;
  }
  .tegishli-malumotlar-text156 {
    width: 100%;
  }
  .tegishli-malumotlar-container54 {
    width: 600px;
  }
  .tegishli-malumotlar-text162 {
    width: 100%;
  }
  .tegishli-malumotlar-text163 {
    width: 100%;
  }
  .tegishli-malumotlar-container59 {
    width: 600px;
  }
  .tegishli-malumotlar-text169 {
    width: 100%;
  }
  .tegishli-malumotlar-text170 {
    width: 100%;
  }
  .tegishli-malumotlar-container64 {
    width: 600px;
  }
  .tegishli-malumotlar-text176 {
    width: 100%;
  }
  .tegishli-malumotlar-text177 {
    width: 100%;
  }
  .tegishli-malumotlar-container69 {
    width: 600px;
  }
  .tegishli-malumotlar-text185 {
    width: 100%;
  }
  .tegishli-malumotlar-text188 {
    width: 100%;
  }
  .tegishli-malumotlar-select {
    background-color: var(--dl-color-gray-white);
  }
  .tegishli-malumotlar-dropdown-toggle10 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tegishli-malumotlar-dropdown-toggle11 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tegishli-malumotlar-dropdown-toggle20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tegishli-malumotlar-dropdown-toggle28 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tegishli-malumotlar-dropdown-toggle38 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tegishli-malumotlar-dropdown-toggle39 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media(max-width: 991px) {
  .tegishli-malumotlar-container11 {
    gap: var(--dl-space-space-halfunit);
    height: auto;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .tegishli-malumotlar-container12 {
    display: none;
  }
  .tegishli-malumotlar-container16 {
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
  }
  .tegishli-malumotlar-container17 {
    display: none;
  }
  .tegishli-malumotlar-container28 {
    border-left-width: 0px;
  }
  .tegishli-malumotlar-container74 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .tegishli-malumotlar-navbar-interactive {
    padding-left: 50px;
    padding-right: 50px;
  }
  .tegishli-malumotlar-dropdown-toggle10 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .tegishli-malumotlar-text231 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .tegishli-malumotlar-dropdown-toggle11 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .tegishli-malumotlar-text232 {
    font-size: 14px;
  }
  .tegishli-malumotlar-dropdown-toggle20 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .tegishli-malumotlar-text250 {
    font-size: 14px;
  }
  .tegishli-malumotlar-dropdown-toggle28 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .tegishli-malumotlar-text263 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .tegishli-malumotlar-dropdown-toggle38 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .tegishli-malumotlar-text281 {
    font-size: 14px;
  }
  .tegishli-malumotlar-dropdown-toggle39 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .tegishli-malumotlar-text282 {
    font-size: 14px;
  }
  .tegishli-malumotlar-textinput1 {
    width: var(--dl-size-size-medium);
  }
  .tegishli-malumotlar-container86 {
    align-self: flex-start;
  }
  .tegishli-malumotlar-container96 {
    align-self: flex-start;
  }
}
@media(max-width: 767px) {
  .tegishli-malumotlar-container11 {
    height: 100%;
    margin-top: 140px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: var(--dl-space-space-threeunits);
    background-size: cover;
    background-image: url("/frame%2043-200h.png");
  }
  .tegishli-malumotlar-container12 {
    display: none;
  }
  .tegishli-malumotlar-container16 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .tegishli-malumotlar-container28 {
    padding-left: 0px;
  }
  .tegishli-malumotlar-text115 {
    width: 100%;
  }
  .tegishli-malumotlar-text116 {
    width: 100%;
  }
  .tegishli-malumotlar-text122 {
    width: 100%;
  }
  .tegishli-malumotlar-text125 {
    width: 100%;
  }
  .tegishli-malumotlar-text131 {
    width: 100%;
  }
  .tegishli-malumotlar-text134 {
    width: 100%;
  }
  .tegishli-malumotlar-text145 {
    width: 100%;
  }
  .tegishli-malumotlar-text153 {
    width: 100%;
  }
  .tegishli-malumotlar-text156 {
    width: 100%;
  }
  .tegishli-malumotlar-text162 {
    width: 100%;
  }
  .tegishli-malumotlar-text163 {
    width: 100%;
  }
  .tegishli-malumotlar-text169 {
    width: 100%;
  }
  .tegishli-malumotlar-text170 {
    width: 100%;
  }
  .tegishli-malumotlar-text176 {
    width: 100%;
  }
  .tegishli-malumotlar-text177 {
    width: 100%;
  }
  .tegishli-malumotlar-text185 {
    width: 100%;
  }
  .tegishli-malumotlar-text188 {
    width: 100%;
  }
  .tegishli-malumotlar-container74 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .tegishli-malumotlar-text192 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .tegishli-malumotlar-navbar-interactive {
    align-self: center;
    padding-top: 0px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: 0px;
    justify-content: space-between;
  }
  .tegishli-malumotlar-text231 {
    font-size: 12px;
  }
  .tegishli-malumotlar-text232 {
    font-size: 12px;
  }
  .tegishli-malumotlar-text250 {
    font-size: 12px;
  }
  .tegishli-malumotlar-text263 {
    font-size: 12px;
  }
  .tegishli-malumotlar-text281 {
    font-size: 12px;
  }
  .tegishli-malumotlar-text282 {
    font-size: 12px;
  }
  .tegishli-malumotlar-container79 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .tegishli-malumotlar-container81 {
    display: none;
  }
  .tegishli-malumotlar-burger-menu {
    align-items: center;
    justify-content: center;
  }
  .tegishli-malumotlar-icon38 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .tegishli-malumotlar-container83 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .tegishli-malumotlar-container85 {
    display: none;
  }
  .tegishli-malumotlar-icon42 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .tegishli-malumotlar-container86 {
    gap: var(--dl-space-space-unit);
    align-self: flex-start;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
    justify-content: center;
  }
  .tegishli-malumotlar-container96 {
    align-self: flex-start;
  }
}
@media(max-width: 479px) {
  .tegishli-malumotlar-container11 {
    margin-top: 190px;
    align-items: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .tegishli-malumotlar-text100 {
    color: var(--dl-color-gray-white);
    width: 273px;
    height: 50px;
    align-self: flex-start;
    font-family: Roboto;
  }
  .tegishli-malumotlar-container16 {
    width: 100%;
    align-items: flex-start;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .tegishli-malumotlar-container28 {
    padding-left: 0px;
  }
  .tegishli-malumotlar-container29 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    flex-direction: column;
  }
  .tegishli-malumotlar-text115 {
    width: 100%;
  }
  .tegishli-malumotlar-text116 {
    width: 100%;
  }
  .tegishli-malumotlar-container34 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
  }
  .tegishli-malumotlar-text122 {
    width: 100%;
  }
  .tegishli-malumotlar-text125 {
    width: 100%;
  }
  .tegishli-malumotlar-container39 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
  }
  .tegishli-malumotlar-text131 {
    width: 100%;
  }
  .tegishli-malumotlar-text134 {
    width: 100%;
  }
  .tegishli-malumotlar-container44 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
  }
  .tegishli-malumotlar-text142 {
    width: 100%;
  }
  .tegishli-malumotlar-text145 {
    width: 100%;
  }
  .tegishli-malumotlar-container49 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
  }
  .tegishli-malumotlar-text153 {
    width: 100%;
  }
  .tegishli-malumotlar-text156 {
    width: 100%;
  }
  .tegishli-malumotlar-container54 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
  }
  .tegishli-malumotlar-text162 {
    width: 100%;
  }
  .tegishli-malumotlar-text163 {
    width: 100%;
  }
  .tegishli-malumotlar-container59 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
  }
  .tegishli-malumotlar-text169 {
    width: 100%;
  }
  .tegishli-malumotlar-text170 {
    width: 100%;
  }
  .tegishli-malumotlar-container64 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
  }
  .tegishli-malumotlar-text176 {
    width: 100%;
  }
  .tegishli-malumotlar-text177 {
    width: 100%;
  }
  .tegishli-malumotlar-container69 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
  }
  .tegishli-malumotlar-text185 {
    width: 100%;
  }
  .tegishli-malumotlar-text188 {
    width: 100%;
  }
  .tegishli-malumotlar-container74 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: center;
  }
  .tegishli-malumotlar-image19 {
    width: 103px;
    height: 60px;
    margin-right: 0px;
  }
  .tegishli-malumotlar-text192 {
    color: var(--dl-color-gray-black);
    width: 200px;
  }
  .tegishli-malumotlar-navbar-interactive {
    top: 133px;
    flex: 1;
    left: 0px;
    align-self: flex-end;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .tegishli-malumotlar-desktop-menu {
    display: none;
  }
  .tegishli-malumotlar-container79 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .tegishli-malumotlar-textinput1 {
    width: var(--dl-size-size-large);
  }
  .tegishli-malumotlar-burger-menu {
    display: flex;
  }
  .tegishli-malumotlar-icon38 {
    width: 32px;
    height: 32px;
  }
  .tegishli-malumotlar-mobile-menu {
    height: auto;
    padding: 0px;
  }
  .tegishli-malumotlar-container82 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
    background-color: var(--dl-color-primary-active_red);
  }
  .tegishli-malumotlar-container83 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .tegishli-malumotlar-icon42 {
    width: 32px;
    height: 32px;
  }
  .tegishli-malumotlar-links2 {
    gap: 0.5PX;
    width: 100%;
    height: auto;
    flex-direction: column;
  }
  .tegishli-malumotlar-thq-dropdown16 {
    width: 100%;
  }
  .tegishli-malumotlar-dropdown-toggle44 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .tegishli-malumotlar-text293 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .tegishli-malumotlar-thq-dropdown17 {
    width: 100%;
  }
  .tegishli-malumotlar-dropdown-toggle45 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .tegishli-malumotlar-text294 {
    font-size: 14px;
  }
  .tegishli-malumotlar-icon44 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .tegishli-malumotlar-dropdown-list5 {
    background-color: var(--dl-color-primary-main);
  }
  .tegishli-malumotlar-dropdown-toggle46 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text295 {
    font-size: 14px;
  }
  .tegishli-malumotlar-dropdown-toggle47 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text298 {
    font-size: 14px;
  }
  .tegishli-malumotlar-dropdown-toggle48 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text299 {
    font-size: 14px;
  }
  .tegishli-malumotlar-dropdown-toggle49 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text300 {
    font-size: 14px;
  }
  .tegishli-malumotlar-dropdown-toggle50 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text305 {
    font-size: 14px;
  }
  .tegishli-malumotlar-dropdown-toggle51 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text306 {
    font-size: 14px;
  }
  .tegishli-malumotlar-dropdown-toggle52 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text307 {
    font-size: 14px;
  }
  .tegishli-malumotlar-dropdown-toggle53 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text310 {
    font-size: 14px;
  }
  .tegishli-malumotlar-thq-dropdown18 {
    width: 100%;
  }
  .tegishli-malumotlar-dropdown-toggle54 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .tegishli-malumotlar-text311 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .tegishli-malumotlar-icon46 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .tegishli-malumotlar-dropdown-list6 {
    background-color: var(--dl-color-primary-main);
  }
  .tegishli-malumotlar-dropdown-toggle55 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text312 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-dropdown-toggle56 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text313 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-dropdown-toggle57 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text314 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-dropdown-toggle58 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text315 {
    font-size: 14px;
    font-family: Roboto;
  }
  .tegishli-malumotlar-dropdown-toggle59 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text318 {
    font-size: 14px;
    font-family: Roboto;
  }
  .tegishli-malumotlar-dropdown-toggle60 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text322 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-dropdown-toggle61 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text323 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-thq-dropdown19 {
    width: 100%;
  }
  .tegishli-malumotlar-dropdown-toggle62 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .tegishli-malumotlar-text324 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .tegishli-malumotlar-icon48 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .tegishli-malumotlar-dropdown-list7 {
    background-color: var(--dl-color-primary-main);
  }
  .tegishli-malumotlar-dropdown-toggle63 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text325 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-dropdown-toggle64 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text326 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-dropdown-toggle65 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text327 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-dropdown-toggle66 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text328 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-dropdown-toggle67 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text329 {
    font-size: 14px;
    font-family: Roboto;
  }
  .tegishli-malumotlar-dropdown-toggle68 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text332 {
    font-size: 14px;
  }
  .tegishli-malumotlar-dropdown-toggle69 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text335 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-dropdown-toggle70 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text336 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-dropdown-toggle71 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text337 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-thq-dropdown20 {
    width: 100%;
  }
  .tegishli-malumotlar-dropdown-toggle72 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .tegishli-malumotlar-text338 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .tegishli-malumotlar-thq-dropdown21 {
    width: 100%;
  }
  .tegishli-malumotlar-dropdown-toggle73 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .tegishli-malumotlar-text339 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .tegishli-malumotlar-icon50 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .tegishli-malumotlar-dropdown-list8 {
    background-color: var(--dl-color-primary-main);
  }
  .tegishli-malumotlar-dropdown-toggle74 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text340 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-dropdown-toggle75 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text341 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-dropdown-toggle76 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text342 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .tegishli-malumotlar-dropdown-toggle77 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .tegishli-malumotlar-text343 {
    font-size: 14px;
    font-family: Roboto;
  }
  .tegishli-malumotlar-container86 {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .tegishli-malumotlar-text346 {
    width: 250px;
  }
  .tegishli-malumotlar-container96 {
    gap: var(--dl-space-space-halfunit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .tegishli-malumotlar-container97 {
    gap: var(--dl-space-space-halfunit);
    align-self: center;
    flex-direction: column;
  }
  .tegishli-malumotlar-container98 {
    flex-wrap: wrap;
    align-self: center;
    flex-direction: row;
  }
  .tegishli-malumotlar-image24 {
    align-self: center;
  }
  .tegishli-malumotlar-image25 {
    display: none;
  }
}
