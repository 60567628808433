.biometrik-malumotlarini-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.biometrik-malumotlarini-container11 {
  gap: 60px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: 146px;
  align-items: flex-start;
  padding-top: 60px;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/frame%2043-200h.png");
  background-repeat: space;
  background-position: center;
}
.biometrik-malumotlarini-text100 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 22px;
  align-self: flex-start;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.biometrik-malumotlarini-container12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  background-color: #F0F0F0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.biometrik-malumotlarini-navlink10 {
  display: contents;
}
.biometrik-malumotlarini-container13 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.biometrik-malumotlarini-icon10 {
  fill: var(--dl-color-primary-500);
  color: var(--dl-color-primary-500);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.biometrik-malumotlarini-text103 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.biometrik-malumotlarini-navlink11 {
  display: contents;
}
.biometrik-malumotlarini-container14 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.biometrik-malumotlarini-icon12 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 16px;
  height: 16px;
}
.biometrik-malumotlarini-text104 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.biometrik-malumotlarini-navlink12 {
  display: contents;
}
.biometrik-malumotlarini-container15 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.biometrik-malumotlarini-icon14 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 16px;
  height: 16px;
}
.biometrik-malumotlarini-text105 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.biometrik-malumotlarini-container16 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  margin: 100px;
  display: flex;
  align-self: flex-start;
  margin-top: 0px;
  align-items: flex-start;
  margin-left: 0px;
  padding-top: var(--dl-space-space-twounits);
  margin-right: 0px;
  padding-left: 100px;
  margin-bottom: 0px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
}
.biometrik-malumotlarini-container17 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.biometrik-malumotlarini-container18 {
  gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.biometrik-malumotlarini-text106 {
  color: var(--dl-color-gray-500);
  font-style: normal;
  font-weight: 500;
}
.biometrik-malumotlarini-icon16 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 12px;
  height: 12px;
}
.biometrik-malumotlarini-image10 {
  width: auto;
  object-fit: cover;
}
.biometrik-malumotlarini-container19 {
  gap: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.biometrik-malumotlarini-navlink13 {
  display: contents;
}
.biometrik-malumotlarini-container20 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-image11 {
  width: var(--dl-size-size-xsmall);
  object-fit: cover;
}
.biometrik-malumotlarini-text107 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.biometrik-malumotlarini-navlink14 {
  display: contents;
}
.biometrik-malumotlarini-container21 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-container21:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.biometrik-malumotlarini-image12 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.biometrik-malumotlarini-text108 {
  color: var(--dl-color-gray-gray-main);
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-family: "Roboto";
  font-weight: 500;
}
.biometrik-malumotlarini-text108:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-navlink15 {
  display: contents;
}
.biometrik-malumotlarini-container22 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-container22:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.biometrik-malumotlarini-image13 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.biometrik-malumotlarini-text109 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.biometrik-malumotlarini-text109:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-navlink16 {
  display: contents;
}
.biometrik-malumotlarini-container23 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-container23:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.biometrik-malumotlarini-image14 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.biometrik-malumotlarini-text110 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.biometrik-malumotlarini-text110:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-navlink17 {
  display: contents;
}
.biometrik-malumotlarini-container24 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-gray-main);
}
.biometrik-malumotlarini-container24:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.biometrik-malumotlarini-image15 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.biometrik-malumotlarini-text111 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.biometrik-malumotlarini-text111:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-navlink18 {
  display: contents;
}
.biometrik-malumotlarini-container25 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-container25:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.biometrik-malumotlarini-image16 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.biometrik-malumotlarini-text112 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.biometrik-malumotlarini-text112:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-navlink19 {
  display: contents;
}
.biometrik-malumotlarini-container26 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-container26:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.biometrik-malumotlarini-image17 {
  width: var(--dl-size-size-xsmall);
  align-self: center;
  object-fit: cover;
  transition: 0.3s;
}

.biometrik-malumotlarini-text113 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.biometrik-malumotlarini-text113:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-navlink20 {
  display: contents;
}
.biometrik-malumotlarini-container27 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-container27:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.biometrik-malumotlarini-image18 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.biometrik-malumotlarini-text114 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.biometrik-malumotlarini-text114:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-container28 {
  gap: var(--dl-space-space-twounits);
  flex: 0;
  width: auto;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  border-left-width: 1px;
}
.biometrik-malumotlarini-text115 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 900px;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-text116 {
  font-style: normal;
  font-weight: 500;
}
.biometrik-malumotlarini-text117 {
  font-style: normal;
  font-weight: 500;
}
.biometrik-malumotlarini-text129 {
  font-style: normal;
  font-weight: 500;
}
.biometrik-malumotlarini-text142 {
  font-style: normal;
  font-weight: 500;
}
.biometrik-malumotlarini-text143 {
  font-style: normal;
  font-weight: 500;
}
.biometrik-malumotlarini-text144 {
  font-style: normal;
  font-weight: 500;
}
.biometrik-malumotlarini-container29 {
  flex: 0;
  width: 100%;
  display: flex;
  position: fixed;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-navlink21 {
  display: contents;
}
.biometrik-malumotlarini-container30 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}
.biometrik-malumotlarini-image19 {
  width: 106px;
  height: 64px;
  text-decoration: none;
}
.biometrik-malumotlarini-text165 {
  fill: var(--dl-color-primary-main);
  color: var(--dl-color-primary-main);
  width: 275px;
  height: 52px;
  font-size: 10px;
  max-width: auto;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  margin-left: var(--dl-space-space-fourunits);
}
.biometrik-malumotlarini-container31 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.biometrik-malumotlarini-container32 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.biometrik-malumotlarini-text168 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-family: "Roboto";
}
.biometrik-malumotlarini-text170 {
  font-style: normal;
  font-weight: 700;
}
.biometrik-malumotlarini-text171 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-family: "Roboto";
}
.biometrik-malumotlarini-text172 {
  font-style: normal;
  font-weight: 700;
}
.biometrik-malumotlarini-image20 {
  width: 16px;
  height: 11px;
  object-fit: cover;
}
.biometrik-malumotlarini-text203 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 700;
}
.biometrik-malumotlarini-image21 {
  width: 16px;
  height: 11px;
  object-fit: cover;
}
.biometrik-malumotlarini-select {
  height: auto;
  font-size: x-small;
  background-color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-navbar-interactive {
  width: 100%;
  display: flex;
  position: fixed;
  align-self: center;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: px;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: px;
  justify-content: center;
  background-color: var(--dl-color-primary-active_red);
}
.biometrik-malumotlarini-desktop-menu {
  flex: 1;
  width: auto;
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
}
.biometrik-malumotlarini-container33 {
  display: flex;
  justify-content: space-between;
}
.biometrik-malumotlarini-links1 {
  gap: 1px;
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-danger-700);
}
.biometrik-malumotlarini-thq-dropdown10 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.biometrik-malumotlarini-dropdown-toggle10:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: mixed;
  padding-right: mixed;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-dropdown-toggle10:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-text204 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.biometrik-malumotlarini-thq-dropdown11 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-dropdown-toggle11:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-dropdown-toggle11:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-text205 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.biometrik-malumotlarini-dropdown-arrow1 {
  transition: 0.3s;
}
.biometrik-malumotlarini-dropdown-list1 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.biometrik-malumotlarini-dropdown10 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle12 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle12:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text206 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.biometrik-malumotlarini-dropdown11 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle13 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle13:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text209 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown12 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle14 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle14:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text210 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown13 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle15 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle15:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text211 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.biometrik-malumotlarini-dropdown14 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle16 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle16:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text216 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown15 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle17 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle17:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text217 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown16 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle18 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle18:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text218 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown17 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle19 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.biometrik-malumotlarini-dropdown-toggle19:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text222 {
  font-style: normal;
}
.biometrik-malumotlarini-thq-dropdown12 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle20 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.biometrik-malumotlarini-dropdown-toggle20:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-dropdown-toggle20:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-text223 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.biometrik-malumotlarini-dropdown-arrow2 {
  transition: 0.3s;
}
.biometrik-malumotlarini-dropdown-list2 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.biometrik-malumotlarini-dropdown18 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle21 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle21:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text224 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown19 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle22 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle22:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text225 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown20 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle23 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle23:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text226 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown21 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle24 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle24:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text227 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.biometrik-malumotlarini-dropdown22 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle25 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle25:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text230 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown23 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle26 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle26:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text234 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown24 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle27 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  padding-bottom: 20px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle27:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text235 {
  font-style: normal;
}
.biometrik-malumotlarini-thq-dropdown13 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle28 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.biometrik-malumotlarini-dropdown-toggle28:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-dropdown-toggle28:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-text236 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.biometrik-malumotlarini-dropdown-arrow3 {
  transition: 0.3s;
}
.biometrik-malumotlarini-dropdown-list3 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.biometrik-malumotlarini-dropdown25 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle29 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle29:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text237 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown26 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle30 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle30:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text238 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown27 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle31 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle31:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text242 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.biometrik-malumotlarini-dropdown28 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle32 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle32:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text245 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown29 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle33 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle33:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text246 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown30 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle34 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle34:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text250 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown31 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle35 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.biometrik-malumotlarini-dropdown-toggle35:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text251 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown32 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle36 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle36:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-dropdown33 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle37 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle37:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-thq-dropdown14 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle38 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.biometrik-malumotlarini-dropdown-toggle38:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-dropdown-toggle38:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-text254 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.biometrik-malumotlarini-thq-dropdown15 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle39 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.biometrik-malumotlarini-dropdown-toggle39:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-dropdown-toggle39:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-text255 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.biometrik-malumotlarini-dropdown-arrow4 {
  transition: 0.3s;
}
.biometrik-malumotlarini-dropdown-list4 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.biometrik-malumotlarini-dropdown34 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle40 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle40:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text256 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown35 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle41 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle41:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text257 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown36 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle42 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle42:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text258 {
  font-style: normal;
}
.biometrik-malumotlarini-dropdown37 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle43 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle43:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text259 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.biometrik-malumotlarini-container34 {
  gap: 4px;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-end;
  background-color: var(--dl-color-primary-active_red);
}
.biometrik-malumotlarini-textinput1 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  border-width: 0px;
  border-radius: 0px;
}
.biometrik-malumotlarini-container35 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.biometrik-malumotlarini-icon18 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 24px;
}
.biometrik-malumotlarini-container36 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.biometrik-malumotlarini-text262 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.biometrik-malumotlarini-text263 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.biometrik-malumotlarini-burger-menu {
  display: none;
}
.biometrik-malumotlarini-icon20 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.biometrik-malumotlarini-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.biometrik-malumotlarini-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.biometrik-malumotlarini-container37 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.biometrik-malumotlarini-container38 {
  gap: 4px;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-end;
  background-color: var(--dl-color-primary-active_red);
}
.biometrik-malumotlarini-textinput2 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  border-width: 0px;
  border-radius: 0px;
}
.biometrik-malumotlarini-container39 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.biometrik-malumotlarini-icon22 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 24px;
}
.biometrik-malumotlarini-container40 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.biometrik-malumotlarini-text264 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.biometrik-malumotlarini-text265 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.biometrik-malumotlarini-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.biometrik-malumotlarini-icon24 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.biometrik-malumotlarini-links2 {
  gap: 1px;
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-danger-700);
}
.biometrik-malumotlarini-thq-dropdown16 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle44 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.biometrik-malumotlarini-dropdown-toggle44:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-dropdown-toggle44:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-text266 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.biometrik-malumotlarini-thq-dropdown17 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle45 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.biometrik-malumotlarini-dropdown-toggle45:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-dropdown-toggle45:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-text267 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.biometrik-malumotlarini-dropdown-arrow5 {
  transition: 0.3s;
}
.biometrik-malumotlarini-dropdown-list5 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.biometrik-malumotlarini-dropdown38 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle46 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle46:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text268 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown39 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle47 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle47:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text271 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown40 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle48 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle48:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text272 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown41 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle49 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle49:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text273 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown42 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle50 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle50:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text278 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown43 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle51 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle51:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text279 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown44 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle52 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle52:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text280 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown45 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle53 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.biometrik-malumotlarini-dropdown-toggle53:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text283 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-thq-dropdown18 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle54 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.biometrik-malumotlarini-dropdown-toggle54:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-dropdown-toggle54:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-text284 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.biometrik-malumotlarini-dropdown-arrow6 {
  transition: 0.3s;
}
.biometrik-malumotlarini-dropdown-list6 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.biometrik-malumotlarini-dropdown46 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle55 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle55:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text285 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown47 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle56 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle56:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text286 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown48 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle57 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle57:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text287 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown49 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle58 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle58:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text288 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown50 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle59 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle59:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text291 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown51 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle60 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle60:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text295 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown52 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle61 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle61:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text296 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-thq-dropdown19 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle62 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.biometrik-malumotlarini-dropdown-toggle62:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-dropdown-toggle62:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-text297 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.biometrik-malumotlarini-dropdown-arrow7 {
  transition: 0.3s;
}
.biometrik-malumotlarini-dropdown-list7 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.biometrik-malumotlarini-dropdown53 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle63 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle63:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text298 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown54 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle64 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle64:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text299 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown55 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle65 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle65:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text300 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown56 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle66 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.biometrik-malumotlarini-dropdown-toggle66:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text301 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown57 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle67 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle67:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text302 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown58 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle68 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle68:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text305 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown59 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle69 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle69:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text308 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown60 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle70 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle70:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text309 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown61 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle71 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle71:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text310 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-thq-dropdown20 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle72 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.biometrik-malumotlarini-dropdown-toggle72:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-dropdown-toggle72:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-text311 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.biometrik-malumotlarini-thq-dropdown21 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle73 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.biometrik-malumotlarini-dropdown-toggle73:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-dropdown-toggle73:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.biometrik-malumotlarini-text312 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.biometrik-malumotlarini-dropdown-arrow8 {
  transition: 0.3s;
}
.biometrik-malumotlarini-dropdown-list8 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.biometrik-malumotlarini-dropdown62 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle74 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle74:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text313 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown63 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle75 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle75:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text314 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown64 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle76 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle76:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text315 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-dropdown65 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.biometrik-malumotlarini-dropdown-toggle77 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.biometrik-malumotlarini-dropdown-toggle77:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.biometrik-malumotlarini-text316 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.biometrik-malumotlarini-container41 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #222222;
}
.biometrik-malumotlarini-container42 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.biometrik-malumotlarini-image22 {
  width: 72px;
  height: 20px;
  object-fit: cover;
}
.biometrik-malumotlarini-text319 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 440px;
  font-size: 14px;
}
.biometrik-malumotlarini-container43 {
  gap: var(--dl-space-space-unit);
  width: 196px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.biometrik-malumotlarini-container44 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.biometrik-malumotlarini-text320 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-style: normal;
  font-weight: 500;
}
.biometrik-malumotlarini-image23 {
  width: auto;
  object-fit: cover;
}
.biometrik-malumotlarini-container45 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.biometrik-malumotlarini-navlink82 {
  display: contents;
}
.biometrik-malumotlarini-container46 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.biometrik-malumotlarini-icon34 {
  color: var(--dl-color-gray-700);
  width: 22px;
  height: 22px;
}
.biometrik-malumotlarini-text321 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.biometrik-malumotlarini-text321:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-navlink83 {
  display: contents;
}
.biometrik-malumotlarini-container47 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.biometrik-malumotlarini-icon36 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.biometrik-malumotlarini-text322 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.biometrik-malumotlarini-text322:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-navlink84 {
  display: contents;
}
.biometrik-malumotlarini-container48 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.biometrik-malumotlarini-icon38 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.biometrik-malumotlarini-text326 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.biometrik-malumotlarini-text326:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-navlink85 {
  display: contents;
}
.biometrik-malumotlarini-container49 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.biometrik-malumotlarini-icon40 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.biometrik-malumotlarini-text330 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.biometrik-malumotlarini-text330:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-navlink86 {
  display: contents;
}
.biometrik-malumotlarini-container50 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.biometrik-malumotlarini-icon42 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.biometrik-malumotlarini-text334 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.biometrik-malumotlarini-text334:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-container51 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #181818;
}
.biometrik-malumotlarini-container52 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.biometrik-malumotlarini-text338 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
}
.biometrik-malumotlarini-container53 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.biometrik-malumotlarini-navlink87 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  transition: 0.3s;
  text-decoration: none;
}
.biometrik-malumotlarini-navlink87:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-navlink88 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 14px;
  transition: 0.3s;
  text-decoration: none;
}
.biometrik-malumotlarini-navlink88:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.biometrik-malumotlarini-image24 {
  width: auto;
  object-fit: cover;
}
.biometrik-malumotlarini-link {
  display: contents;
}
.biometrik-malumotlarini-image25 {
  width: auto;
  margin: 0px;
  text-decoration: none;
}
@media(max-width: 1200px) {
  .biometrik-malumotlarini-text100 {
    color: var(--dl-color-gray-white);
    font-family: Roboto;
  }
  .biometrik-malumotlarini-container16 {
    width: 100%;
  }
  .biometrik-malumotlarini-text115 {
    width: 700px;
  }
  .biometrik-malumotlarini-select {
    background-color: var(--dl-color-gray-white);
  }
  .biometrik-malumotlarini-dropdown-toggle10 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .biometrik-malumotlarini-dropdown-toggle11 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .biometrik-malumotlarini-dropdown-toggle20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .biometrik-malumotlarini-dropdown-toggle28 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .biometrik-malumotlarini-dropdown-toggle38 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .biometrik-malumotlarini-dropdown-toggle39 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media(max-width: 991px) {
  .biometrik-malumotlarini-container11 {
    height: 200px;
  }
  .biometrik-malumotlarini-container12 {
    display: none;
  }
  .biometrik-malumotlarini-container16 {
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
  }
  .biometrik-malumotlarini-container17 {
    display: none;
  }
  .biometrik-malumotlarini-container28 {
    border-left-width: 0px;
  }
  .biometrik-malumotlarini-container29 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .biometrik-malumotlarini-navbar-interactive {
    padding-left: 50px;
    padding-right: 50px;
  }
  .biometrik-malumotlarini-dropdown-toggle10 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .biometrik-malumotlarini-text204 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .biometrik-malumotlarini-dropdown-toggle11 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .biometrik-malumotlarini-text205 {
    font-size: 14px;
  }
  .biometrik-malumotlarini-dropdown-toggle20 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .biometrik-malumotlarini-text223 {
    font-size: 14px;
  }
  .biometrik-malumotlarini-dropdown-toggle28 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .biometrik-malumotlarini-text236 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .biometrik-malumotlarini-dropdown-toggle38 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .biometrik-malumotlarini-text254 {
    font-size: 14px;
  }
  .biometrik-malumotlarini-dropdown-toggle39 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .biometrik-malumotlarini-text255 {
    font-size: 14px;
  }
  .biometrik-malumotlarini-textinput1 {
    width: var(--dl-size-size-medium);
  }
  .biometrik-malumotlarini-container41 {
    align-self: flex-start;
  }
  .biometrik-malumotlarini-container51 {
    align-self: flex-start;
  }
}
@media(max-width: 767px) {
  .biometrik-malumotlarini-container11 {
    height: 100%;
    margin-top: 140px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: var(--dl-space-space-threeunits);
    background-size: cover;
    background-image: url("/frame%2043-200h.png");
  }
  .biometrik-malumotlarini-container12 {
    display: none;
  }
  .biometrik-malumotlarini-container16 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .biometrik-malumotlarini-container28 {
    padding-left: 0px;
  }
  .biometrik-malumotlarini-text115 {
    width: 600px;
  }
  .biometrik-malumotlarini-container29 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .biometrik-malumotlarini-text165 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .biometrik-malumotlarini-navbar-interactive {
    align-self: center;
    padding-top: 0px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: 0px;
    justify-content: space-between;
  }
  .biometrik-malumotlarini-text204 {
    font-size: 12px;
  }
  .biometrik-malumotlarini-text205 {
    font-size: 12px;
  }
  .biometrik-malumotlarini-text223 {
    font-size: 12px;
  }
  .biometrik-malumotlarini-text236 {
    font-size: 12px;
  }
  .biometrik-malumotlarini-text254 {
    font-size: 12px;
  }
  .biometrik-malumotlarini-text255 {
    font-size: 12px;
  }
  .biometrik-malumotlarini-container34 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .biometrik-malumotlarini-container36 {
    display: none;
  }
  .biometrik-malumotlarini-burger-menu {
    align-items: center;
    justify-content: center;
  }
  .biometrik-malumotlarini-icon20 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .biometrik-malumotlarini-container38 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .biometrik-malumotlarini-container40 {
    display: none;
  }
  .biometrik-malumotlarini-icon24 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .biometrik-malumotlarini-container41 {
    gap: var(--dl-space-space-unit);
    align-self: flex-start;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
    justify-content: center;
  }
  .biometrik-malumotlarini-container51 {
    align-self: flex-start;
  }
}
@media(max-width: 479px) {
  .biometrik-malumotlarini-container11 {
    margin-top: 180px;
    align-items: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .biometrik-malumotlarini-text100 {
    color: var(--dl-color-gray-white);
    width: 273px;
    height: 50px;
    font-family: Roboto;
  }
  .biometrik-malumotlarini-container16 {
    width: 100%;
    align-items: flex-start;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .biometrik-malumotlarini-container28 {
    padding-left: 0px;
  }
  .biometrik-malumotlarini-text115 {
    width: 100%;
  }
  .biometrik-malumotlarini-container29 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: center;
  }
  .biometrik-malumotlarini-image19 {
    width: 103px;
    height: 60px;
    margin-right: 0px;
  }
  .biometrik-malumotlarini-text165 {
    color: var(--dl-color-gray-black);
    width: 200px;
  }
  .biometrik-malumotlarini-navbar-interactive {
    top: 133px;
    flex: 1;
    left: 0px;
    align-self: flex-end;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .biometrik-malumotlarini-desktop-menu {
    display: none;
  }
  .biometrik-malumotlarini-container34 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .biometrik-malumotlarini-textinput1 {
    width: var(--dl-size-size-large);
  }
  .biometrik-malumotlarini-burger-menu {
    display: flex;
  }
  .biometrik-malumotlarini-icon20 {
    width: 32px;
    height: 32px;
  }
  .biometrik-malumotlarini-mobile-menu {
    height: auto;
    padding: 0px;
  }
  .biometrik-malumotlarini-container37 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
    background-color: var(--dl-color-primary-active_red);
  }
  .biometrik-malumotlarini-container38 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .biometrik-malumotlarini-icon24 {
    width: 32px;
    height: 32px;
  }
  .biometrik-malumotlarini-links2 {
    gap: 0.5PX;
    width: 100%;
    height: auto;
    flex-direction: column;
  }
  .biometrik-malumotlarini-thq-dropdown16 {
    width: 100%;
  }
  .biometrik-malumotlarini-dropdown-toggle44 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .biometrik-malumotlarini-text266 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .biometrik-malumotlarini-thq-dropdown17 {
    width: 100%;
  }
  .biometrik-malumotlarini-dropdown-toggle45 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .biometrik-malumotlarini-text267 {
    font-size: 14px;
  }
  .biometrik-malumotlarini-icon26 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .biometrik-malumotlarini-dropdown-list5 {
    background-color: var(--dl-color-primary-main);
  }
  .biometrik-malumotlarini-dropdown-toggle46 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text268 {
    font-size: 14px;
  }
  .biometrik-malumotlarini-dropdown-toggle47 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text271 {
    font-size: 14px;
  }
  .biometrik-malumotlarini-dropdown-toggle48 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text272 {
    font-size: 14px;
  }
  .biometrik-malumotlarini-dropdown-toggle49 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text273 {
    font-size: 14px;
  }
  .biometrik-malumotlarini-dropdown-toggle50 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text278 {
    font-size: 14px;
  }
  .biometrik-malumotlarini-dropdown-toggle51 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text279 {
    font-size: 14px;
  }
  .biometrik-malumotlarini-dropdown-toggle52 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text280 {
    font-size: 14px;
  }
  .biometrik-malumotlarini-dropdown-toggle53 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text283 {
    font-size: 14px;
  }
  .biometrik-malumotlarini-thq-dropdown18 {
    width: 100%;
  }
  .biometrik-malumotlarini-dropdown-toggle54 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .biometrik-malumotlarini-text284 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .biometrik-malumotlarini-icon28 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .biometrik-malumotlarini-dropdown-list6 {
    background-color: var(--dl-color-primary-main);
  }
  .biometrik-malumotlarini-dropdown-toggle55 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text285 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-dropdown-toggle56 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text286 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-dropdown-toggle57 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text287 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-dropdown-toggle58 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text288 {
    font-size: 14px;
    font-family: Roboto;
  }
  .biometrik-malumotlarini-dropdown-toggle59 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text291 {
    font-size: 14px;
    font-family: Roboto;
  }
  .biometrik-malumotlarini-dropdown-toggle60 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text295 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-dropdown-toggle61 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text296 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-thq-dropdown19 {
    width: 100%;
  }
  .biometrik-malumotlarini-dropdown-toggle62 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .biometrik-malumotlarini-text297 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .biometrik-malumotlarini-icon30 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .biometrik-malumotlarini-dropdown-list7 {
    background-color: var(--dl-color-primary-main);
  }
  .biometrik-malumotlarini-dropdown-toggle63 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text298 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-dropdown-toggle64 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text299 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-dropdown-toggle65 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text300 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-dropdown-toggle66 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text301 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-dropdown-toggle67 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text302 {
    font-size: 14px;
    font-family: Roboto;
  }
  .biometrik-malumotlarini-dropdown-toggle68 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text305 {
    font-size: 14px;
  }
  .biometrik-malumotlarini-dropdown-toggle69 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text308 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-dropdown-toggle70 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text309 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-dropdown-toggle71 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text310 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-thq-dropdown20 {
    width: 100%;
  }
  .biometrik-malumotlarini-dropdown-toggle72 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .biometrik-malumotlarini-text311 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .biometrik-malumotlarini-thq-dropdown21 {
    width: 100%;
  }
  .biometrik-malumotlarini-dropdown-toggle73 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .biometrik-malumotlarini-text312 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .biometrik-malumotlarini-icon32 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .biometrik-malumotlarini-dropdown-list8 {
    background-color: var(--dl-color-primary-main);
  }
  .biometrik-malumotlarini-dropdown-toggle74 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text313 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-dropdown-toggle75 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text314 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-dropdown-toggle76 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text315 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .biometrik-malumotlarini-dropdown-toggle77 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .biometrik-malumotlarini-text316 {
    font-size: 14px;
    font-family: Roboto;
  }
  .biometrik-malumotlarini-container41 {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .biometrik-malumotlarini-text319 {
    width: 250px;
  }
  .biometrik-malumotlarini-container51 {
    gap: var(--dl-space-space-halfunit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .biometrik-malumotlarini-container52 {
    gap: var(--dl-space-space-halfunit);
    align-self: center;
    flex-direction: column;
  }
  .biometrik-malumotlarini-container53 {
    flex-wrap: wrap;
    align-self: center;
    flex-direction: row;
  }
  .biometrik-malumotlarini-image24 {
    align-self: center;
  }
  .biometrik-malumotlarini-image25 {
    display: none;
  }
}
