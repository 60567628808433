.uchrashuv-sanasini-va-vatini-belgilash-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.uchrashuv-sanasini-va-vatini-belgilash-burger-menu1 {
  display: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uchrashuv-sanasini-va-vatini-belgilash-container11 {
  gap: 60px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: 146px;
  align-items: flex-start;
  padding-top: 60px;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/frame%2043-200h.png");
  background-repeat: space;
  background-position: center;
}
.uchrashuv-sanasini-va-vatini-belgilash-text100 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uchrashuv-sanasini-va-vatini-belgilash-container12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  background-color: #F0F0F0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink10 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container13 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-icon12 {
  fill: var(--dl-color-primary-500);
  color: var(--dl-color-primary-500);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uchrashuv-sanasini-va-vatini-belgilash-text101 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink11 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container14 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-icon14 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 16px;
  height: 16px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text102 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink12 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container15 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-icon16 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 16px;
  height: 16px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text103 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-container16 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  margin: 100px;
  display: flex;
  align-self: flex-start;
  margin-top: 0px;
  align-items: flex-start;
  margin-left: 0px;
  padding-top: var(--dl-space-space-twounits);
  margin-right: 0px;
  padding-left: 100px;
  margin-bottom: 0px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
}
.uchrashuv-sanasini-va-vatini-belgilash-container17 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.uchrashuv-sanasini-va-vatini-belgilash-container18 {
  gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.uchrashuv-sanasini-va-vatini-belgilash-text104 {
  color: var(--dl-color-gray-500);
  font-style: normal;
  font-weight: 500;
}
.uchrashuv-sanasini-va-vatini-belgilash-icon18 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 12px;
  height: 12px;
}
.uchrashuv-sanasini-va-vatini-belgilash-image10 {
  width: auto;
  object-fit: cover;
}
.uchrashuv-sanasini-va-vatini-belgilash-container19 {
  gap: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink13 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container20 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-image11 {
  width: var(--dl-size-size-xsmall);
  object-fit: cover;
}
.uchrashuv-sanasini-va-vatini-belgilash-text105 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink14 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container21 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-container21:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.uchrashuv-sanasini-va-vatini-belgilash-image12 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.uchrashuv-sanasini-va-vatini-belgilash-text106 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uchrashuv-sanasini-va-vatini-belgilash-text106:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink15 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container22 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-container22:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.uchrashuv-sanasini-va-vatini-belgilash-image13 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.uchrashuv-sanasini-va-vatini-belgilash-text107 {
  fill: var(--dl-color-gray-gray-main);
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uchrashuv-sanasini-va-vatini-belgilash-text107:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink16 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container23 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-container23:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.uchrashuv-sanasini-va-vatini-belgilash-image14 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.uchrashuv-sanasini-va-vatini-belgilash-text108 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uchrashuv-sanasini-va-vatini-belgilash-text108:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink17 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container24 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  background-color: var(--dl-color-gray-gray-main);
}
.uchrashuv-sanasini-va-vatini-belgilash-container24:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.uchrashuv-sanasini-va-vatini-belgilash-image15 {
  width: var(--dl-size-size-xsmall);
  align-self: center;
  object-fit: cover;
  transition: 0.3s;
}

.uchrashuv-sanasini-va-vatini-belgilash-text109 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 14px;
  align-self: center;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uchrashuv-sanasini-va-vatini-belgilash-text109:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink18 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container25 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-container25:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.uchrashuv-sanasini-va-vatini-belgilash-image16 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.uchrashuv-sanasini-va-vatini-belgilash-text110 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uchrashuv-sanasini-va-vatini-belgilash-text110:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink19 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container26 {
  gap: var(--dl-space-space-halfunit);
  width: 275px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-container26:hover {
  background-color: var(--dl-color-gray-gray-main);
}
.uchrashuv-sanasini-va-vatini-belgilash-image17 {
  width: var(--dl-size-size-xsmall);
  align-self: flex-start;
  object-fit: cover;
  transition: 0.3s;
}

.uchrashuv-sanasini-va-vatini-belgilash-text111 {
  color: var(--dl-color-gray-gray-main);
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uchrashuv-sanasini-va-vatini-belgilash-text111:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-container27 {
  gap: var(--dl-space-space-twounits);
  flex: 0;
  width: auto;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  border-left-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text112 {
  color: var(--dl-color-gray-maingray);
  width: 100%;
  font-size: 24px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.5;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text115 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 900px;
  font-size: 13px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.4;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
  background-color: rgb(145, 147, 152);
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text123 {
  color: #008080;
}
.uchrashuv-sanasini-va-vatini-belgilash-text125 {
  color: #008080;
}
.uchrashuv-sanasini-va-vatini-belgilash-text130 {
  color: #008080;
}
.uchrashuv-sanasini-va-vatini-belgilash-text132 {
  color: #008080;
}
.uchrashuv-sanasini-va-vatini-belgilash-container28 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.uchrashuv-sanasini-va-vatini-belgilash-text143 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 900px;
  font-size: 24px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.4;
  padding-bottom: 0px;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-container29 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.uchrashuv-sanasini-va-vatini-belgilash-text144 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 900px;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  padding-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-button1 {
  gap: 4px;
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  display: flex;
  align-self: flex-start;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
  background-color: #498074;
}
.uchrashuv-sanasini-va-vatini-belgilash-button1:hover {
  background-color: #233D37;
}
.uchrashuv-sanasini-va-vatini-belgilash-text160 {
  font-size: 14px;
}
.uchrashuv-sanasini-va-vatini-belgilash-container30 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.uchrashuv-sanasini-va-vatini-belgilash-text163 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 900px;
  font-size: 24px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.4;
  padding-bottom: 0px;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-container31 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.uchrashuv-sanasini-va-vatini-belgilash-text166 {
  fill: var(--dl-color-gray-maingray);
  color: var(--dl-color-gray-maingray);
  width: 900px;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 1.4;
  padding-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-button2 {
  gap: 4px;
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  display: flex;
  align-self: flex-start;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
  background-color: #498074;
}
.uchrashuv-sanasini-va-vatini-belgilash-button2:hover {
  background-color: #233D37;
}
.uchrashuv-sanasini-va-vatini-belgilash-text177 {
  font-size: 14px;
}
.uchrashuv-sanasini-va-vatini-belgilash-container32 {
  flex: 0;
  width: 100%;
  display: flex;
  position: fixed;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink20 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container33 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-image18 {
  width: 106px;
  height: 64px;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-text180 {
  fill: var(--dl-color-primary-main);
  color: var(--dl-color-primary-main);
  width: 275px;
  height: 52px;
  font-size: 10px;
  max-width: auto;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  margin-left: var(--dl-space-space-fourunits);
}
.uchrashuv-sanasini-va-vatini-belgilash-container34 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.uchrashuv-sanasini-va-vatini-belgilash-container35 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.uchrashuv-sanasini-va-vatini-belgilash-text183 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-family: "Roboto";
}
.uchrashuv-sanasini-va-vatini-belgilash-text185 {
  font-style: normal;
  font-weight: 700;
}
.uchrashuv-sanasini-va-vatini-belgilash-text186 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-family: "Roboto";
}
.uchrashuv-sanasini-va-vatini-belgilash-text187 {
  font-style: normal;
  font-weight: 700;
}
.uchrashuv-sanasini-va-vatini-belgilash-image19 {
  width: 16px;
  height: 11px;
  object-fit: cover;
}
.uchrashuv-sanasini-va-vatini-belgilash-text218 {
  color: var(--dl-color-gray-maingray);
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 700;
}
.uchrashuv-sanasini-va-vatini-belgilash-image20 {
  width: 16px;
  height: 11px;
  object-fit: cover;
}
.uchrashuv-sanasini-va-vatini-belgilash-select {
  height: auto;
  font-size: x-small;
  background-color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-navbar-interactive {
  width: 100%;
  display: flex;
  position: fixed;
  align-self: center;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: px;
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: px;
  justify-content: center;
  background-color: var(--dl-color-primary-active_red);
}
.uchrashuv-sanasini-va-vatini-belgilash-desktop-menu {
  flex: 1;
  width: auto;
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
}
.uchrashuv-sanasini-va-vatini-belgilash-container36 {
  display: flex;
  justify-content: space-between;
}
.uchrashuv-sanasini-va-vatini-belgilash-links1 {
  gap: 1px;
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-danger-700);
}
.uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown10 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle10:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: mixed;
  padding-right: mixed;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle10:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text219 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown11 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle11:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle11:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text220 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow1 {
  transition: 0.3s;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-list1 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown10 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle12 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle12:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text221 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown11 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle13 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle13:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text224 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown12 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle14 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle14:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text225 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown13 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle15 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle15:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text226 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown14 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle16 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle16:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text231 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown15 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle17 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle17:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text232 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown16 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle18 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle18:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text233 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown17 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle19 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle19:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text237 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown12 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle20 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle20:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle20:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text238 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow2 {
  transition: 0.3s;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-list2 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown18 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle21 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle21:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text239 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown19 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle22 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle22:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text240 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown20 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle23 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle23:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text241 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown21 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle24 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle24:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text242 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown22 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle25 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle25:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text245 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown23 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle26 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle26:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text249 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown24 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle27 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  padding-bottom: 20px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle27:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text250 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown13 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle28 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle28:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle28:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text251 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow3 {
  transition: 0.3s;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-list3 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown25 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle29 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle29:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text252 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown26 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle30 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle30:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text253 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown27 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle31 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle31:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text257 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown28 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle32 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle32:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text260 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown29 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle33 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle33:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text261 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown30 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle34 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle34:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text265 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown31 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle35 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle35:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text266 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown32 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle36 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle36:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown33 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle37 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle37:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown14 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle38 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle38:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle38:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text269 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown15 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle39 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle39:hover {
  padding-top: 24px;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 21px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle39:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text270 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow4 {
  transition: 0.3s;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-list4 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown34 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle40 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle40:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text271 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown35 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle41 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle41:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text272 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown36 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle42 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle42:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text273 {
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown37 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle43 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle43:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text274 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-style: normal;
}
.uchrashuv-sanasini-va-vatini-belgilash-container37 {
  gap: 4px;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-end;
  background-color: var(--dl-color-primary-active_red);
}
.uchrashuv-sanasini-va-vatini-belgilash-textinput1 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  border-width: 0px;
  border-radius: 0px;
}
.uchrashuv-sanasini-va-vatini-belgilash-container38 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.uchrashuv-sanasini-va-vatini-belgilash-icon20 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 24px;
}
.uchrashuv-sanasini-va-vatini-belgilash-container39 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.uchrashuv-sanasini-va-vatini-belgilash-text277 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uchrashuv-sanasini-va-vatini-belgilash-text278 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uchrashuv-sanasini-va-vatini-belgilash-burger-menu2 {
  display: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-icon22 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uchrashuv-sanasini-va-vatini-belgilash-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.uchrashuv-sanasini-va-vatini-belgilash-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.uchrashuv-sanasini-va-vatini-belgilash-container40 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.uchrashuv-sanasini-va-vatini-belgilash-container41 {
  gap: 4px;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-end;
  background-color: var(--dl-color-primary-active_red);
}
.uchrashuv-sanasini-va-vatini-belgilash-textinput2 {
  width: var(--dl-size-size-large);
  font-size: 14px;
  border-width: 0px;
  border-radius: 0px;
}
.uchrashuv-sanasini-va-vatini-belgilash-container42 {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-gray-main);
}
.uchrashuv-sanasini-va-vatini-belgilash-icon24 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 24px;
}
.uchrashuv-sanasini-va-vatini-belgilash-container43 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.uchrashuv-sanasini-va-vatini-belgilash-text279 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uchrashuv-sanasini-va-vatini-belgilash-text280 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 500;
}
.uchrashuv-sanasini-va-vatini-belgilash-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.uchrashuv-sanasini-va-vatini-belgilash-icon26 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uchrashuv-sanasini-va-vatini-belgilash-links2 {
  gap: 1px;
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-danger-700);
}
.uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown16 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle44 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle44:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle44:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text281 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown17 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle45 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle45:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle45:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text282 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow5 {
  transition: 0.3s;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-list5 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown38 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle46 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle46:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text283 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown39 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle47 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle47:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text286 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown40 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle48 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle48:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text287 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown41 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle49 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle49:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text288 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown42 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle50 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle50:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text293 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown43 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle51 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle51:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text294 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown44 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle52 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle52:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text295 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown45 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle53 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle53:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text298 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown18 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle54 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle54:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle54:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text299 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow6 {
  transition: 0.3s;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-list6 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown46 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle55 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle55:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text300 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown47 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle56 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle56:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text301 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown48 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle57 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle57:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text302 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown49 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle58 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle58:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text303 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown50 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle59 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle59:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text306 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown51 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle60 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle60:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text310 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown52 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle61 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle61:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text311 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown19 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle62 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle62:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle62:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text312 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow7 {
  transition: 0.3s;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-list7 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown53 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle63 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle63:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text313 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown54 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle64 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle64:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text314 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown55 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle65 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle65:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text315 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown56 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle66 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle66:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text316 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown57 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle67 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle67:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text317 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown58 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle68 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle68:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text320 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown59 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle69 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle69:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text323 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown60 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle70 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle70:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text324 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown61 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle71 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle71:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text325 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown20 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle72 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle72:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle72:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text326 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown21 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle73 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  padding: 24px;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 24px;
  background-color: var(--dl-color-primary-active_red);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle73:hover {
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle73:active {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
  background-color: var(--dl-color-gray-gray-main);
  border-bottom-width: 3px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text327 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-arrow8 {
  transition: 0.3s;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-list8 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-danger-700);
  list-style-position: inside;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown62 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle74 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle74:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text328 {
  width: auto;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown63 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle75 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle75:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text329 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown64 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle76 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle76:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text330 {
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown65 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle77 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 100%;
  display: inline-flex;
  padding: 20px;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-danger-700);
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-active_red);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle77:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.uchrashuv-sanasini-va-vatini-belgilash-text331 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-container44 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #222222;
}
.uchrashuv-sanasini-va-vatini-belgilash-container45 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.uchrashuv-sanasini-va-vatini-belgilash-image21 {
  width: 72px;
  height: 20px;
  object-fit: cover;
}
.uchrashuv-sanasini-va-vatini-belgilash-text334 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 440px;
  font-size: 14px;
}
.uchrashuv-sanasini-va-vatini-belgilash-container46 {
  gap: var(--dl-space-space-unit);
  width: 196px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.uchrashuv-sanasini-va-vatini-belgilash-container47 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.uchrashuv-sanasini-va-vatini-belgilash-text335 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-style: normal;
  font-weight: 500;
}
.uchrashuv-sanasini-va-vatini-belgilash-image22 {
  width: auto;
  object-fit: cover;
}
.uchrashuv-sanasini-va-vatini-belgilash-container48 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink81 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container49 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-icon36 {
  color: var(--dl-color-gray-700);
  width: 22px;
  height: 22px;
}
.uchrashuv-sanasini-va-vatini-belgilash-text336 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.uchrashuv-sanasini-va-vatini-belgilash-text336:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink82 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container50 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-icon38 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uchrashuv-sanasini-va-vatini-belgilash-text337 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.uchrashuv-sanasini-va-vatini-belgilash-text337:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink83 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container51 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-icon40 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uchrashuv-sanasini-va-vatini-belgilash-text341 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.uchrashuv-sanasini-va-vatini-belgilash-text341:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink84 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container52 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-icon42 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uchrashuv-sanasini-va-vatini-belgilash-text345 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.uchrashuv-sanasini-va-vatini-belgilash-text345:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink85 {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-container53 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-icon44 {
  color: var(--dl-color-gray-700);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.uchrashuv-sanasini-va-vatini-belgilash-text349 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 400;
}
.uchrashuv-sanasini-va-vatini-belgilash-text349:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-container54 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #181818;
}
.uchrashuv-sanasini-va-vatini-belgilash-container55 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.uchrashuv-sanasini-va-vatini-belgilash-text353 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
}
.uchrashuv-sanasini-va-vatini-belgilash-container56 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink86 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  transition: 0.3s;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink86:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink87 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 14px;
  transition: 0.3s;
  text-decoration: none;
}
.uchrashuv-sanasini-va-vatini-belgilash-navlink87:hover {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.uchrashuv-sanasini-va-vatini-belgilash-image23 {
  width: auto;
  object-fit: cover;
}
.uchrashuv-sanasini-va-vatini-belgilash-link {
  display: contents;
}
.uchrashuv-sanasini-va-vatini-belgilash-image24 {
  width: auto;
  margin: 0px;
  text-decoration: none;
}
@media(max-width: 1200px) {
  .uchrashuv-sanasini-va-vatini-belgilash-text100 {
    color: var(--dl-color-gray-white);
    font-family: Roboto;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container16 {
    width: 100%;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text115 {
    width: 700px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text143 {
    width: 700px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text144 {
    width: 700px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text163 {
    width: 700px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text166 {
    width: 700px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-select {
    background-color: var(--dl-color-gray-white);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle10 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle11 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle28 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle38 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle39 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media(max-width: 991px) {
  .uchrashuv-sanasini-va-vatini-belgilash-container11 {
    height: 200px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container12 {
    display: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container16 {
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container17 {
    display: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container27 {
    border-left-width: 0px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container32 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-navbar-interactive {
    padding-left: 50px;
    padding-right: 50px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle10 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text219 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle11 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text220 {
    font-size: 14px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle20 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text238 {
    font-size: 14px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle28 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text251 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle38 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text269 {
    font-size: 14px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle39 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text270 {
    font-size: 14px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-textinput1 {
    width: var(--dl-size-size-medium);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container44 {
    align-self: flex-start;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container54 {
    align-self: flex-start;
  }
}
@media(max-width: 767px) {
  .uchrashuv-sanasini-va-vatini-belgilash-burger-menu1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-icon10 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container11 {
    height: 100%;
    margin-top: 98px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: var(--dl-space-space-threeunits);
    background-size: cover;
    background-image: url("/frame%2043-200h.png");
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container12 {
    display: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container16 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container27 {
    padding-left: 0px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text115 {
    width: 600px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text143 {
    width: 600px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text144 {
    width: 600px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text163 {
    width: 600px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text166 {
    width: 600px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container32 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text180 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-navbar-interactive {
    align-self: center;
    padding-top: 0px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: 0px;
    justify-content: space-between;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text219 {
    font-size: 12px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text220 {
    font-size: 12px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text238 {
    font-size: 12px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text251 {
    font-size: 12px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text269 {
    font-size: 12px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text270 {
    font-size: 12px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container37 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container39 {
    display: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-burger-menu2 {
    align-items: center;
    justify-content: center;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-icon22 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container41 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container43 {
    display: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-icon26 {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container44 {
    gap: var(--dl-space-space-unit);
    align-self: flex-start;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
    justify-content: center;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container54 {
    align-self: flex-start;
  }
}
@media(max-width: 479px) {
  .uchrashuv-sanasini-va-vatini-belgilash-icon10 {
    width: 32px;
    height: 32px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container11 {
    margin-top: 150px;
    align-items: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text100 {
    color: var(--dl-color-gray-white);
    width: 273px;
    height: 50px;
    font-family: Roboto;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container16 {
    width: 100%;
    align-items: flex-start;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container27 {
    width: 100%;
    padding-left: 0px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text112 {
    width: 100%;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text115 {
    width: 100%;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text143 {
    width: 100%;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text144 {
    width: 100%;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text163 {
    width: 100%;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text166 {
    width: 100%;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container32 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: center;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-image18 {
    width: 103px;
    height: 60px;
    margin-right: 0px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text180 {
    color: var(--dl-color-gray-black);
    width: 200px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-navbar-interactive {
    top: 133px;
    flex: 1;
    left: 0px;
    align-self: flex-end;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-desktop-menu {
    display: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container37 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-textinput1 {
    width: var(--dl-size-size-large);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-burger-menu2 {
    display: flex;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-icon22 {
    width: 32px;
    height: 32px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-mobile-menu {
    height: auto;
    padding: 0px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container40 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
    background-color: var(--dl-color-primary-active_red);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container41 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-icon26 {
    width: 32px;
    height: 32px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-links2 {
    gap: 0.5PX;
    width: 100%;
    height: auto;
    flex-direction: column;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown16 {
    width: 100%;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle44 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text281 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown17 {
    width: 100%;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle45 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text282 {
    font-size: 14px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-icon28 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-list5 {
    background-color: var(--dl-color-primary-main);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle46 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text283 {
    font-size: 14px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle47 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text286 {
    font-size: 14px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle48 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text287 {
    font-size: 14px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle49 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text288 {
    font-size: 14px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle50 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text293 {
    font-size: 14px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle51 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text294 {
    font-size: 14px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle52 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text295 {
    font-size: 14px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle53 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text298 {
    font-size: 14px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown18 {
    width: 100%;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle54 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text299 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-icon30 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-list6 {
    background-color: var(--dl-color-primary-main);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle55 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text300 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle56 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text301 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle57 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text302 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle58 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text303 {
    font-size: 14px;
    font-family: Roboto;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle59 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text306 {
    font-size: 14px;
    font-family: Roboto;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle60 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text310 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle61 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text311 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown19 {
    width: 100%;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle62 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text312 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-icon32 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-list7 {
    background-color: var(--dl-color-primary-main);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle63 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text313 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle64 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text314 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle65 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text315 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle66 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text316 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle67 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text317 {
    font-size: 14px;
    font-family: Roboto;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle68 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text320 {
    font-size: 14px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle69 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text323 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle70 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text324 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle71 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text325 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown20 {
    width: 100%;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle72 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text326 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-thq-dropdown21 {
    width: 100%;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle73 {
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-gray-main);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text327 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-icon34 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-list8 {
    background-color: var(--dl-color-primary-main);
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle74 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text328 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle75 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text329 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle76 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text330 {
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-dropdown-toggle77 {
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-primary-700);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #555555;
    border-top-width: 0px;
    border-bottom-width: 0.5px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text331 {
    font-size: 14px;
    font-family: Roboto;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container44 {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-text334 {
    width: 250px;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container54 {
    gap: var(--dl-space-space-halfunit);
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container55 {
    gap: var(--dl-space-space-halfunit);
    align-self: center;
    flex-direction: column;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-container56 {
    flex-wrap: wrap;
    align-self: center;
    flex-direction: row;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-image23 {
    align-self: center;
  }
  .uchrashuv-sanasini-va-vatini-belgilash-image24 {
    display: none;
  }
}
